<template>
  <dialog-layout
    title="Payment information"
    :fullscreen="fullscreen"
    :navIcon="navIcon"
    :dark="dark"
    @pop-dialog="$emit('pop-dialog')"
  >
    <stripe-elements
      v-if="user.privateProfile"
      :full-name="user.displayName"
      :add-card-button-disabled="!user.privateProfile.paymentEmail.length"
      @close="$emit('pop-dialog')"
    >
      <extra-stripe-elements />
    </stripe-elements>
  </dialog-layout>
</template>

<script>
import { DialogLayout, StripeElements } from "vue-app-components";
import ExtraStripeElements from "@/components/extra-stripe-elements.vue";

export default {
  name: "dialog-stripe-elements",
  components: {
    DialogLayout,
    StripeElements,
    ExtraStripeElements
  },
  props: {
    fullscreen: {
      type: Boolean,
      required: true
    },
    navIcon: {
      type: String,
      required: true
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.user;
    }
  }
};
</script>

<style lang="css" scoped>
</style>
