const DEFAULT_TITLE = "Methodology focused - UVMGen";
// Max 155 character descriptions.
const DEFAULT_DESCRIPTION = "UVMGen is a SystemVerilog/UVM code generator focusing on industry proven methodology and productivity.";

function defaultWebAppJsonLd (description) {
  return {
    "@context": "http://schema.org",
    "@type": "WebApplication",
    name: "UVMGen",
    description,
    applicationCategory: "Electronic Design Automation, Code generator",
    applicationSubCategory: "SystemVerilog UVM code generator",
    image: [ // minimum 696 pixel width, 16x9, 4x3, 1x1, minimum 300,000 pixels, jpg, png or gif
      "https://www.uvmgen.com/img/logo16x9.png",
      "https://www.uvmgen.com/img/logo4x3.png",
      "https://www.uvmgen.com/img/logo1x1.png"
    ],
    publisher: {
      "@type": "Organization",
      name: "UVMGen",
      url: "https://www.uvmgen.com"
    },
    copyrightYear: "2019",
    copyrightHolder: {
      "@type": "Organization",
      name: "UVMGen LLC",
      url: "https://www.uvmgen.com"
    },
    offers: {
      "@type": "AggregateOffer",
      highPrice: "0.00",
      lowPrice: "0.00",
      priceCurrency: "USD",
      offers: [
        {
          "@type": "Offer",
          url: "/projects/uvcs/new"
        }
      ]
    }
  };
}

export default function (title = DEFAULT_TITLE, description = DEFAULT_DESCRIPTION, jsonLd = null) {
  if (jsonLd === null) {
    jsonLd = defaultWebAppJsonLd(description);
  }
  document.title = title;
  document.head.querySelector("meta[name=description]").content = description;
  document.head.querySelector("script[type=application\\/ld\\+json]").innerHTML = JSON.stringify(jsonLd);
}
