<template>
  <v-card class="mt-10 create-card">
    <v-card-title class="mt-4 text-h5">
      Create new {{ itemType }}
    </v-card-title>
    <v-card-subtitle v-if="creatingPolicyForSequence">
      On behalf of {{ newClassProperties.klass.type }}
    </v-card-subtitle>
    <v-card-text class="my-2">
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            :label="newItemLabel"
            ref="newItemTypeTextField"
            spellcheck="false"
            autocapitalize="off"
            variant="underlined"
            :placeholder="newItemPlaceholderProperty"
            :hint="newItemHint"
            :error-messages="errorMessages"
            :model-value="newItemType"
            @update:modelValue="onInputOfItemIdentifier"
            @keydown="onKeydown"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-if="isClass"
            variant="underlined"
            :items="baseClassCollection"
            item-title="type"
            item-value="uniqueId"
            :menu-props="{ contentClass: 'collection-editor__extends-menu' }"
            :label="isPolicy ? 'Class to constrain' : 'Extends'"
            :disabled="creatingPolicyForSequence"
            :persistent-hint="!baseClass"
            :hint="isPolicy ? 'Select class to constrain' : 'Select parent class'"
            return-object
            :model-value="baseClass && { type: baseClass.type, uniqueId: baseClass.uniqueId }"
            @update:modelValue="onBaseClassChange"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="!isNeutral">
        <v-col>
          <v-radio-group
            :disabled="canDetermineIsMaster"
            :model-value="radioGroupIsMasterOverride"
            @update:modelValue="radioGroupIsMaster = $event"
          >
            <v-radio
              label="Master"
              :value="true"
            ></v-radio>
            <v-radio
              label="Slave"
              :value="false"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="cancelCreateNewItem"
      >
        CANCEL
      </v-btn>
      <v-btn
        color="primary"
        text
        :disabled="(!newItemType.length || !!errorMessages.length) || (isClass && !baseClass)"
        @click="saveCreateNewItem"
      >
        SAVE
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import parseSystemVerilog from "@/utils/parse-system-verilog";

export default {
  props: {
    itemType: {
      type: String,
      required: true
    },
    isClass: {
      type: Boolean,
      default: true
    },
    isPolicy: {
      type: Boolean,
      default: false
    },
    baseClassCollection: {
      type: Array,
      default: function () {
        return [];
      }
    },
    selectedItem: {
      type: Object,
      default: null
    },
    newClassProperties: {
      type: Object,
      default: null
    },
    newItemLabel: {
      type: String,
      default: "Class"
    },
    newItemPlaceholder: {
      type: Function,
      required: true
    },
    newItemHint: {
      type: String,
      required: true
    },
    isNeutral: {
      type: Boolean,
      default: true
    },
    onSave: {
      type: Function,
      required: true
    },
    onCancel: {
      type: Function,
      required: true
    },
    createNewItem: {
      type: Function,
      required: true
    },
    defaultBaseClass: {
      type: Function,
      default: function (selectedItem) {
        return selectedItem;
      }
    }
  },
  watch: {
    newClassProperties (properties) {
      this.setProperties(properties);
    }
  },
  data () {
    return {
      newItemType: "",
      baseClass: null,
      radioGroupIsMaster: true,
      creatingPolicyForSequence: false,
      errorMessages: []
    };
  },
  created () {
    this.baseClass = this.defaultBaseClass(this.selectedItem);
    setTimeout(() => {
      this.$refs.newItemTypeTextField && this.$refs.newItemTypeTextField.focus();
    }, 500);
  },
  mounted () {
    this.setProperties(this.newClassProperties);
  },
  computed: {
    newItemPlaceholderProperty () {
      return this.newItemPlaceholder(this.baseClass, this.radioGroupIsMasterOverride);
    },
    radioGroupIsMasterOverride () {
      if (this.creatingPolicyForSequence) {
        return this.newClassProperties.master;
      } else if (this.canDetermineIsMaster) {
        return this.baseClass.isMaster;
      } else {
        return this.radioGroupIsMaster;
      }
    },
    canDetermineIsMaster () {
      return (this.baseClass && ((typeof this.baseClass.isMaster) === "boolean")) || this.creatingPolicyForSequence;
    }
  },
  methods: {
    setProperties (properties) {
      if (properties) {
        this.creatingPolicyForSequence = true;
        this.newItemType = "";
        this.baseClass = properties.baseClass;
        this.radioGroupIsMaster = properties.master;
      }
    },
    onBaseClassChange (event) {
      this.baseClass = event;
      if (!this.baseClass.isUvmBaseClass) {
        this.$emit("activate", this.baseClass);
      }
    },
    saveCreateNewItem () {
      if (this.newItemType.length && (this.baseClass || !this.isClass) && !this.errorMessages.length) {
        return this.createNewItem(this.newItemType, this.baseClass, this.radioGroupIsMasterOverride, this.creatingPolicyForSequence)
          .then(id => {
            this.$emit("done");
            this.onSave(id);
          });
      }
    },
    cancelCreateNewItem () {
      this.$emit("done");
      this.onCancel();
    },
    onInputOfItemIdentifier (value) {
      const errors = [];
      this.newItemType = value;

      try {
        this.newItemType = this.parseClassIdentifier(value);
        if (this.isClass && !this.newItemType.match(/_c$/)) {
          errors.push("Must end in '_c'");
        }
      } catch (e) {
        errors.push(e.message);
      }
      this.errorMessages = errors;
    },
    parseClassIdentifier (value) {
      return parseSystemVerilog(value, "ClassIdentifier");
    },
    onKeydown (evt) {
      if (evt.code === "Enter") {
        this.saveCreateNewItem();
      }
    }
  }
};
</script>

<style lang="css" scoped>

.create-card {
  margin-left: 2px;
  margin-right: 2px;
}

</style>
