<template>
  <v-tooltip
    :model-value="forceTooltipVisible"
    left
    class="generate-files-fab__tooltip"
  >
    <template v-slot:activator="{ props }">
      <div
        v-bind="props"
      >
        <v-btn
          class="generate-files-fab__button"
          data-test="generate-files-fab__button"
          :icon="fabIcon"
          :color="buttonDisabled ? undefined : 'accent'"
          :loading="generating"
          :disabled="buttonDisabled"
          size="x-large"
          @click="onClick"
          elevation="15"
        >
        </v-btn>
      </div>
    </template>
    {{ numFilesCounterFormatted }} files ({{ numLinesCounterFormatted }} lines)
  </v-tooltip>
</template>

<script>
import GenerateFiles from "@/utils/generate-files-VITE_APP_PLATFORM";
import debounce from "lodash.debounce";

export default {
  name: "generate-files-fab",
  mixins: [
    GenerateFiles
  ],
  props: {
    disabled: {
      type: Boolean,
      required: true
    },
    rootProject: {
      type: Object,
      required: false
    },
    allModels: {
      type: Array,
      required: true
    },
    models: {
      type: Array,
      required: true
    },
    projectName: {
      type: String
    }
  },
  data () {
    return {
      numFilesCounterTimeInterval: 0,
      numFilesCounterOn: false,
      numFilesCounter: 0,
      numLinesCounterTimeInterval: 0,
      numLinesCounterOn: false,
      numLinesCounter: 0,
      forceTooltipVisible: undefined,
      generating: false
    };
  },
  created () {
    this.debouncedTooltip = debounce(() => {
      this.forceTooltipVisible = undefined;
    }, 2000);
  },
  watch: {
    numFiles: {
      immediate: true,
      handler (value) {
        this.numFilesCounterTimeInterval = 1000 / Math.abs(value - this.numFilesCounter);
        this.numFilesCounterOn = true;
      }
    },
    numFilesCounterOn: {
      immediate: true,
      handler (value) {
        if (value) {
          this.numFilesCounterStart();
        }
      }
    },
    numFilesCounter () {
      this.forceTooltipVisible = true;
      this.debouncedTooltip();
    },
    numLines: {
      immediate: true,
      handler (value) {
        this.numLinesCounterTimeInterval = 1000 / (Math.abs(value - this.numLinesCounter) / 7);
        this.numLinesCounterOn = true;
      }
    },
    numLinesCounterOn: {
      immediate: true,
      handler (value) {
        if (value) {
          this.numLinesCounterStart();
        }
      }
    },
    numLinesCounter () {
      this.forceTooltipVisible = true;
      this.debouncedTooltip();
    }
  },
  computed: {
    buttonDisabled () {
      return this.disabled || !this.rootProject || this.generating;
    },
    numFilesCounterFormatted () {
      return this.numFilesCounter.toLocaleString();
    },
    numFiles () {
      return this.models.length;
    },
    numLinesCounterFormatted () {
      return this.numLinesCounter.toLocaleString();
    },
    numLines () {
      let num = 0;
      this.models.forEach(model => {
        num = num + model.numLines;
      });
      return num;
    }
  },
  methods: {
    async onClick () {
      this.generating = true;
      // [TODO] i'd like to know when any user downloads a uvc
      if (this.$store.getters.isAuthenticated && (this.rootProject.userId === this.$store.getters.user.id)) {
        this.rootProject.immediateUpdate({ numDownloads: this.rootProject.numDownloads + 1 });
      }
      await this.generateFiles();
      this.generating = false;
    },
    numFilesCounterStart () {
      setTimeout(() => {
        if (this.numFiles > this.numFilesCounter) {
          this.numFilesCounter = this.numFilesCounter + 1;
          this.numFilesCounterStart();
        } else if (this.numFiles < this.numFilesCounter) {
          this.numFilesCounter = this.numFilesCounter - 1;
          this.numFilesCounterStart();
        } else {
          this.numFilesCounterOn = false;
        }
      }, this.numFilesCounterTimeInterval);
    },
    numLinesCounterStart () {
      setTimeout(() => {
        const incValue = (Math.abs(this.numLines - this.numLinesCounter) > 6) ? 7 : 1;
        if (this.numLines > this.numLinesCounter) {
          this.numLinesCounter = this.numLinesCounter + incValue;
          this.numLinesCounterStart();
        } else if (this.numLines < this.numLinesCounter) {
          this.numLinesCounter = this.numLinesCounter - incValue;
          this.numLinesCounterStart();
        } else {
          this.numLinesCounterOn = false;
        }
      }, this.numLinesCounterTimeInterval);
    }
  }
};
</script>

<style lang="css" scoped>
</style>
