<template>
  <v-navigation-drawer
    v-model="sideNavOpen"
    app
    disable-resize-watcher
    temporary
    hide-overlay
  >

    <div class="logo-background">
      <div class="text-caption pa-1 pr-3 text-right">Version {{version}}</div>
      <div class="px-4 pt-1 pb-3 text-center">
        <router-link
          data-test="side-nav__logo-link"
          :to="{name: 'Home'}"
          @click="sideNavOpen = false"
        >
          <img
            src="/img/logo-bw.svg"
            class="logo"
          />
        </router-link>
      </div>
    </div>

    <v-list>
      <v-list-item
        title=""
        @click="navToUVCs"
      >
        <template v-slot:prepend>
          <v-icon icon="mdi-compare-horizontal"></v-icon>
        </template>
        <v-list-item-title>
          UVCs
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        title=""
        @click="navToEnvs"
      >
        <template v-slot:prepend>
          <v-icon>mdi-developer-board</v-icon>
        </template>
        <v-list-item-title>
          Environments
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        title=""
        @click="navToTbs"
      >
        <template v-slot:prepend>
          <v-icon>mdi-view-array</v-icon>
        </template>
        <v-list-item-title>
          Testbenches
        </v-list-item-title>
      </v-list-item>

      <v-divider />

      <v-list-item
        v-if="$store.getters.isWeb"
        title=""
        @click="navToCheckout"
      >
        <template v-slot:prepend>
          <v-icon>mdi-monitor-arrow-down-variant</v-icon>
        </template>
        <v-list-item-title>
          Download desktop app
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="$store.getters.isDesktop"
        title=""
        @click="$dialog.override('license-assets')"
      >
        <template v-slot:prepend>
          <v-icon>mdi-information-slab-box</v-icon>
        </template>
        <v-list-item-title>
          License information
        </v-list-item-title>
      </v-list-item>

      <v-divider />

      <v-list-group
        v-if="false"
        prepend-icon="library_books"
        no-action
      >
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="">
            <v-list-item-title>Blogs</v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item
          v-for="blog in blogs"
          :key="blog.title"
          title=""
          @click="$router.push({name: 'blog', params: {id: blog.id}})"
        >

          <v-list-item-title>{{ blog.title }}</v-list-item-title>

          <template v-slot:prepend>
            <v-icon></v-icon>
          </template>
        </v-list-item>
      </v-list-group>

      <v-list-group
        v-if="false"
        prepend-icon="assignment"
        no-action
      >
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" title="">
            <v-list-item-title>Cheat sheets</v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item
          v-for="cheatSheet in cheatSheets"
          :key="cheatSheet.title"
          title=""
          @click="$router.push({name: 'cheatSheet', params: {id: cheatSheet.id}})"
        >

            <v-list-item-title>{{ cheatSheet.title }}</v-list-item-title>

          <template v-slot:prepend>
            <v-icon></v-icon>
          </template>
        </v-list-item>
      </v-list-group>

      <v-list-item
        v-if="$store.getters.isWeb"
        title=""
        @click="$router.push({name: 'blog', params: {id: 'faq'}})"
      >
        <template v-slot:prepend>
          <v-icon>mdi-frequently-asked-questions</v-icon>
        </template>

          <v-list-item-title>
            FAQ
          </v-list-item-title>

      </v-list-item>

      <v-list-item title="" @click="$dialog.override('send-feedback')">
        <template v-slot:prepend>
          <v-icon>mdi-message-alert</v-icon>
        </template>

          <v-list-item-title>
            Send feedback
          </v-list-item-title>

      </v-list-item>

      <v-list-item title="" @click="$dialog.override('help')">
        <template v-slot:prepend>
          <v-icon>mdi-help-circle</v-icon>
        </template>

          <v-list-item-title>
            Help
          </v-list-item-title>

      </v-list-item>

      <v-list-item
        v-if="$store.getters.isWeb"
        title=""
        href="https://twitter.com/uvmgen_com"
        target="_blank"
      >
        <template v-slot:prepend>
          <v-icon>mdi-twitter</v-icon>
        </template>

          <v-list-item-title>
            Follow
          </v-list-item-title>

      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="text-caption text-center">
        &copy; 2018 UVMGen, LLC.
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import config from "@/config";

export default {
  name: "side-nav",
  created () {
    this.version = `${__APP_VERSION__} (${__APP_GIT_VERSION__})`; // eslint-disable-line no-undef
    this.blogs = __APP_MARKDOWN_INFO__.blogs; // eslint-disable-line no-undef
    this.cheatSheets = __APP_MARKDOWN_INFO__["cheat-sheets"]; // eslint-disable-line no-undef
    this.githubIssuesUrl = "https://github.com/uvmgen/issues";
  },
  inject: ["$dialog"],
  computed: {
    sideNavOpen: {
      get () {
        return this.$store.getters.isSideNavOpen;
      },
      set (open) {
        this.$store.commit("setSideNavOpen", { open });
      }
    }
  },
  methods: {
    navToUVCs: function () {
      if (this.$route.name !== "uvcs") {
        this.$router.push({ name: "uvcs" })
          .catch((e) => {
            if (!e.message.match(/navigation guard/)) {
              throw e;
            }
          });
      }
      this.sideNavOpen = false;
    },
    navToEnvs: function () {
      if (import.meta.env.VITE_APP_PLATFORM === "desktop") {
        if (this.$route.name !== "envs") {
          this.$router.push({ name: "envs" })
            .catch((e) => {
              if (!e.message.match(/navigation guard/)) {
                throw e;
              }
            });
        }
      } else {
        this.$store.commit("setOnPremiseDialogTitle", "Environments available on Desktop");
        this.$store.commit("setOnPremiseDialogParagraph", config.envMarketingParagraph);
        this.$dialog.override("on-premise-license");
      }
      this.sideNavOpen = false;
    },
    navToTbs: function () {
      if (import.meta.env.VITE_APP_PLATFORM === "desktop") {
        if (this.$route.name !== "tbs") {
          this.$router.push({ name: "tbs" })
            .catch((e) => {
              if (!e.message.match(/navigation guard/)) {
                throw e;
              }
            });
        }
      } else {
        this.$store.commit("setOnPremiseDialogTitle", "Testbenches available on Desktop");
        this.$store.commit("setOnPremiseDialogParagraph", config.tbMarketingParagraph);
        this.$dialog.override("on-premise-license");
      }
      this.sideNavOpen = false;
    },
    navToCheckout: function () {
      this.$router.push({ name: "Checkout" })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
      this.sideNavOpen = false;
    }
  }
};
</script>

<style lang="css" scoped>

.v-navigation-drawer {
  display: flex;
  flex-direction: column;
  z-index: var(--side-nav-z-index);
}

.logo-background {
  background: rgb(255,235,59);
  background: linear-gradient(130deg, rgba(255,235,59,1) 15%, rgba(251,140,0,1) 90%);
}

.logo {
  margin: 16px;
  padding-left: 5px;
  height: 150px;
}

</style>
