<template>
  <v-tooltip
    location="bottom center"
  >
    <template v-slot:activator="{ props }">
      <div
        v-bind="props"
      >
        <v-icon v-if="properties.disabled">
          {{ iconName }}
        </v-icon>
        <div
          v-else
        >
          <v-menu
            min-width="228"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                class="menu-button"
                :style="buttonStyles"
                data-test="data-input-table__select-button"
                v-bind="props"
                variant="outlined"
                size="small"
                :color="properties.color"
                append-icon="mdi-menu-down"
              >
                {{ properties.label[0] }}
              </v-btn>
            </template>

            <v-list
              variant="flat"
              min-width="210px"
            >
              <v-list-subheader>Source of {{ subject.name }}</v-list-subheader>

              <v-list-item
                v-for="(item, index) in listItems"
                :key="index"
                title=""
                :two-line="!!item.subtitle"
                @click="onCheck(index)"
              >
                <template #prepend>
                  <v-checkbox-btn
                    class="mr-2"
                    color="primary"
                    true-icon="mdi-radiobox-marked"
                    false-icon="mdi-radiobox-blank"
                    :model-value="properties.selectedIndex === index"
                  />
                </template>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </template>

    <span>{{ properties.label }}</span>
  </v-tooltip>
</template>

<script>

export default {
  props: {
    subject: {
      type: Object,
      required: true
    },
    column: {
      type: Object,
      required: true
    }
  },
  data () {
    return {};
  },
  computed: {
    buttonStyles () {
      return {
        "--button-color": this.properties.color
      };
    },
    iconName () {
      return "mdi-alpha-" + this.properties.label.charAt(0).toLowerCase();
    },
    properties () {
      return this.column["select-button"].properties(this.subject);
    },
    listItems () {
      return this.column["select-button"].listItems(this.subject);
    }
  },
  methods: {
    onCheck (index) {
      this.subject.update(this.listItems[index].data);
    }
  }
};

</script>

<style lang="css" scoped>

.menu-button {
  margin-left: -7px;
}

:deep(.menu-button .v-icon) {
  color: var(--button-color);
}

</style>
