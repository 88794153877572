<template>
  <v-container
    ref="fileGrid"
    v-resize="getCoordinates"
    :style="fileGridStyles"
  >
    <!--<div class="corner-ribbon">Beta</div>-->
    <dialog-navigator
      :isVDialog="false"
      paramName="toolDialog"
      dark
    />

    <div
      class="file-grid-cover"
      :class="{'file-grid-cover--dialog-present': showDialog}"
      v-show="showFileGridCover"
    />

    <div class="animation-target-parent">
      <div class="animation-target-div" ref="targetDiv">
      </div>
    </div>

    <div
      class="pb-8"
      v-for="directory in directories"
      :key="directory"
    >
      <div
        class="pb-4"
      >
        <v-icon class="folder-icon">mdi-folder-outline</v-icon>
        <span class="title folder-name">
          {{ directory }}/
        </span>
      </div>

      <v-container class="pa-0">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            :md="(windowWidth <= 1200) ? 12 : 6"
            :lg="(windowWidth > 1560) ? 4 : ((windowWidth <= 1200) ? 12 : 6)"
            xl="3"
            v-for="model in modelsByDirName[directory]"
            :key="model.key"
            class="file-card-flex"
          >
            <file-card
              ref="fileCards"
              :model="model"
              :directory="directory"
              :transition-duration="fileCardActivateDuration"
              :active-semaphore="activeFileCardSemaphore"
              @pre-activate="scrollToElement"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div
      class="generate-files-fab"
    >
      <generate-files-fab
        :project-name="projectName"
        :root-project="rootProject"
        :all-models="allModels"
        :models="models"
        :disabled="fabDisabled"
      />
    </div>

  </v-container>
</template>

<script>
import OnPageLoadDone from "@/utils/on-page-load-done";
import Semaphore from "@/utils/semaphore";
import debounce from "lodash.debounce";
import { DialogNavigator } from "vue-app-components";
import FileCard from "@/components/file-card.vue";
import GenerateFilesFab from "@/components/generate-files-fab.vue";
import groupBy from "lodash.groupby";

export default {
  name: "file-grid",

  mixins: [
    OnPageLoadDone
  ],

  components: {
    DialogNavigator,
    FileCard,
    GenerateFilesFab
  },

  props: {
    rootProject: {
      type: Object,
      required: false
    },
    allModels: {
      type: Array,
      required: true
    },
    models: {
      type: Array,
      required: true
    },
    projectName: {
      type: String
    },
    fabDisabled: {
      type: Boolean,
      required: true
    }
  },

  created () {
    this.activeFileCardSemaphore = new Semaphore(1);
    this.onPageLoadDone().then(() => {
      this.pageLoadDone = true;
    });
  },

  mounted () {
    this.getCoordinates();
  },

  data () {
    return {
      pageLoadDone: false,
      windowHeight: 0,
      windowWidth: 0,
      targetRectTop: 0,
      targetRectLeft: 0,
      targetRectWidth: 0,
      targetRectHeight: 0,
      fileGridTop: 0,
      fileGridLeft: 0,
      fileGridWidth: 0,
      fileCardActivateDuration: 430
    };
  },

  computed: {

    directories () {
      const priorities = {
        ".": 1,
        policies: 2,
        seqs: 3,
        env: 4,
        tb: 5,
        "tb/policies": 6,
        "tb/seqs": 7,
        "tb/work": 8
      };
      return Object.keys(this.modelsByDirName)
        .sort((a, b) => {
          if (priorities[a] > priorities[b]) {
            return 1;
          } else if (priorities[a] < priorities[b]) {
            return -1;
          } else {
            return 0;
          }
        });
    },

    modelsByDirName () {
      return groupBy(this.models, "relativeDirPath");
    },

    showFileGridCover () {
      return (this.$store.getters.hasActiveFileCard || this.showDialog);
    },

    showDialog () {
      const dialog = this.$route.query.toolDialog;
      return !((dialog === undefined) ||
        ((typeof dialog === "object") && (dialog.length === 0)));
    },

    fileGridStyles () {
      return {
        "--window-height": this.windowHeight + "px",
        "--target-rect-top": this.targetRectTop + "px",
        "--target-rect-left": this.targetRectLeft + "px",
        "--target-rect-width": this.targetRectWidth + "px",
        "--target-rect-height": this.targetRectHeight + "px",
        "--file-grid-top": this.fileGridTop + "px",
        "--file-grid-left": this.fileGridLeft + "px",
        "--file-grid-width": this.fileGridWidth + "px",
        "--file-card-activate-duration": this.fileCardActivateDuration + "ms"
      };
    }

  },

  methods: {

    scrollToElement (el, resolve) {
      if (this.$store.getters.openedByUser) {
        if (this.$route.meta.fields.isAToolRoute) {
          this.getCoordinates().then(resolve);
        } else { // happens when navigated away from tool route
          resolve();
        }
      } else {
        if (this.pageLoadDone) {
          this.scroll(el, resolve);
        } else {
          this.onPageLoadDone().then(() => {
            this.scroll(el, resolve);
          });
        }
      }
    },

    isInViewport (element, parentElement) {
      const rect = element.getBoundingClientRect();
      const rectOutter = parentElement.getBoundingClientRect();
      return (
        rect.top >= rectOutter.top &&
        rect.left >= rectOutter.left &&
        rect.bottom <= rectOutter.bottom &&
        rect.right <= rectOutter.right
      );
    },

    scroll (el, resolve) {
      const parentNode = this.$refs.fileGrid && this.$refs.fileGrid.$parent.$el;
      if (parentNode) {
        if (!this.isInViewport(el, parentNode)) {
          parentNode.scrollTop = el.offsetTop - parentNode.offsetTop - 120;
          let r;
          const scrollEnd = new Promise(resolve => { r = resolve; });
          const debouncedScrollEvents = debounce(r, 100); // scroll events can come in every 50ms. doubling that.
          parentNode.addEventListener("scroll", debouncedScrollEvents);
          // It takes about a 500ms from scrollTop assignment to first scroll event. Have to wait at least that long to see if a scroll is going to happen.
          // Sometimes no scroll happens but there is no simple/reliable way to figure this out.
          setTimeout(debouncedScrollEvents, 500);
          scrollEnd.then(() => {
            parentNode.removeEventListener("scroll", debouncedScrollEvents);
            if (this.$route.meta.isAToolRoute) {
              this.getCoordinates().then(resolve);
            } else {
              resolve();
            }
          });
        } else {
          this.getCoordinates().then(resolve);
        }
      }
    },

    getCoordinates () {
      if (this.coordinatesPromise) {
        return this.coordinatesPromise;
      } else {
        let r;
        const promise = new Promise(resolve => { r = resolve; });
        this.coordinatesPromise = promise;

        const fileGrid = this.$refs.fileGrid.$el;
        const targetDiv = this.$refs.targetDiv;

        if (!(fileGrid && targetDiv)) {
          throw new Error("Could not get coordinates of fileGrid.");
        }

        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;

        const rect = fileGrid.parentNode.getBoundingClientRect();
        const targetRect = targetDiv.getBoundingClientRect();

        this.fileGridTop = rect.top;
        this.fileGridLeft = rect.left;
        this.fileGridWidth = rect.width;
        this.targetRectTop = targetRect.top;
        this.targetRectLeft = targetRect.left;
        this.targetRectWidth = targetRect.width;
        this.targetRectHeight = targetRect.height;

        this.resolveOnStyleProperties(fileGrid, r);

        return promise;
      }
    },
    resolveOnStyleProperties (fileGrid, resolve) {
      if (
        Math.round(fileGrid.style.getPropertyValue("--window-height").replace("px", "")) === Math.round(this.windowHeight) &&
        Math.round(fileGrid.style.getPropertyValue("--file-grid-top").replace("px", "")) === Math.round(this.fileGridTop) &&
        Math.round(fileGrid.style.getPropertyValue("--file-grid-left").replace("px", "")) === Math.round(this.fileGridLeft) &&
        Math.round(fileGrid.style.getPropertyValue("--file-grid-width").replace("px", "")) === Math.round(this.fileGridWidth) &&
        Math.round(fileGrid.style.getPropertyValue("--target-rect-top").replace("px", "")) === Math.round(this.targetRectTop) &&
        Math.round(fileGrid.style.getPropertyValue("--target-rect-left").replace("px", "")) === Math.round(this.targetRectLeft) &&
        Math.round(fileGrid.style.getPropertyValue("--target-rect-width").replace("px", "")) === Math.round(this.targetRectWidth) &&
        Math.round(fileGrid.style.getPropertyValue("--target-rect-height").replace("px", "")) === Math.round(this.targetRectHeight)
      ) {
        this.coordinatesPromise = null;
        resolve();
      } else {
        setTimeout(() => {
          this.resolveOnStyleProperties(fileGrid, resolve);
        }, 10);
      }
    }
  }
};
</script>

<style lang="css">

:root {
  --file-card-height: 300px;
  --file-card-actions-height: 56px;
}

</style>

<style lang="css" scoped>

.file-card-flex {
  height: var(--file-card-height);
}

.file-grid-cover {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--background-color);
  filter: opacity(0.65);
  z-index: var(--file-grid-cover-z-index);
}

.file-grid-cover--dialog-present {
  z-index: var(--file-grid-cover-with-dialog-present-z-index);
}

.animation-target-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 85px);
  pointer-events: none;
  padding: 24px;
}

.animation-target-div {
  max-width: 580px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  margin: auto;
}

.generate-files-fab {
  position: absolute;
  z-index: var(--fab-z-index);
  bottom: 16px;
  right: 16px;
}

.folder-icon {
  margin-left: 12px;
  margin-right: 12px;
}

.folder-name {
  margin-top: 2px;
}

</style>
