<template>
  <form>
    <v-text-field
      spellcheck="false"
      autocapitalize="off"
      autocomplete="email"
      variant="underlined"
      label="Email"
      persistent-placeholder
      placeholder="your.name@example.com"
      :disabled="disabled"
      :model-value="usePaymentEmail ? user.privateProfile.paymentEmail : user.privateProfile.email"
      @update:modelValue="onInputOfPaymentEmail"
      validate-on-blur
      :rules="[verifyEmail]"
    />
  </form>
</template>

<script>
// const emailRegExp = new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      usePaymentEmail: false
    };
  },
  watch: {
    "user.privateProfile.paymentEmail": {
      handler: function (paymentEmail) {
        if (paymentEmail && paymentEmail.length) {
          this.usePaymentEmail = true;
        }
      },
      immediate: true
    }
  },
  computed: {
    user () {
      return this.$store.getters.user;
    }
  },
  methods: {
    onInputOfPaymentEmail (value) {
      const paymentEmail = (value || "").trim();

      this.user.privateProfile.immediateUpdate({ paymentEmail });
    },
    verifyEmail (value) {
      const paymentEmail = (value || "").trim();

      if (paymentEmail.length === 0) {
        return "Email can not be blank.";
      } else if (!emailRegExp.test(paymentEmail)) {
        return "Email format is not valid.";
      } else {
        return true;
      }
    }
  }
};
</script>
