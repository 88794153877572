import routes from "@/routes-for-web-and-desktop";
import store from "@/store";
import { SigningIn } from "vue-app-components";
import setDocumentTitle from "@/utils/set-document-title";
import Home from "@/views/home.vue";
import Projects from "@/views/projects/index.vue";
import CartsEdit from "@/views/carts/edit.vue";
import OrdersShow from "@/views/orders/show.vue";
import LicensesShow from "@/views/licenses/show.vue";
import Admin from "@/views/admin.vue";
import Account from "@/views/account.vue";

/*
 * Disabling dynamic import of components due to https://github.com/vitejs/vite/issues/11804
const Projects = () => import("./views/projects/index.vue");
const CartsEdit = () => import("./views/carts/edit.vue");
const OrdersShow = () => import("./views/orders/show.vue");
const LicensesShow = () => import("./views/licenses/show.vue");
const Admin = () => import("./views/admin.vue");
const Account = () => import("./views/account.vue");
*/

const REQUIRES_AUTH = ["uvcs", "uvcNew"];

REQUIRES_AUTH.forEach((routeName) => {
  const route = routes.find((route) => {
    return route.name === routeName;
  });
  route.meta.requiresAuth = true;
});

export default routes.concat([
  {
    name: "Home",
    path: "/",
    alias: "/index.html",
    component: Home,
    meta: { },
    beforeEnter: (to, from, next) => {
      setDocumentTitle();
      next();
    }
  },
  {
    name: "Projects",
    path: "/projects",
    component: Projects,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      setDocumentTitle("Projects - UVMGen");
      next();
    }
  },
  {
    name: "SigningIn",
    path: "/signing-in",
    component: SigningIn,
    meta: { }
  },
  {
    name: "Account",
    path: "/account",
    component: Account,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      setDocumentTitle("Account - UVMGen");
      next();
    }
  },
  {
    name: "Admin",
    path: "/admin",
    component: Admin,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (location.hostname === "localhost") {
        setDocumentTitle("Admin dashboard - UVMGen");
        next();
      } else {
        next({ name: "NotFound" });
      }
    }
  },
  {
    name: "Checkout",
    path: "/checkout",
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      next({ name: "cartsEdit", params: { id: store.getters.user.id } });
    }
  },
  {
    name: "cartsEdit",
    path: "/carts/:id/edit",
    component: CartsEdit,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      setDocumentTitle("Checkout - UVMGen");
      next();
    }
  },
  {
    name: "ordersShow",
    path: "/orders/:id",
    component: OrdersShow,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      setDocumentTitle("Order complete - UVMGen");
      next();
    }
  },
  {
    name: "licensesShow",
    path: "/licenses/:id",
    component: LicensesShow,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      setDocumentTitle("License - UVMGen");
      next();
    }
  }
]);
