<template>
  <div v-if="cart.readonlyCart && cart.result">
    <code-area
      label="Function"
      :placeholder="cart.readonlyCart.priceAdjustmentFunctionPlaceholder"
      :num-rows="5"
      :value="priceAdjustmentFunctionBuffer"
      @input="priceAdjustmentFunctionBuffer = $event"
      hint=""
    />

    <div class="d-flex justify-end">
      <div>
        <v-btn
          color="primary"
          block
          :disabled="priceAdjustmentFunctionBuffer === cart.readonlyCart.priceAdjustmentFunction"
          @click="cart.readonlyCart.immediateUpdate({ priceAdjustmentFunction: priceAdjustmentFunctionBuffer })"
        >
          PUBLISH FUNCTION
        </v-btn>

        <v-btn
          class="mt-2"
          block
          color="primary"
          @click="cart.immediateUpdate({ writable: !cart.writable })"
        >
          {{ cart.writable ? 'FREEZE CART' : 'UNFREEZE CART' }}
        </v-btn>
      </div>
    </div>

    <div>
      Order type: {{ cart.orderType || "initial" }}
    </div>
    <div>
      License duration: {{ cart.result.licenseDurationInMonths }} months
    </div>
    <div v-if="cart.isUpgradeOrder">
      Prorated duration: {{ cart.result.proratedDurationInMonths}} months
    </div>
    <v-row
      class="mt-8"
      no-gutters
    >
      <v-col>
        <v-row
          class="mx-4"
        >
          <v-col>
          </v-col>
          <v-col>
            <i>Quantity</i>
          </v-col>
          <v-col>
            <i>Global price</i>
          </v-col>
          <v-col>
            <i>Custom cart price</i>
          </v-col>
        </v-row>
        <v-row
          v-for="(feature, featureIndex) in user.priceSheet.features"
          :key="featureIndex + 'a'"
          class="mx-4"
        >
          <v-col>
            {{ feature.id }}
          </v-col>
          <v-col>
            {{ cart[feature.id + "Quantity"] }}
          </v-col>
          <v-col>
            {{ $stripeManager.formatPrice(globalPriceTimesQuantityAndMonths(feature)) }}
          </v-col>
          <v-col>
            {{ $stripeManager.formatPrice(cartPriceTimesQuantityAndMonths(feature)) }}
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-col>
            Total (before tax)
          </v-col>
          <v-col>
          </v-col>
          <v-col>
            {{ $stripeManager.formatPrice(globalPriceTotal) }}
          </v-col>
          <v-col>
            {{ $stripeManager.formatPrice(cartPriceTotal) }}
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-col>
            Tax
          </v-col>
          <v-col>
          </v-col>
          <v-col>
            {{ $stripeManager.formatPrice(cart.result["tax" + suffix]) }}
          </v-col>
          <v-col>
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-col>
            Total + tax
          </v-col>
          <v-col>
          </v-col>
          <v-col>
            {{ $stripeManager.formatPrice(cart.result["priceWithTax" + suffix]) }}
          </v-col>
          <v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CodeArea from "@/components/code-area.vue";

export default {
  props: {
    cart: {
      type: Object,
      required: true
    }
  },
  inject: ["$stripeManager"],
  components: {
    CodeArea
  },
  data () {
    return {
      priceAdjustmentFunctionBuffer: ""
    };
  },
  watch: {
    "cart.readonlyCart.priceAdjustmentFunction": {
      handler: function (value) {
        this.priceAdjustmentFunctionBuffer = value;
      },
      immediate: true
    }
  },
  computed: {
    user () {
      return this.$store.getters.user;
    },
    suffix () {
      return this.cart.isUpgradeOrder ? "Prorated" : "FullTerm";
    },
    globalPriceTotal () {
      let sum = 0;
      this.user.priceSheet.features.forEach(feature => { sum = sum + this.globalPriceTimesQuantityAndMonths(feature); });
      sum = +sum.toFixed(2);

      const cloudSum = this.cart.result["priceWithGlobalDiscount" + this.suffix];
      if (cloudSum && (cloudSum !== sum)) {
        throw new Error(`Expected cloud price of ${cloudSum} to match client calculated price of ${sum}.`);
      }

      return sum;
    },
    cartPriceTotal () {
      let sum = 0;
      this.user.priceSheet.features.forEach(feature => { sum = sum + this.cartPriceTimesQuantityAndMonths(feature); });
      return sum;
    },
    numMonthsToCharge () {
      return this.cart.isUpgradeOrder
        ? this.cart.result.proratedDurationInMonths
        : this.cart.result.licenseDurationInMonths;
    }
  },
  methods: {
    opts (feature) {
      return { feature, licenseDurationInMonths: this.cart.result.licenseDurationInMonths, quantity: this.cart[feature.id + "Quantity"] };
    },
    globalPrice (feature) {
      let result;
      try {
        const opts = Object.assign({}, this.opts(feature));
        result = eval("(" + this.user.priceSheet.priceAdjustmentFunction + ")")(opts); // eslint-disable-line no-eval
        if (isNaN(result) || ((typeof result) !== "number")) {
          console.error("Result was not a number, instead was:", JSON.stringify(result));
        }
      } catch (e) {
        result = JSON.stringify({
          type: "ERROR",
          content: this.user.priceSheet.priceAdjustmentFunction,
          message: e.message
        });
      }
      return result;
    },
    globalPriceTimesQuantityAndMonths (feature) {
      return (this.globalPrice(feature) * this.opts(feature).quantity) * this.numMonthsToCharge;
    },
    cartPriceTimesQuantityAndMonths (feature) {
      let result;
      try {
        const opts = Object.assign({}, this.opts(feature), { monthlyPriceWithGlobalDiscount: this.globalPrice(feature) });
        if (this.priceAdjustmentFunctionBuffer) {
          result = eval("(" + this.priceAdjustmentFunctionBuffer + ")")(opts); // eslint-disable-line no-eval
        } else {
          result = opts.monthlyPriceWithGlobalDiscount;
        }
        if (isNaN(result) || ((typeof result) !== "number")) {
          console.error("Result was not a number, instead was:", JSON.stringify(result));
        }
        result = (result * opts.quantity) * this.numMonthsToCharge;
      } catch (e) {
        result = JSON.stringify({
          type: "ERROR",
          content: this.priceAdjustmentFunctionBuffer,
          message: e.message
        });
      }
      return result;
    }
  }
};

</script>
