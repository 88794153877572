import auth from "@/utils/auth-VITE_APP_PLATFORM";
import { createApp } from "vue";
import App from "@/app.vue";

import store from "@/store";
import router from "@/router";
import setupErrorHandling from "@/utils/error-handler-VITE_APP_PLATFORM";
import registerServiceWorker from "@/utils/register-service-worker-VITE_APP_PLATFORM";
import stripeManager from "@/utils/stripe-manager-VITE_APP_PLATFORM";
import { createSignInComponent } from "vue-app-utils-web";

import { VContainer } from "vuetify/components";
import { Resize } from "vuetify/directives";
import "@/assets/app.css";
// there is also prism-dark.css etc and https://github.com/PrismJS/prism-themes for additional themes
import "prismjs/themes/prism.css";
import registerMarkdownComponents from "@/utils/register-markdown-components";
import { Vue3SlideUpDown } from "vue3-slide-up-down";
import vuetify from "@/plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import dialog from "@/utils/dialog";
import DialogSendFeedback from "@/components/dialog-send-feedback.vue";
import DialogHelp from "@/components/dialog-help.vue";
import DialogSelectEnvForTb from "@/components/dialog-select-env-for-tb.vue";
import DialogStripeElements from "@/components/dialog-stripe-elements-VITE_APP_PLATFORM.vue";
import DialogStripeVerifyMicrodeposits from "@/components/dialog-stripe-verify-microdeposits-VITE_APP_PLATFORM.vue";
import DialogLicenseAssets from "@/components/dialog-license-assets-VITE_APP_PLATFORM.vue";
import DialogOnPremiseLicense from "@/components/dialog-on-premise-license-VITE_APP_PLATFORM.vue";

Error.stackTraceLimit = Infinity;

const app = createApp(App);
app.use(store);
registerServiceWorker();

if (store.getters.isWeb) {
  import.meta.glob("./utils/offline-state-manager");
}

app.use(vuetify);
app.config.productionTip = false;
app.provide("$auth", auth);
app.provide("$stripeManager", stripeManager);
app.provide("$dialog", dialog);
app.config.globalProperties.$auth = auth;
app.config.globalProperties.$stripeManager = stripeManager;
app.config.globalProperties.$dialog = dialog;
// [TODO] re-enable by moving functionality to vue-app-components
app.component("sign-in-and-save-to-the-cloud", createSignInComponent("mdi-cloud-check", "Sign in", "Your UVCs will be saved to the cloud automatically."));
// app.component("sign-in-and-send-feedback", createSignInComponent("mdi-message-alert", "Send feedback", "You must sign in as non-guest to get a reply."));
app.component("dialog-send-feedback", DialogSendFeedback);
app.component("dialog-help", DialogHelp);
if (store.getters.isWeb) {
  app.component("dialog-stripe-elements", DialogStripeElements);
  app.component("dialog-stripe-verify-microdeposits", DialogStripeVerifyMicrodeposits);
  app.component("dialog-on-premise-license", DialogOnPremiseLicense);
} else {
  app.component("dialog-select-env-for-tb", DialogSelectEnvForTb);
  app.component("dialog-license-assets", DialogLicenseAssets);
}
app.component("vue3-slide-up-down", Vue3SlideUpDown);
app.component("v-container", VContainer);
registerMarkdownComponents(app);
app.directive("resize", Resize);

setupErrorHandling(app, store);

auth.onReady(async () => {
  app.use(router);
  app.mount("#app");
  if (window.Cypress) {
    window.authSignOut = function () {
      auth.signOut();
    };
  }
  return app;
});

// [TODO] ARGV is handled by NW.js.
// http://docs.nwjs.io/en/latest/References/App/
// [TODO] CLI: this would need to be inserted somewhere before render.
// I don't know how to do that yet. Would be good to have io file checks
// happen before render too and just output error message on the command
// line.
// let dataFile;
//
// if (isDesktop && !window.Cypress) {
//
//   const parseArgs = require('electron-args');
//
//   const cli = parseArgs(`
//       sample-viewer
//
//       Usage
//         $ sample-viewer [path]
//
//       Options
//         --help     show help
//         --version  show version
//         --auto     slide show [Default: false]
//
//       Examples
//         $ sample-viewer . --auto
//         $ sample-viewer ~/Pictures/
//   `, {
//       alias: {
//           h: 'help'
//       },
//       default: {
//           auto: false
//       }
//   });
//
//   // const opts = cli.flags;
//   const args = cli.input;
//
//   dataFile = args[0] || process.env['UVMGEN_DATA_FILE'];
//
//   if (typeof dataFile !== 'string') {
//     console.log("Must specify a data file as an argument or through " +
//         "the UVMGEN_DATA_FILE environment variable.");
//     process.exit(0);
//   } else {
//     const fs = require('fs');
//     dataFile = require('path').resolve(dataFile);
//     if (!fs.existsSync(dataFile)) {
//       console.log(dataFile + " does not exist.");
//       console.log("Creating " + dataFile);
//       fs.writeFileSync(dataFile, JSON.stringify({}));
//     }
//   }
// }
//
// console.log({
//   processenv: process.env,
//   dataFile
// });
// //                                               // serve e2ew e2ee s:e b:e
// console.log(process.env['NODE_ENV']);                // y y    y    e
// console.log(process.env['VUE_CLI_MODE']);            // n n    n
// console.log(process.env['UVMGEN_DATA_FILE'])         // n n    n
// console.log(process.env['VUE_APP_UVMGEN_DATA_FILE']) // y y    y
