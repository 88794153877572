<template>
  <div style="max-width: 700px;">
    <div class="d-flex justify-space-between">
      <span class="mt-3">{{ user.privateProfile.email }}</span>
      <span v-if="user.uvcs.length" class="mt-3">{{ user.uvcs.length }} uvcs, {{ user.uvcs.reduce((sum, uvc) => sum + uvc.numDownloads, 0) }} downloads</span>
      <v-badge
        style="max-width: calc(100% - 40px);"
        :model-value="sendThemAnEmail"
        :color="badgeColor"
        :content="user.numEmails"
      >
        <v-btn
          variant="text"
          :icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          @click="show = !show">
        </v-btn>
      </v-badge>
    </div>
    <div v-show="show">
      <div class="mt-4">Name: {{ user.displayName }}</div>
      <div>id: {{ user.id }}</div>
      <div>User since: {{ user.createdAt.toDate() }}</div>
      <div>paymentEmail: {{ user.privateProfile.paymentEmail }}</div>
      <div>companyName: {{ user.privateProfile.companyName }}</div>
      <div>companySize: {{ user.privateProfile.companySize }}</div>
      <div>companyDvSize: {{ user.privateProfile.companyDvSize }}</div>
      <div>teamDvSize: {{ user.privateProfile.teamDvSize }}</div>
      <div>benefits: {{ user.privateProfile.benefits }}</div>
      <div>UVCs:</div>
      <div
        v-for="uvc in user.uvcs"
        :key="uvc.id"
        class="ml-4"
      >
        {{ uvc.name }} (downloads: {{uvc.numDownloads}})
      </div>
      <div>Emails:</div>
      <v-expansion-panels v-if="show" class="ml-4">
        <v-expansion-panel
          v-for="email in user.emails"
          :key="email.id"
          :title="email.subject"
        >
          <v-expansion-panel-text>
            <div>
              {{ email.createdAt.toDate() }}
            </div>
            <div>
              Status: {{ email.state || "UNKNOWN" }}
            </div>
            <pre class="mt-3 pa-2" style="border: 1px solid black; text-wrap: wrap; max-width: 700px;">{{ email.text }}</pre>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="mt-6 d-flex justify-space-between">
        <span class="mt-3 text-h6">Email</span>
        <v-checkbox
          style="max-width: 150px;"
          :label="user.unsubscribedToEmails ? 'Unsubscribed' : 'Subscribed'"
          :model-value="user.unsubscribedToEmails"
          @update:modelValue="(value) => user.immediateUpdate({ unsubscribedToEmails: value })"
        />
      </div>

      <div v-if="!user.unsubscribedToEmails">
        <div class="d-flex justify-space-between">
          <v-text-field
            class="mt-5"
            variant="outlined"
            label="Email"
            :placeholder="emailPlaceholder"
            :disabled="linkedIn || meeting"
            v-model="email"
          ></v-text-field>
          <div>
            <v-checkbox
              style="max-width: 150px;"
              label="LinkedIn"
              hide-details
              :model-value="linkedIn"
              @update:modelValue="setLinkedIn($event)"
            />
            <v-checkbox
              style="max-width: 150px;"
              label="Meeting notes"
              hide-details
              :model-value="meeting"
              @update:modelValue="setMeeting($event)"
            />
          </div>
        </div>

        <v-text-field
          class="mt-5"
          variant="outlined"
          label="Subject"
          :placeholder="subjectPlaceholder"
          v-model="subject"
        ></v-text-field>

        <code-area
          class="mt-4"
          label="Message"
          :placeholder="messagePlaceholder"
          hint="Input email message."
          :value="message"
          @input="message = $event"
        />

        <v-btn
          class="mt-4"
          prepend-icon="mdi-mail"
          @click="sendMail()"
        >
          {{ (linkedIn || meeting) ? "SAVE" : "SEND MAIL" }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CodeArea from "@/components/code-area.vue";
import { sendMail } from "vue-app-utils-web";
import { currentTime } from "@/utils/time-web";
import { formatDate } from "vue-app-utils";

const BENEFITS = {
  headCountConstraints: "mitigate your head count constraints",
  challenginDeadlines: "more easily meet your deadlines",
  increaseVipQuality: "increase the quality of your VIP",
  useBestPractices: "ensure your engineers are using industry best practices",
  helpNewEngineers: "help your new engineers be productive",
  replaceTestbenchGenerationSolution: "replace your current testbench generation solution"
};

export default {
  components: {
    CodeArea
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      show: false,
      email: "",
      subject: "",
      message: "",
      linkedIn: false,
      meeting: false
    };
  },
  watch: {
    emailPlaceholder: {
      handler () {
        this.email = this.emailPlaceholder;
      },
      immediate: true
    },
    subjectPlaceholder: {
      handler () {
        this.subject = this.subjectPlaceholder;
      },
      immediate: true
    },
    messagePlaceholder: {
      handler () {
        this.message = this.messagePlaceholder;
      },
      immediate: true
    }
  },
  created () {
    this.formatDate = formatDate;
  },
  computed: {
    uvcsWithNames () {
      return this.user.uvcs.filter(uvc => uvc.name);
    },
    badgeColor () {
      if (this.user.numEmails === 0) {
        return "error";
      } else if (this.user.numEmails === 1) {
        return "orange";
      } else if (this.user.numEmails === 2) {
        return "blue";
      } else if (this.user.numEmails === 3) {
        return "purple";
      } else {
        return "black";
      }
    },
    sendThemAnEmail () {
      if (this.user.unsubscribedToEmails) {
        return false;
      }
      if (this.user.numEmails === 0) {
        return this.user.daysSinceEmailed > 2;
      } else if (this.user.numEmails === 1) {
        return this.user.daysSinceEmailed > 4;
      } else if (this.user.numEmails === 2) {
        return this.user.daysSinceEmailed > 10;
      } else if (this.user.numEmails === 3) {
        return this.user.daysSinceEmailed > (7 * 7);
      } else {
        return this.user.daysSinceEmailed > (7 * 4 * 5);
      }
    },
    emailPlaceholder () {
      if (this.linkedIn || this.meeting) {
        return "ben.delsol@uvmgen.com";
      } else {
        return this.user.privateProfile.email || this.user.privateProfile.paymentEmail;
      }
    },
    highlightedUvc () {
      const lastDownloadedUvc = this.uvcsWithNames.findLast(uvc => uvc.numDownloads);
      const lastCreatedUvc = this.uvcsWithNames[this.uvcsWithNames.length - 1];
      return lastDownloadedUvc || lastCreatedUvc;
    },
    subjectPlaceholder () {
      if (this.linkedIn) {
        return "LinkedIn: Connect message";
      } else if (this.meeting) {
        return "Meeting notes - " + this.formatDate(new Date(Date.now()));
      } else {
        const subject = this.highlightedUvc
          ? `How did it go with the ${this.highlightedUvc.name} UVC?`
          : "Thanks for checking out uvmgen.com";
        return (this.user.numEmails === 0)
          ? subject
          : `Re: ${subject}`;
      }
    },
    greeting () {
      let firstName;
      if (this.user.displayName) {
        const match = this.user.displayName.match(/^([a-zA-Z]+)/);
        if (match) {
          firstName = match[0];
        }
      }
      const hi = (this.linkedIn || (this.user.numEmails > 0))
        ? "Hey"
        : "Hi";
      return firstName ? `${hi} ${firstName}` : hi;
    },
    messagePlaceholder () {
      if (this.linkedIn) {
        const intro = this.highlightedUvc
          ? "I see you created the " + this.highlightedUvc.name + " UVC on uvmgen.com and sent you an email about it"
          : "I see you had some interest in uvmgen.com and sent you an email about it";
        return `${this.greeting}, ${intro}. Just connecting here to put a face to the name.`;
      } else if (this.meeting) {
        return "";
      } else if (this.user.numEmails === 0) {
        const uvcNames = [...new Set(this.uvcsWithNames.map(uvc => uvc.name))];
        let uvcPhrase;
        if (uvcNames.length) {
          uvcPhrase = "I see you created the " + this.joinPhrases(uvcNames) + " UVC";
          if (uvcNames.length > 1) {
            uvcPhrase = uvcPhrase + "s";
          }
          uvcPhrase = uvcPhrase + " - how did that go for you?";
        } else {
          uvcPhrase = "Hopefully, you can use our free online UVC code generator in one of your current projects. Did you find what you needed on the site?";
        }
        const benefitPhrases = this.user.privateProfile.benefits.map(benefit => BENEFITS[benefit]);
        let benefitPhrase = this.joinPhrases(benefitPhrases);
        if (benefitPhrases.length) {
          benefitPhrase = " Because of this, UVMGen can help you to " + benefitPhrase + ".";
        }
        const purposePhrase = uvcNames.length
          ? "I'd love to hear your experience and thoughts and help you make the most of UVMGen."
          : "I'd love to help you make the most of UVMGen.";
        return `${this.greeting},

Thank you for checking out uvmgen.com. ${uvcPhrase}

As a DV engineer myself, I understand the struggle of tight schedules, the complexity of VIP reuse/scalability and the temptation of shortcuts leading to slowing development and potential rewrites. This is why I founded UVMGen. It simplifies and accelerates testbench creation with built-in industry best practices, facilitating easy development, integration, and VIP reuse. Think of it as Legos for testbenches, enabling one-click integration of UVCs and environments, all implemented the right way from the beginning.${benefitPhrase}

${purposePhrase} Are you available Wednesday between 8:00am and 12:00pm PST (UTC-8) for a brief chat?

Best,
Ben Delsol
Founder of UVMGen LLC
www.uvmgen.com
www.linkedin.com/in/benjamin-delsol-86390b75
916-812-8203
`;
      } else if (this.user.numEmails === 1) {
        return `${this.greeting},

Hope you are well.

I'm curious what brought you to uvmgen.com? Was it the speed of UVC development, VIP quality (baked-in industry best practices) or something else?

All the best,
Ben

www.linkedin.com/in/benjamin-delsol-86390b75
`;
      } else if (this.user.numEmails === 2) {
        return `${this.greeting},

Do let me know if there is anything I can do for you, technical or otherwise. I am here to help.

If there is anyone you think UVMGen could really help, please don't hesitate to connect us.

Cheers,
Ben
Founder of UVMGen LLC
www.uvmgen.com
www.linkedin.com/in/benjamin-delsol-86390b75
916-812-8203
`;
      } else {
        return "";
      }
    }
  },
  methods: {
    setLinkedIn (event) {
      this.linkedIn = event;
      this.meeting = false;
    },
    setMeeting (event) {
      this.meeting = event;
      this.linkedIn = false;
    },
    joinPhrases (phrases, defaultPhrase = "") {
      if (phrases.length === 0) {
        return defaultPhrase;
      } else if (phrases.length === 1) {
        return phrases[0];
      } else {
        return phrases.slice(0, -1).join(", ") + " and " + phrases[phrases.length - 1];
      }
    },
    async sendMail () {
      await sendMail(this.email, "ben.delsol@uvmgen.com", "Ben Delsol <ben.delsol@uvmgen.com>", this.subject, this.message, this.$store, ((this.linkedIn || this.meeting) ? "Saved." : "Email sent."), "", this.user.id);
      const data = {};
      if (this.meeting) {
        data.meetingAt = currentTime();
      } else if (this.linkedIn) {
        data.emailedAt = currentTime();
      } else {
        data.emailedAt = currentTime();
        data.numEmails = this.user.numEmails + 1;
      }
      await this.user.immediateUpdate(data);
      this.linkedIn = false;
      this.meeting = false;
    }
  }
};

</script>

<style lang="css" scoped>

:deep(.v-textarea textarea) {
  text-wrap: wrap !important;
}

</style>
