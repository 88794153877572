<template>
  <standard-layout
    v-if="loaded"
    :title="title"
    has-container
  >
    <div v-if="user.priceSheet.waitlistMode && !user.privateProfile.waitlistApproved">
      <div class="mt-8 text-h4">
        Join the waitlist
      </div>
      <div class="mt-8">
        To ensure we give our existing customers the support they deserve, we are currently in waitlist mode. Please join the waitlist and we'll let you know when you can start generating VIP on-premise with UVMGen.
      </div>
      <div class="mt-10">
        <extra-stripe-elements
          style="max-width: 400px;"
          :disabled="!!user.privateProfile.waitlistedTime"
        />
        <v-btn
          class="mt-2"
          color="primary"
          :disabled="!!user.privateProfile.waitlistedTime"
          @click="joinWaitlist"
        >
          JOIN WAITLIST
        </v-btn>
        <div
          v-if="user.privateProfile.waitlistedTime"
          class="mt-10 text-h6"
        >
          <v-icon
            color="primary"
            size="40"
          >
            mdi-check-circle
          </v-icon>
          <span class="ml-2">
            We will email you when you can purchase a UVMGen on-premise license.
          </span>
        </div>
      </div>
    </div>
    <uvmgen-stepper
      v-else
      style="max-width: 900px;"
      ref="stepper"
      :value="step"
      @change.self="updateStep"
      vertical
      elevation="0"
    >
      <uvmgen-stepper-step
        data-test="company-info-step-button"
        editable
        edit-icon="$vuetify.icons.complete"
        :complete="stepCompanyInfoComplete"
        :step="stepCompanyInfo"
      >
        Company information
        <!--<small>Summarize if needed</small>-->
      </uvmgen-stepper-step>

      <uvmgen-stepper-content
        data-test="company-info-step-content"
        :step="stepCompanyInfo"
      >
        <div v-if="$route.query.step == stepCompanyInfo">
          <div
            style="max-width: 400px;"
          >
            <v-text-field
              autofocus
              spellcheck="false"
              autocapitalize="off"
              variant="underlined"
              label="Company name *"
              :error-messages="companyNameErrorMessages"
              :model-value="user.privateProfile.companyName"
              @update:modelValue="onInputOfCompanyName"
            />
          </div>

          <v-text-field
            class="mt-2"
            style="max-width: 300px;"
            variant="underlined"
            label="DV engineers in your team *"
            type="number"
            :min="1"
            :error-messages="teamDvSizeErrorMessages"
            :model-value="user.privateProfile.teamDvSize"
            @update:modelValue="onInputOfTeamDvSize"
          ></v-text-field>

          <v-select
            class="mt-2"
            style="max-width: 300px;"
            variant="underlined"
            :label="`DV engineers in ${user.privateProfile.companyName || 'your company'}`"
            :items="['5', '10', '20', '50', '100', '150', '200', '250+']"
            :model-value="user.privateProfile.companyDvSize"
            @update:modelValue="user.privateProfile.update({ companyDvSize: $event })"
          />

          <v-text-field
            v-if="false"
            class="mt-2"
            style="max-width: 300px;"
            variant="underlined"
            :label="`Employees in ${user.privateProfile.companyName || 'your company'}`"
            type="number"
            :min="1"
            :error-messages="companySizeErrorMessages"
            :model-value="user.privateProfile.companySize"
            @update:modelValue="onInputOfCompanySize"
          ></v-text-field>

          <!--[TODO] let user type benefits-->
          <v-select
            class="mt-2"
            style="max-width: 600px;"
            variant="underlined"
            :items="[{ title: 'To mitigate head count constraints', value: 'headCountConstraints' }, { title: 'To easily meet deadlines', value: 'challenginDeadlines' }, { title: 'To increase VIP quality', value: 'increaseVipQuality' }, { title: 'To ensure engineers are using industry best practices', value: 'useBestPractices' }, { title: 'To help new engineers be productive', value: 'helpNewEngineers' }, { title: 'To replace our current testbench generation solution', value: 'replaceTestbenchGenerationSolution' }]"
            :menu-props="{ allowOverflow: true, offsetOverflow: true, contentClass: 'v-select-menu--benefits' }"
            multiple
            chips
            closable-chips
            label="What benefits are you looking to get from UVMGen?"
            :model-value="user.privateProfile.benefits"
            @update:modelValue="user.privateProfile.update({ benefits: $event })"
          />

          <div>
            <v-btn
              class="continue-button mt-12"
              color="primary"
              :disabled="!stepCompanyInfoComplete"
              @click="nextStep(stepCompanyInfo)"
            >CONTINUE</v-btn>
          </div>
        </div>
      </uvmgen-stepper-content>

      <uvmgen-stepper-step
        data-test="cart-step-button"
        :editable="stepCompanyInfoComplete"
        edit-icon="$vuetify.icons.complete"
        :complete="stepCartComplete"
        :step="stepCart"
      >
        License parameters
        <!--<small>Summarize if needed</small>-->
      </uvmgen-stepper-step>

      <uvmgen-stepper-content
        data-test="cart-step-content"
        :step="stepCart"
      >
        <div v-if="($route.query.step == stepCart) && stepCompanyInfoComplete">
          <div v-if="(activeLicenses.length > 0) && !cart.isRenewalOrder">
            <v-radio-group
              :disabled="!cart.writable"
              :model-value="cart.orderType"
              @update:modelValue="onUpgradeExistingRadioSelect"
            >
              <v-radio
                label="Upgrade existing license (typical case)"
                value="upgrade"
              ></v-radio>
              <v-radio
                label="Create new license"
                value="initial"
              ></v-radio>
            </v-radio-group>
            <v-select
              v-if="cart.isUpgradeOrder"
              class="mt-6"
              variant="underlined"
              :items="activeLicenses.map(license => { return { title: `${license.durationInMonths}-month license (${license.licenseKey})${license.termPaymentProcessing ? ' - Processing...' : ''}`, value: license.id, disabled: license.termPaymentProcessing }; })"
              label="License to upgrade"
              :disabled="!cart.writable"
              :model-value="cart.licenseId"
              @update:modelValue="onSelectUpgradeLicense"
            />
          </div>

          <v-card
            v-if="(!activeLicenses.length || cart.orderType === 'initial') && !cart.newLicenseBundleCreated"
            class="mt-6 mb-10"
            max-width="405"
            elevation="3"
          >
            <v-card-title class="text-h5" style="word-break: break-word;">
              How many testbenches do you want to generate?
            </v-card-title>
            <v-card-subtitle class="mt-2">
              With your new super powers, your team can handle more testbenches per person. You can verify all the levels of design you wish you had testbenches for.
            </v-card-subtitle>
            <v-card-text class="my-2">
              <select-infinite
                class="mt-4"
                label="Testbenches"
                hint="Number of testbenches UVMGen will generate and maintain."
                :feature="user.priceSheet.features.find(feature => feature.id === 'tb')"
                :pack-size="packSizes.tb"
                :value="tempTbQuantity"
                @change="tempTbQuantityOverride = $event"
              >
              </select-infinite>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                :disabled="(typeof tempTbQuantity) !== 'number'"
                @click="createNewLicenseBundle(tempTbQuantity)"
              >
                SHOW LICENSE DETAILS
              </v-btn>
            </v-card-actions>
          </v-card>
          <div
            v-if="(cart.isInitialOrder && cart.newLicenseBundleCreated) || (cart.isUpgradeOrder && cart.upgradeLicenseBundleCreated) || cart.isRenewalOrder"
          >
            <v-table
              class="mt-4"
              density="compact"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Feature
                    </th>
                    <th class="text-left" style="width: 253px;">
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(feature, featureIndex) in user.priceSheet.features"
                    :key="featureIndex"
                  >
                    <td>
                      {{ titles[feature.id] }}
                    </td>
                    <td style="width: 253px;">
                      <div style="display: flex; justify-content: space-between;">
                        <select-infinite
                          v-if="((typeof cart[feature.id + 'Quantity']) === 'number')"
                          style="max-width: 83px; max-width: 191px; position: relative; top: -5px;"
                          :feature="feature"
                          :pack-size="packSizes[feature.id]"
                          :value="cart[feature.id + 'Quantity']"
                          :disabled="cart.isRenewalOrder || !cart.writable || (cart.result.priceWithGlobalAndCustomDiscount === null)"
                          @change="feature.id === 'tb' ? createNewLicenseBundle($event) : onQuantityUpdate(feature.id, $event.toString(), true)"
                        >
                        </select-infinite>
                        <v-tooltip
                          bottom
                        >
                          <template v-slot:activator="{ props }">
                            <v-icon
                              style="margin-top: 10px;"
                              size="18"
                              v-bind="props"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span>{{ quantityDescriptions[feature.id] }}</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-table>

            <div class="mt-8 d-flex flex-column flex-md-row-reverse justify-end justify-md-space-between align-end align-md-start">
              <v-select
                v-if="licenseDurationOptions.length"
                class="license-duration__v-select"
                variant="filled"
                :label="cart.isUpgradeOrder ? 'Upgrade duration' : 'License duration'"
                hide-details
                :disabled="cart.isUpgradeOrder || cart.isRenewalOrder || !cart.writable"
                :items="licenseDurationOptions"
                :model-value="cart.result.durationInMonths"
                @update:modelValue="onLicenseDurationUpdate"
              />
              <div class="d-flex align-end">
                <download-quote-button
                  v-if="!cart.result.errorMessage && subTotal"
                  class="order-first order-md-last"
                  :cart="cart"
                  :clientCompanyName="user.privateProfile.companyName"
                  :subTotal="$stripeManager.formatPrice(subTotal)"
                />
                <div
                  class="ml-4 order-last order-md-first"
                  style="width: 100%; width: 310px; position: relative;"
                >
                  <v-progress-circular
                    v-if="(cart.result.priceWithGlobalAndCustomDiscount === null) && false"
                    style="position: absolute; bottom: 20px; right: 8px;"
                    indeterminate
                    color="primary"
                  />
                  <div
                    class="pb-4 pb-md-1 d-flex flex-wrap justify-space-between text-h6"
                    style="margin-top: 23px; border-bottom: 1px solid rgba(0, 0, 0, 0.3);"
                  >
                    <div
                    >
                      Cost per month:
                    </div>
                    <div
                      class="flex-grow-1 text-right"
                    >
                      {{ $stripeManager.formatPrice(cart.isRenewalOrder ? cart.result.monthlyPriceWithRenewalDiscount : cart.result.monthlyPriceWithGlobalAndCustomDiscount) }}
                    </div>
                  </div>
                  <div
                    class="mt-3 text-subtitle-1"
                  >
                    <div class="d-flex justify-space-between">
                      <div>
                        License duration discount:
                      </div>
                      <div
                        v-if="(typeof cart.result.globalDiscount) === 'number'"
                        class="flex-grow-1 text-right green--text accent-4--text"
                      >
                        -{{ $stripeManager.formatPrice(cart.result.globalDiscount) }}
                      </div>
                    </div>
                    <div class="d-flex justify-space-between">
                      <div
                        v-if="!!cart.result.customDiscount"
                      >
                        Custom discount:
                      </div>
                      <div
                        v-if="!!cart.result.customDiscount"
                        class="flex-grow-1 text-right green--text accent-4--text"
                      >
                        -{{ $stripeManager.formatPrice(cart.result.customDiscount) }}
                      </div>
                    </div>
                    <div class="d-flex justify-space-between">
                      <div
                        v-if="!!cart.result.renewalDiscount"
                      >
                        Custom renewal discount:
                      </div>
                      <div
                        v-if="!!cart.result.renewalDiscount"
                        class="flex-grow-1 text-right green--text accent-4--text"
                      >
                        -{{ $stripeManager.formatPrice(cart.result.renewalDiscount) }}
                      </div>
                    </div>
                    <div class="mb-2 d-flex justify-space-between">
                      <div
                      >
                        Subtotal<span v-if="cart.result.durationInMonths"> ({{ cart.result.durationInMonths }} {{ (cart.result.durationInMonths > 1) ? "months" : "month" }})</span>:
                      </div>
                      <div
                        class="flex-grow-1 text-right"
                      >
                        {{ $stripeManager.formatPrice(subTotal) }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="cart.result.errorMessage"
                    class="d-flex flex-wrap justify-space-between text-body-2 error--text"
                  >
                    <div>
                      Error:
                    </div>
                    <div
                      class="flex-grow-1 text-right"
                    >
                      {{ cart.result.errorMessage }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <v-btn
                class="continue-button mt-14"
                color="primary"
                :loading="((cart.isRenewalOrder ? cart.result.priceWithRenewalDiscount : cart.result.priceWithGlobalAndCustomDiscount) === null) || !$stripeManager.paymentIntentUpdated"
                :disabled="!stepCartComplete || !$stripeManager.paymentIntentUpdated"
                @click="nextStep(stepCart)"
              >CONTINUE</v-btn>
            </div>
          </div>
        </div>
      </uvmgen-stepper-content>

      <uvmgen-stepper-step
        data-test="payment-step-button"
        :editable="stepCompanyInfoComplete && stepCartComplete && $stripeManager.paymentIntentUpdated"
        edit-icon="$vuetify.icons.complete"
        :complete="stepPaymentComplete"
        :step="stepPayment"
      >
        Payment
        <!--<small>Optional</small>-->
      </uvmgen-stepper-step>

      <uvmgen-stepper-content
        data-test="payment-step-content"
        :step="stepPayment"
      >
        <stripe-cards
          :full-name="user.displayName"
          :add-card-button-disabled="!user.privateProfile.paymentEmail.length"
          :show-progress="false"
        >
          <template v-slot:list-title>
            <div class="step-subheading">
              Select a default
            </div>
          </template>
          <template v-slot:add-card-title>
            <div class="step-subheading mt-4">
              Payment information
            </div>
          </template>
          <extra-stripe-elements />
        </stripe-cards>

        <div>
          <v-btn
            class="continue-button mt-12"
            color="primary"
            :loading="!!user.paymentMethods.length && ((!$stripeManager.paymentIntentUpdated && !$stripeManager.addCardErrorMessage) || (user.paymentMethods.length !== $stripeManager.loadedPaymentMethods.length))"
            :disabled="!stepPaymentComplete || !$stripeManager.paymentIntentUpdated"
            @click="nextStep(stepPayment)"
          >CONTINUE</v-btn>
        </div>
      </uvmgen-stepper-content>

      <uvmgen-stepper-step
        data-test="order-step-button"
        :editable="stepCompanyInfoComplete && stepCartComplete && stepPaymentComplete && $stripeManager.paymentIntentUpdated"
        edit-icon="$vuetify.icons.complete"
        :complete="stepOrderComplete"
        :step="stepOrder"
      >
        Review and order
        <!--<small>Optional</small>-->
      </uvmgen-stepper-step>

      <uvmgen-stepper-content
        data-test="order-step-content"
        :step="stepOrder"
      >
        <div v-if="($route.query.step == stepOrder) && stepCompanyInfoComplete && stepCartComplete && stepPaymentComplete">
          <div class="text-h6">
            {{ cart.result.durationInMonths }}-month license<span v-if="cart.isUpgradeOrder"> upgrade</span>
          </div>
          <order-review
            v-if="$stripeManager.defaultPaymentMethod && cart.result.licenseDurationInMonths"
            class="mt-10"
            :cart="cart"
            :payment-method-type="$stripeManager.defaultPaymentMethod.genericDescription"
            :payment-method-description="$stripeManager.defaultPaymentMethod.shortDescription"
            :licenseDurationInMonths="cart.result.licenseDurationInMonths"
          />

          <div v-if="renewalAlreadyProcessed" class="error--text">
            Renewal order already completed.
          </div>

          <v-checkbox
            class="mt-4"
            style="margin-left: 6px;"
            hide-details
            v-model="softwareLicenseAgreementAccepted"
            hint="By placing your order, you agree to UVMGen's Software License Agreement"
          >
            <template v-slot:label>
              <span>
                I accept/agree to UVMGen's&nbsp;<a @click.prevent="$dialog.override('software-license-agreement')">Software License Agreement</a>.
              </span>

              <download-software-license-agreement-button />
            </template>
          </v-checkbox>

          <stripe-pay-button
            class="mt-4"
            :cart="cart"
            companyName="UVMGen"
            :disabled="renewalAlreadyProcessed || !softwareLicenseAgreementAccepted"
          />
        </div>
      </uvmgen-stepper-content>
      <div class="scrollability-spacer" style="height: 400px;">
      </div>
    </uvmgen-stepper>
  </standard-layout>
</template>

<script>
import StandardLayout from "@/components/standard-layout.vue";
import UvmgenStepper from "@/components/uvmgen-stepper.vue";
import UvmgenStepperStep from "@/components/uvmgen-stepper-step.vue";
import UvmgenStepperContent from "@/components/uvmgen-stepper-content.vue";
import StepperMixin from "@/utils/stepper-mixin";
import SelectInfinite from "@/components/select-infinite.vue";
import { StripeCards, StripePayButton } from "vue-app-components";
import { sendFeedback } from "vue-app-utils-web";
import OrderReview from "@/components/order-review.vue";
import ExtraStripeElements from "@/components/extra-stripe-elements.vue";
import DownloadQuoteButton from "@/components/download-quote-button.vue";
import DownloadSoftwareLicenseAgreementButton from "@/components/download-software-license-agreement-button.vue";
import createUpgradeLicenseBundle from "@/utils/create-upgrade-license-bundle";
import featureTitles from "@/utils/feature-titles";
import get from "lodash.get";

export default {
  components: {
    StandardLayout,
    UvmgenStepper,
    UvmgenStepperStep,
    UvmgenStepperContent,
    SelectInfinite,
    StripeCards,
    StripePayButton,
    OrderReview,
    ExtraStripeElements,
    DownloadQuoteButton,
    DownloadSoftwareLicenseAgreementButton
  },
  mixins: [
    StepperMixin
  ],
  inject: ["$stripeManager"],
  data () {
    return {
      tempTbQuantityOverride: null,
      companyNameErrorMessages: [],
      companySizeErrorMessages: [],
      teamDvSizeErrorMessages: [],
      licensesLoaded: false,
      softwareLicenseAgreementAccepted: false
    };
  },
  watch: {
    "cart.uvcQuantity": {
      handler: function () {
        this.fixUvcDevTbQuantity();
      }
    },
    "cart.uvcPipelinedQuantity": {
      handler: function () {
        this.fixUvcDevTbQuantity();
      }
    }
  },
  created () {
    this.stepCompanyInfo = 1;
    this.stepCart = 2;
    this.stepPayment = 3;
    this.stepOrder = 4;

    this.titles = featureTitles;
    this.quantityDescriptions = {
      tb: "Total number of Testbenches you can create. Includes top-level environment instance.",
      env: "Total number of Environments you can create.",
      uvc: "Total number of UVCs you can create. A single UVC includes a master, slave and monitor.",
      uvcPipelined: "Total number of AXI-style UVCs with out of order responses you can create. A single UVC of this type can do reads or writes, not both. To support both access types you would need 2 out of order response UVCs",
      uvcDevTb: "Total number of UVC development testbenches you can use to test out your UVC before the DUT is ready.",
      envInstance: "Total number of Sub-environment instances that can be instantiated across all your environments.",
      uvcInstance: "Total number of UVC instances that can be instantiated across all your environments."
    };
    this.packSizes = {
      tb: 5,
      env: 5,
      uvc: 5,
      uvcPipelined: 2,
      uvcDevTb: 1,
      envInstance: 1,
      uvcInstance: 25
    };

    this.user.paymentMethods; // eslint-disable-line no-unused-expressions
    this.user.licenses; // eslint-disable-line no-unused-expressions
    this.user.promiseLicenses.then(() => {
      this.licensesLoaded = true;
    });
  },
  computed: {
    activeLicenses () {
      return this.user.licenses.filter(license => license.active);
    },
    title () {
      if (this.cart.isUpgradeOrder) {
        return "Upgrade UVMGen license";
      } else if (this.cart.isRenewalOrder) {
        return "Renew UVMGen license";
      } else {
        return "Create UVMGen license";
      }
    },
    loaded () {
      if (this.user) {
        this.user.privateProfile; // eslint-disable-line no-unused-expressions
        this.user.priceSheet; // eslint-disable-line no-unused-expressions
        this.cart; // eslint-disable-line no-unused-expressions
        this.user.licenses; // eslint-disable-line no-unused-expressions
      }
      return !!(this.user && this.user.privateProfile && this.user.priceSheet && this.cart && this.cart.result && this.cart.readonlyCart && this.licensesLoaded);
    },
    checkout () {
      return this;
    },
    licenseDurationOptions () {
      const licenseDurations = [...get(this.user, "priceSheet.licenseDurations", [])];
      if (this.cart.isUpgradeOrder) {
        licenseDurations.push(this.cart.result.proratedDurationInMonths);
        licenseDurations.sort();
      }
      return licenseDurations.map(licenseDuration => {
        return { title: `${licenseDuration} months`, value: licenseDuration };
      });
    },
    tempTbQuantity () {
      if (this.tempTbQuantityOverride !== null) {
        return this.tempTbQuantityOverride;
      } else {
        return this.defaultTempTbQuantity;
      }
    },
    defaultTempTbQuantity () {
      const packSize = this.packSizes.tb;
      return this.user.privateProfile.teamDvSize ? (Math.round((this.user.privateProfile.teamDvSize * 5) / packSize) * packSize) : null;
    },
    user () {
      return this.$store.getters.user;
    },
    cart () {
      return this.$stripeManager.cart;
    },
    stepCompanyInfoComplete () {
      return (
        (!!this.user.privateProfile.companyName && (this.companyNameErrorMessages.length === 0)) &&
        !!this.user.privateProfile.teamDvSize &&
        !!this.cart.readonlyCart.stripePaymentIntentClientSecret // Makes sure server has finished updating cart before user does
      );
    },
    stepCartComplete () {
      return this.user.priceSheet.features.every(feature => {
        return (typeof this.cart[feature.id + "Quantity"]) === "number";
      }) && ((typeof this.cart.result.priceWithGlobalAndCustomDiscount) === "number") && (this.cart.result.priceWithGlobalAndCustomDiscount >= 0.5) &&
        ((this.cart.orderType !== "upgrade") || !this.activeLicenses.find(license => (license.id === this.cart.licenseId) && license.termPaymentProcessing));
    },
    stepPaymentComplete () {
      return !!this.$stripeManager.defaultPaymentMethod;
    },
    stepOrderComplete () {
      return false;
    },
    renewalAlreadyProcessed () {
      return this.cart.isRenewalOrder && (this.cart.result.autoRenewTimestamp.toDate().getTime() > Date.now());
    },
    subTotal () {
      return this.cart.isRenewalOrder
        ? this.cart.result.priceWithRenewalDiscount
        : this.cart.result.priceWithGlobalAndCustomDiscount;
    }
  },
  methods: {
    nextStep (step) {
      let nextStep = step + 1;

      if (nextStep > this.stepOrder) {
        nextStep = 1;
      }
      this.$router.updateQueryParams({ step: nextStep });
    },
    async joinWaitlist () {
      await this.user.privateProfile.immediateUpdate({ waitlistedTime: Date.now() });
      sendFeedback(`${this.user.privateProfile.paymentEmail || this.user.privateProfile.email} joined the waitlist`, "EOM\n", this.$store, false);
    },
    onInputOfCompanyName (value) {
      if (value === "") {
        this.companyNameErrorMessages = ["Company name can not be blank."];
      } else {
        this.companyNameErrorMessages = [];
        this.user.privateProfile.update({
          companyName: value
        });
      }
    },
    onInputOfCompanySize (value) {
      const companySize = parseInt(value);
      if (value === "") {
        this.companySizeErrorMessages = ["Company size can not be blank."];
      } else if (isNaN(companySize)) {
        this.companySizeErrorMessages = [`"${value}" is not a number.`];
      } else if (companySize < 1) {
        this.companySizeErrorMessages = ["Company size must be a positive number."];
      } else {
        this.companySizeErrorMessages = [];
        this.user.privateProfile.update({
          companySize
        });
      }
    },
    onInputOfTeamDvSize (value) {
      const teamDvSize = parseInt(value);
      if (value === "") {
        this.teamDvSizeErrorMessages = ["Team size can not be blank."];
      } else if (isNaN(teamDvSize)) {
        this.teamDvSizeErrorMessages = [`"${value}" is not a number.`];
      } else if (teamDvSize < 1) {
        this.teamDvSizeErrorMessages = ["Team size must be a positive number."];
      } else {
        this.teamDvSizeErrorMessages = [];
        this.user.privateProfile.update({
          teamDvSize
        });
      }
    },
    createNewLicenseBundle (tbQuantity) {
      const data = {
        newLicenseBundleCreated: true,
        licenseDurationInMonths: this.cart.licenseDurationInMonths
      };
      this.user.priceSheet.features.forEach(feature => {
        data[feature.id + "Quantity"] = this[feature.id + "SuggestedQuantity"](tbQuantity);
      });
      this.$stripeManager.waitForPaymentIntentUpdate();
      return this.cart.immediateUpdate(data);
    },
    onQuantityUpdate (featureId, value, immediateUpdate = false) {
      if (value.length > 0) {
        this[featureId + "ErrorMessages"] = [];

        const data = {};
        data[featureId + "Quantity"] = parseInt(value);

        this.$stripeManager.waitForPaymentIntentUpdate();
        if (immediateUpdate) {
          this.cart.immediateUpdate(data);
        } else {
          this.cart.update(data);
        }
      } else {
        this[featureId + "ErrorMessages"] = ["Quantity can not be blank."];
      }
    },
    onLicenseDurationUpdate (licenseDurationInMonths) {
      this.$stripeManager.waitForPaymentIntentUpdate();
      this.cart.immediateUpdate({ licenseDurationInMonths });
    },
    tbSuggestedQuantity (tbQuantity) {
      const packSize = this.packSizes.tb;
      return Math.ceil(tbQuantity / packSize) * packSize;
    },
    envSuggestedQuantity (tbQuantity) {
      const numTbs = this.tbSuggestedQuantity(tbQuantity);
      let res;
      if (numTbs > 0) {
        res = Math.ceil(numTbs * 1.2);
      } else {
        res = 0;
      }
      const packSize = this.packSizes.env;
      return Math.round(res / packSize) * packSize;
    },
    uvcSuggestedQuantity (tbQuantity) {
      const numEnvs = this.envSuggestedQuantity(tbQuantity || this.defaultTempTbQuantity);
      const x = numEnvs * 0.34;
      const base = 1.1;
      let res = ((Math.log(x + 9.5) / Math.log(base)) * 9.5) - 220;
      res = (Math.ceil(res / numEnvs) * numEnvs) - 10;
      const packSize = this.packSizes.uvc;
      return Math.round(res / packSize) * packSize;
    },
    uvcPipelinedSuggestedQuantity (tbQuantity) {
      let res;
      const numEnvs = this.envSuggestedQuantity(tbQuantity || this.defaultTempTbQuantity);
      if (numEnvs >= 50) {
        res = 4;
      } else if (numEnvs > 5) {
        res = 2;
      } else {
        res = 0;
      }
      const packSize = this.packSizes.uvcPipelined;
      return Math.ceil(res / packSize) * packSize;
    },
    uvcDevTbSuggestedQuantity (tbQuantity) {
      const numUvcs = this.uvcSuggestedQuantity(tbQuantity);
      const numUvcPipelined = this.uvcPipelinedSuggestedQuantity(tbQuantity);

      const res = (Math.ceil(numUvcs / 2)) + numUvcPipelined;
      const packSize = this.packSizes.uvcDevTb;
      return Math.ceil(res / packSize) * packSize;
    },
    envInstanceSuggestedQuantity (tbQuantity) {
      const numEnvs = this.envSuggestedQuantity(tbQuantity);
      let res;
      if (numEnvs > 0) {
        res = numEnvs / 3.5;
      } else {
        res = 0;
      }
      const packSize = this.packSizes.envInstance;
      return Math.round(res / packSize) * packSize;
    },
    uvcInstanceSuggestedQuantity (tbQuantity) {
      const numEnvs = this.envSuggestedQuantity(tbQuantity);
      let res;
      if (numEnvs > 0) {
        res = numEnvs * 5.5;
      } else {
        res = 0;
      }
      const packSize = this.packSizes.uvcInstance;
      return Math.round(res / packSize) * packSize;
    },
    fixUvcDevTbQuantity () {
      const sum = this.cart.uvcQuantity + this.cart.uvcPipelinedQuantity;
      if (sum < this.cart.uvcDevTbQuantity) {
        this.cart.immediateUpdate({ uvcDevTbQuantity: sum });
      }
    },
    onUpgradeExistingRadioSelect (orderType) {
      const data = { orderType };
      if ((orderType === "upgrade") && this.cart.newLicenseBundleCreated && this.cart.upgradeLicenseBundleCreated) {
        this.user.priceSheet.features.forEach(feature => {
          data[feature.id + "Quantity"] = 0;
        });
        data.newLicenseBundleCreated = false;
      }
      this.$stripeManager.waitForPaymentIntentUpdate();
      this.cart.immediateUpdate(data);
    },
    async onSelectUpgradeLicense (licenseId) {
      if (!this.cart.upgradeLicenseBundleCreated) {
        this.$stripeManager.waitForPaymentIntentUpdate();
        await createUpgradeLicenseBundle(this.user.priceSheet, this.user.cart, licenseId);
      } else {
        this.$stripeManager.waitForPaymentIntentUpdate();
        this.cart.immediateUpdate({ licenseId });
      }
    }
  }
};
</script>

<style lang="css" scoped>

.license-duration__v-select {
  width: 250px;
  max-width: 250px;
  margin-right: 0;
}

.add-card-button {
  background-color: white;
}

.subtotal {
  width: 225px;
}

</style>
