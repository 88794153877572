<template>
  <code-area
    class="constraints-code-area pb-4"
    :disabled="disabled"
    label="Coverpoint"
    :placeholder="instanceVariable.defaultCoverpoint"
    :num-rows="0"
    :value="instanceVariable.coverpoint"
    hint="Add coverpoint."
    @input="instanceVariable.update({ coverpointOverride: $event })"
  />
</template>

<script>
import CodeArea from "@/components/code-area.vue";

export default {
  components: {
    CodeArea
  },
  props: {
    instanceVariable: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    };
  },
  computed: {
    disabled () {
      return this.instanceVariable.isProtected || this.instanceVariable.computed || (this.instanceVariable.parent.rootProject.hasUvcCoverageOverride === false);
    }
  }
};
</script>

<style lang="css" scoped>

.coverpoint-code-area {
  margin-top: 0 !important;
}

</style>
