<template>
  <standard-layout
    hasContainer
    dark
  >
    <project-list-for-uvcs :uvcs="uvcs">
    </project-list-for-uvcs>
  </standard-layout>
</template>

<script>
import StandardLayout from "@/components/standard-layout.vue";
import ProjectListForUvcs from "@/components/project-list-for-uvcs.vue";

export default {
  name: "uvcs-index",
  components: {
    StandardLayout,
    ProjectListForUvcs
  },
  data () {
    return {};
  },
  computed: {
    uvcs () {
      const user = this.$store.getters.user;
      if (user) {
        return user.uvcs;
      } else {
        return [];
      }
    }
  }
};
</script>

<style lang="css" scoped>
</style>
