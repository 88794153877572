<template>
  <v-text-field
    class="license-key__text-field"
    spellcheck="false"
    autocapitalize="off"
    variant="solo"
    theme="dark"
    hide-details
    readonly
    :model-value="licenseKey"
  >
    <template v-slot:append-inner>
      <v-btn
        variant="flat"
        icon="mdi-content-copy"
        @click="onCopy"
      >
      </v-btn>
    </template>
  </v-text-field>
</template>
<script>

export default {
  props: {
    licenseKey: {
      type: String,
      required: true
    }
  },
  methods: {
    onCopy () {
      navigator.clipboard.writeText(this.licenseKey);
      this.$store.commit("pushSnackbarMessageProps", {
        message: "License key copied."
      });
    }
  }
};

</script>

<style lang="css" scoped>

.license-key__text-field {
  max-width: 390px;
}

</style>
