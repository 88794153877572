import OnPageLoadDone from "@/utils/on-page-load-done";
import { isNavigationFailure, NavigationFailureType } from "vue-router";

export default {
  mixins: [
    OnPageLoadDone
  ],
  watch: {
    step (step) {
      if (step) {
        this.scrollToStep(step);
      }
    }
  },
  mounted () {
    this.scrollOnMounted();
  },
  computed: {
    step () {
      return this.$route.query.step;
    }
  },
  methods: {
    scrollOnMounted () {
      if (this.step === undefined) {
        this.updateStep(1);
      }
      this.scrollToStep(this.step);
    },
    scrollToStep (step) {
      this.onPageLoadDone().then(() => {
        const steps = this.$refs.stepper && this.$refs.stepper.$el.getElementsByClassName("v-stepper__step");
        const targetNode = steps && steps.item(parseInt(step) - 1);
        const parentNode = this.$refs.stepper && this.$refs.stepper.$el.closest(".scrollbox");
        if (targetNode && parentNode) {
          parentNode.scrollTop = targetNode.offsetTop - parentNode.offsetTop;
        } else {
          setTimeout(() => {
            this.scrollToStep(step);
          }, 250);
        }
      });
    },
    updateStep (step) {
      if (!step) {
        throw new Error("updateStep argument step=" + JSON.stringify(step));
      }
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { step })
      })
        .catch((e) => {
          if (!isNavigationFailure(e, NavigationFailureType.duplicated)) {
            throw Error(e);
          }
        });
    }
  }
};
