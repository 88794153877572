<template>
  <dialog-layout
    title="Verify bank account"
    :fullscreen="fullscreen"
    :navIcon="navIcon"
    :dark="dark"
    @pop-dialog="$emit('pop-dialog')"
  >
    <v-card
      v-if="user.privateProfile && user.privateProfile.readonlyProfile && user.privateProfile.microdepositType"
      elevation="0"
    >
      <v-card-text>
        <div
          v-if="user.privateProfile.usingMicrodepositDescriptorCode"
        >
          <div class="text-h6">
            Enter the 6-digit code from your bank statement
          </div>
          <div class="mt-4">
            You will see a $0.01 deposit in your account. To verify your account, enter the 6-digit code starting with "SM" from that deposit.
          </div>
          <v-card
            class="mt-8"
          >
            <v-card-title>
              <v-icon>mdi-bank</v-icon><span class="ml-4">BANK STATEMENT</span>
            </v-card-title>
            <v-card-text>
              <v-table
                class="mt-4"
                density="compact"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Transaction
                      </th>
                      <th class="text-left">
                        Amount
                      </th>
                      <th class="text-left">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span style="padding: 2px; border: 3px solid rgb(var(--v-theme-accent));">SMXXXX</span>-UVMGEN
                      </td>
                      <td>
                        $0.01
                      </td>
                      <td>
                        JUL 15
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Groceries
                      </td>
                      <td>
                        $1000.00
                      </td>
                      <td>
                        JUL 14
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-table>
            </v-card-text>
          </v-card>
          <div class="mt-9 d-flex justify-center">
            Enter code
          </div>
          <v-otp-input
            class="mt-3 mx-4"
            v-model="microdepositDescriptorCode"
            length="6"
          >
          </v-otp-input>
        </div>
        <div
          v-if="user.privateProfile.usingMicrodepositAmounts"
        >
          <div class="text-h6">
            Enter two deposits from your bank statement
          </div>
          <div class="mt-4">
            You will see two deposits in your account. To verify your account, enter the two amounts from those deposits.
          </div>
          <v-card
            class="mt-8"
          >
            <v-card-title>
              <v-icon>mdi-bank</v-icon><span class="ml-4">BANK STATEMENT</span>
            </v-card-title>
            <v-card-text>
              <v-table
                class="mt-4"
                density="compact"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Transaction
                      </th>
                      <th class="text-left">
                        Amount
                      </th>
                      <th class="text-left">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        SMXXXX-UVMGEN
                      </td>
                      <td>
                        $<span style="padding: 2px; border: 3px solid var(--accent-color);">0.34</span>
                      </td>
                      <td>
                        JUL 15
                      </td>
                    </tr>
                    <tr>
                      <td>
                        SMXXXX-UVMGEN
                      </td>
                      <td>
                        $<span style="padding: 2px; border: 3px solid var(--accent-color);">0.12</span>
                      </td>
                      <td>
                        JUL 15
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-table>
            </v-card-text>
          </v-card>
          <div class="mt-9 d-flex justify-center">
            Enter amounts
          </div>
          <div class="mt-3 d-flex justify-center">
            <v-otp-input
              style="max-width: 100px; margin-right: -8px; padding-right: 0;"
              disabled
              model-value="0."
              length="2"
            />
            <v-otp-input
              style="max-width: 100px; margin-left: -8px; padding-left: 0;"
              v-model="microdepositAmountOne"
              length="2"
            />
          </div>
          <div class="mt-3 d-flex justify-center">
            <v-otp-input
              style="max-width: 100px; margin-right: -8px; padding-right: 0;"
              disabled
              model-value="0."
              length="2"
            />
            <v-otp-input
              style="max-width: 100px; margin-left: -8px; padding-left: 0;"
              v-model="microdepositAmountTwo"
              length="2"
            />
          </div>
        </div>

        <div
          class="mt-4 text-body-1 error--text"
        >
          {{ errorMessage }}
        </div>

        <v-btn
          class="mt-8"
          color="primary"
          block
          :disabled="verifyButtonDisabled"
          @click="verifyMicrodeposits"
        >
          VERIFY
        </v-btn>
        <div class="mt-4 d-flex justify-center">
          <div v-if="user.privateProfile.usingMicrodepositDescriptorCode">
            Can't find your code? Check your account in 1-2 days.
          </div>
          <div v-if="user.privateProfile.usingMicrodepositAmounts">
            Can't find your deposits? Check your account in 1-2 days.
          </div>
        </div>
      </v-card-text>
    </v-card>
  </dialog-layout>
</template>

<script>
import { DialogLayout } from "vue-app-components";
import { VOtpInput } from "vuetify/labs/VOtpInput";

export default {
  name: "dialog-stripe-verify-microdeposits",
  components: {
    DialogLayout,
    VOtpInput
  },
  props: {
    fullscreen: {
      type: Boolean,
      required: true
    },
    navIcon: {
      type: String,
      required: true
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      microdepositDescriptorCode: "SM",
      microdepositAmountOne: "",
      microdepositAmountTwo: "",
      errorMessage: ""
    };
  },
  computed: {
    user () {
      return this.$store.getters.user;
    },
    verifyButtonDisabled () {
      return (
        (this.user.privateProfile.usingMicrodepositDescriptorCode && this.microdepositDescriptorCode.length !== 6) ||
        (this.user.privateProfile.usingMicrodepositAmounts && (isNaN(parseInt(this.microdepositAmountOne[0])) || isNaN(parseInt(this.microdepositAmountTwo[0])) || isNaN(parseInt(this.microdepositAmountOne[1])) || isNaN(parseInt(this.microdepositAmountTwo[1]))))
      );
    }
  },
  inject: ["$dialog", "$stripeManager"],
  methods: {
    async verifyMicrodeposits () {
      const data = {};
      if (this.user.privateProfile.usingMicrodepositDescriptorCode) {
        data.descriptor_code = this.microdepositDescriptorCode;
      } else if (this.user.privateProfile.usingMicrodepositAmounts) {
        data.amounts = [parseInt(this.microdepositAmountOne), parseInt(this.microdepositAmountTwo)];
      } else {
        throw new Error("Unexpected microdeposit type.");
      }
      const result = await this.$stripeManager.stripe.verifyMicrodepositsForSetup(this.user.privateProfile.readonlyProfile.stripeSetupIntentClientSecret, data);
      if (result.error) {
        this.errorMessage = result.error.message;
      } else {
        this.$stripeManager.onSetupIntentConfirmed(result.setupIntent);
        this.$dialog.pop();
      }
    }
  }
};
</script>

<style lang="css" scoped>
</style>
