<template>
  <data-input-table
    class="mt-3"
    data-test="data-input-table-for-parameters"
    :subject="parent"
    :getCollection="parent.allParameters"
    setCollectionProperty="parameters"
    collectionName="parameters"
    :columns="columns"
    :parse-new-items="parseParameters"
    :new-items-placeholder="newItemsPlaceholder"
    new-items-hint="Input your parameters here."
    dense
    :can-add-items="canAddParameters"
    :show-expand="hasExpand"
  >
    <template v-slot:expand="{ item }">
      <v-checkbox
        v-if="parent.isInterface"
        class="mt-4"
        label="Localparam"
        :disabled="item.computed"
        :model-value="item.isLocalparam"
        @update:modelValue="makeLocalparam($event, item)"
      />
    </template>
  </data-input-table>
</template>

<script>
import DataInputTable from "@/components/data-input-table.vue";
import parseSystemVerilog from "@/utils/parse-system-verilog";

export default {
  components: {
    DataInputTable
  },
  props: {
    parent: {
      type: Object,
      required: true
    },
    newItemsPlaceholder: {
      type: String,
      default: "PARAM_NAME0 = 0, PARAM_NAME1 = 1"
    },
    canAddParameters: {
      type: Boolean,
      default: true
    },
    hasExpand: {
      type: Boolean,
      default: true
    }
  },
  created () {
  },
  computed: {
    columns () {
      const columns = [
        {
          title: "Type",
          key: "type",
          sortable: false,
          width: "35%",
          parser: this.parseType
        },
        {
          title: "Name",
          key: "name",
          sortable: false,
          width: "45%",
          parser: this.parseName
        },
        {
          title: this.parent.isInterface ? "Default" : "Value",
          key: "defaultValue",
          setProperty: "defaultValueOverride",
          sortable: false,
          width: "20%"
        },
        {
          title: "",
          key: "data-table-expand",
          sortable: false,
          width: "56px"
        }
      ];
      return this.hasExpand ? columns : columns.slice(0, -1);
    }
  },
  methods: {
    makeLocalparam (value, item) {
      const info = this.parseType(item.type);
      let type = value ? "localparam" : "parameter";
      if (info.rootType) {
        type = type + " " + info.rootType;
      }
      item.update({ type });
    },
    parseParameters (value) {
      const infoList = parseSystemVerilog(value, "ListOfParameterPortDeclarations");

      infoList.forEach(info => {
        if (info.data.defaultValue) {
          info.data.defaultValueOverride = info.data.defaultValue;
          delete info.data.defaultValue;
        }
      });

      return infoList;
    },
    parseType (value) {
      return parseSystemVerilog(value, "ParameterType");
    },
    parseName (value) {
      return parseSystemVerilog(value, "ParameterIdentifier");
    }
  }
};

</script>

<style lang="css" scoped>
</style>
