export default function (priceSheet, cart, licenseId) {
  const data = {
    orderType: "upgrade",
    upgradeLicenseBundleCreated: true,
    licenseId
  };
  priceSheet.features.forEach(feature => {
    data[feature.id + "Quantity"] = 0;
  });
  return cart.immediateUpdate(data);
}
