<template>
  <v-app-bar
    id="top-nav"
    :class="{ 'top-nav--dark': dark, 'top-nav--light': !dark }"
    app
    :color="dark ? 'primary' : undefined"
    extension-height="74"
  >

    <v-app-bar-nav-icon
      @click.stop="$store.commit('toggleSideNavOpen')"
    ></v-app-bar-nav-icon>

    <v-toolbar-title>{{ title }}</v-toolbar-title>

    <template v-slot:append>
      <top-nav-custom />
    </template>

    <template v-if="$slots.extension" v-slot:extension>
      <slot name="extension"></slot>
    </template>

  </v-app-bar>
</template>

<script>
import TopNavCustom from "@/components/top-nav-VITE_APP_PLATFORM.vue";

export default {
  components: {
    TopNavCustom
  },
  props: {
    title: {
      type: String
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    };
  }
};

</script>

<style lang="css" scoped>

#top-nav {
  padding-left: 2px;
  padding-right: 6px;
}

</style>
