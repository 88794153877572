<template>
  <dialog-layout
    :title="$store.getters.onPremiseDialogTitle"
    :fullscreen="fullscreen"
    :navIcon="navIcon"
    :dark="dark"
    @pop-dialog="$emit('pop-dialog')"
  >
    <v-card
      elevation="0"
    >
      <v-card-text>
        <div>
          {{ $store.getters.onPremiseDialogParagraph }}
        </div>
        <div class="mt-4">
          The UVMGen on-premise license allows you to download a UVMGen desktop application for Linux that can generate UVCs, environments and testbenches. The desktop app saves your SystemVerilog files to your local file system. A local JSON file acts as UVMGen's database and can be checked into your version control system. All of your company's proprietary information remains safely behind your firewalls alongside the rest of your IP.
        </div>
        <div class="d-flex justify-center">
          <v-btn
            color="primary"
            class="text-h6 mt-8 mb-4 elevation-9"
            height="75px"
            width="300px"
            @click="onClick"
          >
            GET THE DESKTOP APP
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </dialog-layout>
</template>

<script>
import { DialogLayout } from "vue-app-components";

export default {
  name: "dialog-on-premise-license",
  components: {
    DialogLayout
  },
  props: {
    fullscreen: {
      type: Boolean,
      required: true
    },
    navIcon: {
      type: String,
      required: true
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {};
  },
  computed: {
    user () {
      return this.$store.getters.user;
    }
  },
  methods: {
    onClick () {
      this.$router.push({ name: "Checkout" })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    }
  }
};
</script>

<style lang="css" scoped>
</style>
