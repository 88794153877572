<template>
  <div class="mb-5">
    <div class="text-h5 project-list__title">
      {{ title }}
    </div>

    <v-container grid-list-lg pa-0>
      <v-row row wrap>
        <slot></slot>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
};

</script>

<style lang="css" scoped>

.project-list__title {
  margin-left: 4px;
  padding-top: 12px;
  padding-bottom: 30px;
}

</style>
