<template>
  <v-list
    class="v-stepper__step"
    bg-color="transparent"
    two-line
  >
    <v-list-item
      title=""
      :disabled="!editable"
      @click="updateStep"
    >
      <template v-slot:prepend>
        <v-icon
          :icon="complete ? 'mdi-check-circle' : `mdi-numeric-${step}-circle`"
          :color="complete ? 'primary' : undefined"
          size="25"
        />
      </template>

      <v-list-item-title>
        <div class="font-weight-normal">
          <slot></slot>
        </div>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ subtitle }}
      </v-list-item-subtitle>
    </v-list-item>
  </v-list>
</template>

<script>

export default {
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    /*
    editIcon: {
      type: String,
      default: "mdi-pencil"
    },
    */
    complete: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      required: true
    },
    subtitle: {
      type: String,
      default: ""
    }
  },
  methods: {
    updateStep () {
      this.findParent(this.$parent).$emit("change", this.step);
    },
    findParent (parent) {
      if (!parent) {
        throw new Error("Could not find parent.");
      } else if (parent.isUvmGenStepper) {
        return parent;
      } else {
        return this.findParent(parent.$parent);
      }
    }
  }
};

</script>

<style scoped>

:deep(.v-list-item__prepend > .v-icon) {
  margin-inline-end: 17px;
}

</style>
