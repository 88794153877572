import Prism from "prismjs/components/prism-core";
import "prismjs/components/prism-verilog";

const StringConverter = {
  toHtml: function (code) {
    return Prism.highlight(code, Prism.languages.verilog);
  }
};

export default StringConverter;
