<template>
  <standard-layout
    title="UVMGen licensing"
    has-container
  >
    <div
      v-if="license && license.cart && license.cart.result && (license.payments.length > 0) && user.priceSheet"
      class="mt-4 mt-md-16 pb-16"
    >
      <div class="text-h5">
        {{ license.durationInMonths }}-month license
      </div>

      <v-row class="mt-10">
        <v-col class="mb-10" cols="12" md="5">
          <div
            style="max-width: 350px"
          >
            <div class="step-subheading">
              Authorized features
            </div>
            <v-table
              class="mt-6"
              density="compact"
            >
              <template v-slot:default>
                <thead v-if="false">
                  <tr>
                    <th>
                      Feature
                    </th>
                    <th>
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="feature in user.priceSheet.features"
                    :key="feature.id"
                  >
                    <td>{{ titles[feature.id] }}</td>
                    <td>{{ license.activeParameters[feature.id] || 0 }}</td>
                  </tr>
                </tbody>
              </template>
            </v-table>
            <div class="d-flex justify-start">
              <v-btn
                v-if="license.active && user.cart && !license.cart.result.needsAttention && !license.termPaymentProcessing"
                class="mt-8"
                color="primary"
                @click="upgradeLicense()"
              >
                UPGRADE LICENSE
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col class="mb-6" cols="12" md="7">
          <div class="step-subheading">
            Orders
          </div>
          <v-table
            class="mt-6"
            style="max-width: 500px"
            density="compact"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    Type
                  </th>
                  <th>
                    Term
                  </th>
                  <th>
                    Status
                  </th>
                  <th>
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="payment in sortedPayments"
                  :key="payment.id"
                >
                  <td class="order-table__col">{{ payment.type }}</td>
                  <td class="order-table__col">{{ formatDate(payment.licenseTermStartDate, { short: true }) }} - {{ formatDate(payment.licenseTermEndDate, { short: true }) }}</td>
                  <td class="order-table__col">{{ payment.status }}</td>
                  <td class="order-table__col">
                    <v-btn
                      style="margin-left: -16px;"
                      color="primary"
                      variant="text"
                      @click="goToPayment(payment)"
                    >
                      VIEW
                    </v-btn>
                  </td>
                </tr>
                <tr v-if="license.cart.result.needsAttention">
                  <td>Renewal</td>
                  <td>{{ formatDate(license.cart.result.licenseTermStartDate, { short: true }) }} - {{ formatDate(license.cart.result.licenseTermEndDate, { short: true }) }}</td>
                  <td class="error--text">Error</td>
                  <td>
                    <v-badge
                      style="max-width: calc(100% - 40px);"
                      :model-value="license.needsAttention"
                      color="error"
                      dot
                      offset-x="-22"
                      offset-y="14"
                    >
                      <v-btn
                        style="margin-left: -16px;"
                        color="primary"
                        variant="text"
                        @click="confirmRenewal()"
                      >
                        VIEW
                      </v-btn>
                    </v-badge>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-table>
          <v-checkbox
            v-if="license.active && !license.cart.result.needsAttention && !license.termPaymentProcessing"
            class="mt-10"
            style="margin-left: 8px;"
            :label="license.autoRenewEnabled ? `Auto renew (${$stripeManager.formatPrice(license.cart.result.priceWithTax)} on ${license.cart.result.autoRenewTimestamp && formatDate(license.cart.result.autoRenewTimestamp.toDate(), { short: true })} using **${$stripeManager.defaultPaymentMethod ? $stripeManager.defaultPaymentMethod.last4 : '????'})` : 'Auto renew disabled'"
            :error="!$stripeManager.defaultPaymentMethod || (user.privateProfile && !!user.privateProfile.setupIntentErrorMessage)"
            :model-value="license.autoRenewEnabled"
            @update:modelValue="(value) => license.immediateUpdate({ autoRenewEnabled: value })"
          />
        </v-col>
      </v-row>

      <div v-if="license.active">
        <div class="mt-10 step-subheading">On-premise installation</div>

        <on-premise-installation-stepper
          v-if="user.privateProfile && user.privateProfile.readonlyProfile && user.privateProfile.readonlyProfile.hasActiveLicense"
          class="mt-4 mx-0"
          :license="license"
        />
      </div>
    </div>
    <div
      v-else-if="!license && loadTimeExpired"
      class="mt-4 mt-md-16 pb-16"
    >
      <div class="text-h5">
        License not found
      </div>
    </div>
    <v-container
      v-else
      class="center-vh"
    >
      <div class="d-flex fill-height align-center justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
          size="48"
        />
      </div>
    </v-container>
  </standard-layout>
</template>

<script>
import StandardLayout from "@/components/standard-layout.vue";
import OnPremiseInstallationStepper from "@/components/on-premise-installation-stepper.vue";
import createUpgradeLicenseBundle from "@/utils/create-upgrade-license-bundle";
import featureTitles from "@/utils/feature-titles";
import { formatDate } from "vue-app-utils";

export default {
  components: {
    StandardLayout,
    OnPremiseInstallationStepper
  },
  inject: ["$stripeManager"],
  data () {
    return {
      loadTimeExpired: false
    };
  },
  created () {
    this.formatDate = formatDate;
    this.titles = featureTitles;
  },
  mounted () {
    setTimeout(() => {
      this.loadTimeExpired = true;
    }, 3000);
  },
  computed: {
    user () {
      return this.$store.getters.user;
    },
    license () {
      return this.user.licenses.find(license => license.id === this.$route.params.id);
    },
    sortedPayments () {
      return [...this.license.payments].sort((a, b) => {
        if (a.licenseTermStartDate < b.licenseTermStartDate) {
          return -1;
        } else if (a.licenseTermStartDate > b.licenseTermStartDate) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  },
  methods: {
    async upgradeLicense () {
      await createUpgradeLicenseBundle(this.user.priceSheet, this.user.cart, this.license.id);
      this.$router.push({ name: "cartsEdit", params: { id: this.user.cart.id }, query: { step: 2 } })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    },
    goToPayment (payment) {
      this.$router.push({ name: "ordersShow", params: { id: payment.id } })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    },
    async confirmRenewal () {
      this.$router.push({ name: "cartsEdit", params: { id: this.license.cart.id }, query: { step: 4 } })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    }
  }
};
</script>

<style lang="css" scoped>

.order-table__col {
  padding: 0 4px 0 16px !important;
}

</style>
