<template>
  <div class="collection-editor">
    <div
      class="mt-2 d-flex"
    >
      <v-menu
        v-model="openSelectMenu"
        max-width="calc(100% - 74px)"
        max-height="520px"
      >
        <template v-slot:activator="{ props }">
          <div style="width: 100%;">
            <v-btn
              data-test="collection-editor__select-btn"
              class="select-button"
              :class="{ 'select-button--with-fab': addButtonIsFab }"
              v-bind="props"
              color="primary"
              variant="outlined"
              block
              :disabled="allItems.length === 0"
            >
              <div
                class="d-flex d-sm-none"
              >
                SELECT
              </div>
              <div
                class="d-none d-sm-flex"
              >
                SELECT {{ itemType }}
              </div>

              <template v-slot:append>
                <v-badge
                  :model-value="needsAttention"
                  color="error"
                  dot
                  location="top end"
                  offset-x="-6"
                  offset-y="-2"
                >
                  <v-icon
                    right
                  >
                    mdi-menu-down
                  </v-icon>
                </v-badge>
              </template>
            </v-btn>
          </div>
        </template>
        <v-list lines="two">
          <div v-if="isNeutral">
            <v-list-subheader>{{ pluralItemTypeTitlized }}</v-list-subheader>
            <v-list-item
              v-if="neutralItems.length === 0"
              title=""
              disabled
              prepend-icon="mdi-sflkjsj"
            >
              <v-list-item-title>No custom {{ pluralItemType }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-for="klass in neutralItems"
              :key="klass.uniqueId"
              title=""
              :two-line="!!$slots['list-item-subtitle']"
              :active="selectedItem && (selectedItem.uniqueId === klass.uniqueId)"
              @click="setSelectedItemUniqueId(klass.uniqueId)"
            >
              <template v-slot:prepend>
                <v-badge
                  :model-value="klass.needsAttention || false"
                  color="error"
                  dot
                  location="top end"
                  offset-x="30"
                >
                  <v-icon>{{ listItemIcon }}</v-icon>
                </v-badge>
              </template>
              <v-list-item-title>{{ klass[itemNameProperty] }}</v-list-item-title>
              <slot name="list-item-subtitle" :klass="klass">
              </slot>
            </v-list-item>
          </div>

          <div
            v-else
          >
            <v-list-subheader v-if="hasMaster">Master {{ pluralItemType }}</v-list-subheader>
            <v-list-item
              v-if="hasMaster && masterItems.length === 0"
              title=""
              disabled
              prepend-icon="mdi-sflkjsj"
            >
              <v-list-item-title>No custom master {{ pluralItemType }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-for="klass in masterItems"
              :key="klass.uniqueId"
              title=""
              prepend-icon="mdi-alpha-m-box"
              :active="selectedItem && (selectedItem.uniqueId === klass.uniqueId)"
              @click="setSelectedItemUniqueId(klass.uniqueId)"
            >
              <v-list-item-title>{{ klass[itemNameProperty] }}</v-list-item-title>
              <v-list-item-subtitle v-if="klass.superclass">
                {{ klass.isPolicy ? "Constrains" : "Extends" }} {{ klass.isPolicy ? (klass.subject && klass.subject.type) : klass.superclass.type }}
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-subheader v-if="hasSlave">Slave {{ pluralItemType }}</v-list-subheader>
            <v-list-item
              v-if="hasSlave && slaveItems.length === 0"
              title=""
              disabled
              prepend-icon="mdi-sflkjsj"
            >
              <v-list-item-title>No custom slave {{ pluralItemType }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="klass in slaveItems"
              :key="klass.uniqueId"
              title=""
              prepend-icon="mdi-alpha-s-box"
              :active="selectedItem && (selectedItem.uniqueId === klass.uniqueId)"
              @click="setSelectedItemUniqueId(klass.uniqueId)"
            >
              <v-list-item-title>{{ klass[itemNameProperty] }}</v-list-item-title>
              <v-list-item-subtitle v-if="klass.superclass">
                {{ klass.isPolicy ? "Constrains" : "Extends" }} {{ klass.isPolicy ? (klass.subject && klass.subject.type) : klass.superclass.type }}
              </v-list-item-subtitle>
            </v-list-item>
          </div>
        </v-list>
      </v-menu>
      <v-btn
        class="ml-4 add-button"
        :class="{ 'add-button--with-item-selected': createItemToggleOn }"
        color="primary"
        :size="addButtonIsFab ? 'large' : 'small'"
        icon="mdi-plus"
        :disabled="addButtonIsDisabled"
        variant="outlined"
        @click="showCreateNewItemCard"
      />
    </div>

    <div v-if="createItemToggleOn">
      <slot
        name="create-card"
        :selectedItem="selectedItem"
        :onSave="onSave"
        :onCancel="onCancel"
      >
      </slot>
    </div>
    <div
      v-else-if="selectedItem"
      class="mt-5"
    >
      <slot
        name="item-editor"
        :selectedItem="selectedItem"
      >
      </slot>
    </div>
  </div>
</template>

<script>
import pluralize from "pluralize-esm";

export default {
  props: {
    itemType: {
      type: String,
      required: true
    },
    itemNameProperty: {
      type: String,
      default: "name"
    },
    listItemIcon: {
      type: String,
      default: ""
    },
    addButtonIsDisabled: {
      type: Boolean,
      default: false
    },
    addButtonIsFabCondition: {
      type: Boolean,
      default: true
    },
    isNeutral: {
      type: Boolean,
      default: true
    },
    neutralItems: {
      type: Array,
      default: function () {
        return [];
      }
    },
    hasMaster: {
      type: Boolean,
      default: false
    },
    hasSlave: {
      type: Boolean,
      default: false
    },
    masterItems: {
      type: Array,
      default: function () {
        return [];
      }
    },
    slaveItems: {
      type: Array,
      default: function () {
        return [];
      }
    },
    newClassProperties: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      createItemToggleOn: false,
      selectedItemUniqueId: "",
      openSelectMenu: false,
      existingItemUniqueIds: [],
      pluralItemType: "",
      pluralItemTypeTitlized: ""
    };
  },
  created () {
    this.pluralItemType = this.itemType === "bfm" ? "BFMs" : pluralize(this.itemType);
    this.pluralItemTypeTitlized = this.pluralItemType[0].toUpperCase() + this.pluralItemType.slice(1);
  },
  watch: {
    newClassProperties (properties) {
      if (properties) {
        this.showCreateNewItemCard();
      }
    },
    selectedItem (newItem, oldItem) {
      if (newItem && (newItem !== oldItem)) {
        this.$emit("activate", newItem);
      }
    }
  },
  computed: {
    needsAttention () {
      return this.allItems.some(klass => klass.needsAttention);
    },
    addButtonIsFab () {
      return (this.allItems.length === 0) && this.addButtonIsFabCondition;
    },
    allItems () {
      if (this.isNeutral) {
        return this.neutralItems;
      } else {
        return this.masterItems.concat(this.slaveItems);
      }
    },
    selectedItem () {
      return this.allItems.find(item => item.uniqueId === this.selectedItemUniqueId);
    }
  },
  methods: {
    onSave (id) {
      this.createItemToggleOn = false;
      const findItem = (tries = 0) => {
        if (tries > 60) {
          throw new Error("Timed out trying to find item.");
        }
        const item = this.allItems.find(item => item.id === id);
        if (item) {
          this.selectedItemUniqueId = item.uniqueId;
        } else {
          setTimeout(() => {
            findItem(tries + 1);
          }, 50);
        }
      };
      findItem();
    },
    onCancel () {
      this.createItemToggleOn = false;
      if (this.selectedItem) {
        this.$emit("activate", this.selectedItem);
      }
    },
    showCreateNewItemCard () {
      this.createItemToggleOn = true;
    },
    setSelectedItemUniqueId (uniqueId) {
      this.selectedItemUniqueId = uniqueId;
    }
  }
};
</script>

<style lang="css" scoped>

.collection-editor {
  min-height: 450px;
}

.select-button {
  background-color: white;
  margin-top: 2px;
}

.select-button--with-fab {
  margin-top: 10px;
}

.add-button {
  margin-right: 4px;
  background-color: white;
}

.add-button--with-item-selected {
  background-color: var(--primary-color-lighten1);
}

</style>
