<template>
  <standard-layout
    hasContainer
    dark
  >
    <table
      class="mt-10"
    >
      <tr>
        <td cols="5" md="2">
          <img
            src="/img/logo-bw.svg"
            class="logo"
          />
        </td>
        <td style="padding-left: 42px;">
          <div class="text-h3">Page not found</div>
          <div class="text-body-1 mt-4">{{$route.fullPath}}</div>
        </td>
      </tr>
    </table>
  </standard-layout>
</template>

<script>
import StandardLayout from "@/components/standard-layout.vue";

export default {
  components: {
    StandardLayout
  },
  data () {
    return {};
  }
};
</script>

<style lang="css" scoped>

.logo {
  height: 110px;
}

</style>
