<template>
  <v-tooltip
    location="bottom"
  >
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        variant="text"
        icon="mdi-download-box-outline"
        color="primary"
        href="#0"
        @click="onClick"
      >
      </v-btn>
    </template>
    Download quote
  </v-tooltip>
</template>

<script>

export default {
  props: {
    cart: {
      type: Object,
      required: true
    },
    clientCompanyName: {
      type: String,
      required: true
    },
    subTotal: {
      type: String,
      required: true
    }
  },
  data () {
    return {

    };
  },
  methods: {
    async onClick (event) {
      event.preventDefault();

      const clientCompanyName = this.clientCompanyName;
      const subTotal = this.subTotal;
      const {
        isUpgradeOrder,
        tbQuantity,
        envQuantity,
        uvcQuantity,
        uvcPipelinedQuantity,
        uvcDevTbQuantity,
        envInstanceQuantity,
        uvcInstanceQuantity
      } = this.cart;
      const licenseDurationInMonths = this.cart.result.durationInMonths;

      const createQuotePdf = await import("@/utils/create-quote-pdf");
      createQuotePdf.default({
        clientCompanyName,
        isUpgradeOrder,
        licenseDurationInMonths,
        tbQuantity,
        envQuantity,
        uvcQuantity,
        uvcPipelinedQuantity,
        uvcDevTbQuantity,
        envInstanceQuantity,
        uvcInstanceQuantity,
        subTotal
      }).download("UVMGen - Sales Quote.pdf");
    }
  }
};
</script>
