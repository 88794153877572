import promptToInstall from "@/utils/prompt-to-install";
import auth from "@/utils/auth-VITE_APP_PLATFORM";
import { createRouter, createWebHistory, isNavigationFailure, NavigationFailureType } from "vue-router";
import routes from "@/routes-VITE_APP_PLATFORM";
import { validateLicenseRequest } from "@/utils/license-validator-VITE_APP_PLATFORM";
import { db, dbFns } from "@/db-VITE_APP_PLATFORM";
import store from "@/store";

const router = createRouter({
  history: createWebHistory(),
  routes
});

const { collection, doc, getDoc } = dbFns;

/*
router.onError((error, to) => {
  const errors = ["Failed to fetch dynamically imported module", "Unable to preload CSS"];

  if (errors.some((e) => error.message.includes(e))) {
    window.location = to.fullPath;
  }
});
*/

router.beforeEach((to, from, next) => {
  store.commit("setSideNavOpen", { open: false });

  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isAuthenticated) {
    const redirect = (({ name, params, query }) => { return { name, params, query }; })(to);
    auth.openDialog(redirect);
    next(false);
  } else if (!from.name && auth.isSigningInBeforeRedirect() && !store.getters.isAuthenticated) {
    if (to.name !== "SigningIn") {
      next({ name: "SigningIn" });
    } else {
      next();
    }
  } else {
    if (to.matched.some(record => record.meta.isAToolRoute)) {
      promptToInstall();
    }

    if (to.name === "uvcNew") {
      const event = {
        type: "created",
        assetType: "uvc"
      };
      store.getters.user.inAppAssets().then(inAppAssets => {
        validateLicenseRequest(store.getters.user.l, inAppAssets, event).then(result => {
          if (!result.error) {
            store.getters.user.createUvc({ name: "" }).then((id) => {
              // setTimeout(() => {
              //   analytics.log("create_tape", {});
              //   if (store.getters.user.tapes.length === 1) {
              //     analytics.log("create_first_tape", {});
              //   }
              // }, 8 * 1000);
              next({
                name: "uvcEdit",
                params: {
                  id
                }
              });
            });
          }
        });
      });
    } else if (to.name === "uvcEdit") {
      const uvcId = to.params.id;
      getDoc(doc(collection(db, "uvcs"), uvcId))
        .then((doc) => {
          if (!doc.exists()) {
            next({ name: "NotFound", params: { 0: to.path } });
          } else {
            next();
          }
        })
        .catch((e) => {
          const error = new Error("router::beforeEach::get1: " + e.message);
          error.name = e.name;
          throw error;
        });
    } else if (to.name === "envNew") {
      const event = {
        type: "created",
        assetType: "env"
      };
      store.getters.user.inAppAssets().then(inAppAssets => {
        validateLicenseRequest(store.getters.user.l, inAppAssets, event).then(result => {
          if (!result.error) {
            store.getters.user.createEnv({}).then((id) => {
              // setTimeout(() => {
              //   analytics.log("create_tape", {});
              //   if (store.getters.user.tapes.length === 1) {
              //     analytics.log("create_first_tape", {});
              //   }
              // }, 8 * 1000);
              next({
                name: "envEdit",
                params: {
                  id
                }
              });
            });
          }
        });
      });
    } else if (to.name === "envEdit") {
      const envId = to.params.id;
      getDoc(doc(collection(db, "envs"), envId))
        .then((doc) => {
          if (!doc.exists()) {
            next({ name: "NotFound", params: { 0: to.path } });
          } else {
            next();
          }
        })
        .catch((e) => {
          const error = new Error("router::beforeEach::get2: " + e.message);
          error.name = e.name;
          throw error;
        });
    } else if (to.name === "tbNew") {
      const event = {
        type: "created",
        assetType: "tb"
      };
      store.getters.user.inAppAssets().then(inAppAssets => {
        validateLicenseRequest(store.getters.user.l, inAppAssets, event).then(result => {
          if (!result.error) {
            const env = store.getters.user.envs.find(env => env.id === to.query.id);
            env.immediateUpdate({ hasTbOverride: true }).then(() => {
              // setTimeout(() => {
              //   analytics.log("create_tape", {});
              //   if (store.getters.user.tapes.length === 1) {
              //     analytics.log("create_first_tape", {});
              //   }
              // }, 8 * 1000);
              next({
                name: "tbEdit",
                params: {
                  id: env.id
                }
              });
            });
          }
        });
      });
    } else if (to.name === "tbEdit") {
      const envId = to.params.id;
      getDoc(doc(collection(db, "envs"), envId))
        .then((doc) => {
          if (!doc.exists()) {
            next({ name: "NotFound", params: { 0: to.path } });
          } else {
            next();
          }
        })
        .catch((e) => {
          const error = new Error("router::beforeEach::get3: " + e.message);
          error.name = e.name;
          throw error;
        });
    } else {
      next();
    }
  }
});

router.updateQueryParams = function (params) {
  return this.replace({
    query: Object.assign({}, this.currentRoute.value.query, params)
  })
    .catch((e) => {
      if (!isNavigationFailure(e, NavigationFailureType.duplicated)) {
        throw Error(e);
      }
    });
};

router.removeQueryParam = function (name) {
  const query = Object.assign({}, this.currentRoute.value.query);
  if (name in query) {
    delete query[name];
    this.replace({ query })
      .catch((e) => {
        if (!isNavigationFailure(e, NavigationFailureType.cancelled)) {
          throw Error(e);
        }
      });
  }
};

let openedByUser = false;

router.activateFileCard = function (key, byUser = false) {
  if (openedByUser && !byUser) {
    // Do nothing.
  } else {
    openedByUser = byUser;
    this.updateQueryParams({ view: key });
  }
};

router.deactivateFileCard = function (byUser = false) {
  if (openedByUser && !byUser) {
    // Do nothing.
  } else {
    openedByUser = false;
    this.removeQueryParam("view");
  }
};

export default router;
