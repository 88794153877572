<template>
  <collection-editor
    data-test="package-collection-editor"
    :item-type="itemType"
    item-name-property="type"
    list-item-icon="mdi-alpha-p-box"
    :neutral-items="tb.testParameterPackages"
    @activate="$emit('activate', $event)"
  >
    <template v-slot:create-card="{ selectedItem, onSave, onCancel }">
      <class-create-card
        :selected-item="selectedItem"
        :on-save="onSave"
        :on-cancel="onCancel"
        :is-class="false"
        :item-type="itemType"
        new-item-label="Description"
        :new-item-placeholder="newItemPlaceholder"
        new-item-hint="Type package description"
        :create-new-item="createNewItem"
        @activate="$emit('activate', $event)"
      />
    </template>
    <template v-slot:item-editor="{ selectedItem }">
      <item-heading
        :subject="selectedItem"
        item-name="name"
        :subheading="selectedItem.type"
        hasMenu
        rename
        @rename="onInputOfPackageDescription(selectedItem, $event, true)"
        :renameErrorMessages="errorMessages"
      />
      <div class="step-subheading mt-12">Parameters</div>
      <data-input-table-for-parameters
        :parent="selectedItem"
        :can-add-parameters="false"
        :has-expand="false"
      />
    </template>
  </collection-editor>
</template>

<script>
import CollectionEditor from "@/components/collection-editor.vue";
import ClassCreateCard from "@/components/class-create-card.vue";
import ItemHeading from "@/components/item-heading.vue";
import DataInputTableForParameters from "@/components/data-input-table-for-parameters.vue";
import parseSystemVerilog from "@/utils/parse-system-verilog";

export default {
  components: {
    CollectionEditor,
    ClassCreateCard,
    ItemHeading,
    DataInputTableForParameters
  },
  props: {
    tb: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      newPackageDescription: "",
      errorMessages: []
    };
  },
  created () {
    this.itemType = "DUT parameter package";
  },
  methods: {
    createNewItem (identifier, baseClass, master, creatingPolicyForSequence) {
      return this.tb.createTbTestParameterPackage({ name: identifier });
    },
    newItemPlaceholder () {
      return "ie. big, med, sm, fast, slow";
    },
    onInputOfPackageDescription (selectedItem, value, update = false) {
      const errors = [];
      this.newPackageDescription = value;

      try {
        this.newParameterDescription = this.parseClassIdentifier(value);
      } catch (e) {
        errors.push(e.message);
      }
      this.errorMessages = errors;

      if (update && !errors.length) {
        selectedItem.update({
          name: this.newParameterDescription
        });
      }
    },
    parseClassIdentifier (value) {
      return parseSystemVerilog(value, "ClassIdentifier");
    },
    onKeydown (evt) {
      if (evt.code === "Enter") {
        this.saveCreateNewClass();
      }
    }
  }
};
</script>

<style lang="css" scoped>
</style>
