<template>
  <div
    v-if="klass"
    data-test="class-editor"
  >

    <item-heading
      :subject="klass"
      item-name="type"
      :subheading="klass.superclass ? `Extends ${klass.superclass.type}` : ''"
      :hasMenu="hasHeadingMenu"
      rename
      :show-hide-instance-variables="instanceVariablesAreAdvanced"
      :show-hide-subroutines="subroutinesAreAdvanced"
      :show-instance-variables-value="showInstanceVariables"
      :show-subroutines-value="showSubroutines"
      @rename="onInputOfClassIdentifier"
      :rename-error-messages="errorMessages"
      @change:show-instance-variables="showInstanceVariablesOverride = $event"
      @change:show-subroutines="showSubroutinesOverride = $event"
    />

    <div v-if="!klass.isComponent">
      <div
        v-if="klass.isPolicy"
      >
        <div class="step-subheading mt-12">Constraints</div>

        <v-select
          class="mt-6"
          ref="addConstraintSelect"
          variant="outlined"
          :items="randomInstanceVariablesWithNoConstraintOptions"
          return-object
          label="Add constraint..."
          :menu-props="{ contentClass: 'menu-add-constraint--' + klass.type }"
          :no-data-text="policyNoDataText"
          persistent-hint
          :hint="`${klass.subject && klass.subject.type} rand instance variables`"
          @update:modelValue="createConstraint($event)"
        />
        <div :class="{ 'mt-6': (klass.constraints.length > 0) }">
          <div
            v-for="constraint in klass.constraints"
            :key="constraint.id"
          >
            <code-area
              class="constraints-code-area mt-4"
              :disabled="!constraint.instanceVariable.isRandom"
              :label="`${constraint.name}`"
              :placeholder="`object.${constraint.instanceVariable.identifier} == 0;`"
              :numRows="0"
              :value="constraint.content"
              :hint="`Add constraints for ${constraint.instanceVariable.type} ${constraint.instanceVariable.name}`"
              dialogHelp="constraints"
              :before-input-event="constraint.createPersistable && constraint.createPersistable.bind(constraint)"
              @input="constraint.update({ content: $event })"
              destroyable
              @destroy="$store.getters.waitForZeroTransactions.then(() => constraint.destroy())"
            />
          </div>
        </div>
      </div>
      <div
        v-else-if="klass.reachableRandomObjectDoHandles.length"
      >
        <div class="step-subheading mt-12">Apply policies</div>

        <div
          v-for="handle in klass.reachableRandomObjectDoHandles"
          :key="handle.uniqueId"
        >
          <policy-select
            class="mt-6"
            :root-project="rootProject"
            :klass="klass"
            :is-neutral="isNeutral"
            :random-object-do-handle="handle"
            @add-policy="addPolicy(handle)"
          />
        </div>
      </div>
    </div>

    <div v-if="klass.isTest">
      <v-row
        class="expand-heading mt-6"
        align-start
        justify-space-between
      >
        <v-col align-self="center">
          <div class="step-subheading">Factory overrides</div>
        </v-col>
        <v-col class="expand-actions">
          <v-btn
            icon="mdi-plus"
            variant="text"
            @click="createFactoryOverrideToggleOn = true"
          >
          </v-btn>
        </v-col>
      </v-row>

      <!--[TODO] could extract factory override v-card and list into another component. low-priority.-->
      <v-card v-if="createFactoryOverrideToggleOn" class="mt-4 create-factory-override__v-card">
        <v-card-title class="mt-4 text-h5">
          Create new factory override
        </v-card-title>

        <v-card-text class="my-2">
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                class="mt-8"
                variant="underlined"
                v-model="activeFactoryOverride"
                :items="factoryOverrideClasses"
                item-title="type"
                label="Override"
                return-object
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                class="mt-8"
                variant="underlined"
                :items="factoryBaseClasses"
                item-title="type"
                item-value="uniqueId"
                label="Base class"
                :disabled="!activeFactoryOverride"
                return-object
                :model-value="(activeFactoryBaseClass && { type: activeFactoryBaseClass.type, uniqueId: activeFactoryBaseClass.uniqueId }) || (defaultFactoryBaseClass && { type: defaultFactoryBaseClass.type, uniqueId: defaultFactoryBaseClass.uniqueId })"
                @update:modelValue="activeFactoryBaseClass = $event"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="text"
            @click="onOverrideCancel"
          >
            CANCEL
          </v-btn>
          <v-btn
            color="primary"
            variant="text"
            :disabled="!activeFactoryOverride || !(defaultFactoryBaseClass || activeFactoryBaseClass)"
            @click="onOverride"
          >
            OVERRIDE
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-list
        v-if="!createFactoryOverrideToggleOn"
        class="mt-4 inline-list"
        bg-color="transparent"
        two-line
      >
        <v-list-item
          v-for="pair in klass.buildFactoryTypeOverrides"
          :key="pair.override.uniqueId"
          title=""
          prepend-icon="mdi-alpha-b-box"
        >
          <v-list-item-title v-if="pair.override">{{ pair.override.type }}</v-list-item-title>
          <v-list-item-subtitle v-if="pair.base">Overrides {{ pair.base.type }}</v-list-item-subtitle>

          <template #append>
            <v-btn
              variant="text"
              :disabled="$store.getters.hasOpenTransactions"
              icon
              @click="klass.removeBuildFactoryTypeOverride(pair.base, pair.override)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-list-item>

        <v-list-item
          v-for="pair in klass.runtimeFactoryTypeOverrides"
          :key="pair.override.uniqueId"
          title=""
          prepend-icon="mdi-alpha-r-box"
        >
          <v-list-item-title v-if="pair.override">{{ pair.override.type }}</v-list-item-title>
          <v-list-item-subtitle v-if="pair.base">Overrides {{ pair.base.type }}</v-list-item-subtitle>

          <template #append>
            <v-btn
              variant="text"
              :disabled="$store.getters.hasOpenTransactions"
              icon="mdi-delete"
              @click="klass.removeRuntimeFactoryTypeOverride(pair.base, pair.override)"
            >
            </v-btn>
          </template>
        </v-list-item>
      </v-list>
    </div>

    <div
      v-if="showInstanceVariables"
      class="pb-3"
    >
      <div class="step-subheading mt-12">Instance variables</div>
      <data-input-table-for-instance-variables
        :klass="klass"
        :new-items-placeholder="newInstanceVariablesPlaceholder"
      />
    </div>

    <div
      v-if="showSubroutines"
    >
      <div class="step-subheading mt-12">Subroutines</div>

      <code-panels
        class="mt-3"
        :subject="klass"
        collection-name="subroutines"
        get-collection-property="allSubroutines"
        set-collection-property="subroutines"
        :new-item-placeholder="newSubroutinePlaceholder"
      />
    </div>
  </div>
</template>

<script>
import ItemHeading from "@/components/item-heading.vue";
import CodeArea from "@/components/code-area.vue";
import DataInputTableForInstanceVariables from "@/components/data-input-table-for-instance-variables.vue";
import CodePanels from "@/components/code-panels.vue";
import PolicySelect from "@/components/policy-select.vue";
import parseSystemVerilog from "@/utils/parse-system-verilog";
import get from "lodash.get";

export default {
  components: {
    CodeArea,
    ItemHeading,
    DataInputTableForInstanceVariables,
    CodePanels,
    PolicySelect
  },
  props: {
    rootProject: {
      type: Object,
      required: true
    },
    klass: {
      type: Object,
      required: true
    },
    isNeutral: {
      type: Boolean,
      default: true
    },
    hasHeadingMenu: {
      type: Boolean,
      default: true
    },
    instanceVariablesAreAdvanced: {
      type: Boolean,
      default: false
    },
    subroutinesAreAdvanced: {
      type: Boolean,
      default: false
    },
    newInstanceVariablesPlaceholder: {
      type: String,
      required: true
    },
    newSubroutinePlaceholder: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      errorMessages: [],
      activeFactoryOverride: null,
      activeFactoryBaseClass: null,
      createFactoryOverrideToggleOn: false,
      showInstanceVariablesOverride: null,
      showSubroutinesOverride: null
    };
  },
  computed: {
    showInstanceVariables () {
      if (this.showInstanceVariablesOverride !== null) {
        return !this.instanceVariablesAreAdvanced || this.showInstanceVariablesOverride;
      } else {
        return !this.instanceVariablesAreAdvanced || (this.klass.instanceVariables.length > 0);
      }
    },
    showSubroutines () {
      if (this.showSubroutinesOverride !== null) {
        return !this.subroutinesAreAdvanced || this.showSubroutinesOverride;
      } else {
        return (!this.subroutinesAreAdvanced || (this.klass.allSubroutines.length > 0)); // && !this.klass.isPolicy;
      }
    },
    factoryOverrideClasses () {
      return this.klass.project.factoryOverrideClasses;
    },
    factoryBaseClasses () {
      if (this.activeFactoryOverride) {
        return this.activeFactoryOverride.superclasses.filter(obj => !obj.virtual && !obj.isUvmBaseClass);
      } else {
        return [];
      }
    },
    defaultFactoryBaseClass () {
      return (this.factoryBaseClasses.length === 1) ? this.factoryBaseClasses[0] : null;
    },
    policyNoDataText () {
      return `No ${get(this.klass, "subject.reachableRandomInstanceVariables", []).length ? "additional " : ""}random instance variables for ${get(this.klass, "subject.type")}`;
    },
    randomInstanceVariablesWithNoConstraintOptions () {
      return get(this.klass, "subject.reachableRandomInstanceVariables", [])
        .filter(iv => !this.klass.constraints.find(constraint => constraint.instanceVariable && (constraint.instanceVariable.id === iv.id)))
        .map(iv => {
          return { title: `${iv.type} ${iv.name}`, instanceVariable: iv };
        });
    }
  },
  methods: {
    addPolicy (obj) {
      const data = { klass: this.klass, instanceVariable: obj, baseClass: obj.klass };
      if (!this.isNeutral) {
        data.master = this.klass.isMaster;
      }
      this.$emit("add-policy", data);
    },
    onOverrideCancel () {
      this.createFactoryOverrideToggleOn = false;
      this.activeFactoryOverride = null;
      this.activeFactoryBaseClass = null;
    },
    onOverride () {
      if (this.activeFactoryOverride.isComponent || this.activeFactoryOverride.isConfig) {
        this.klass.addBuildFactoryTypeOverride((this.defaultFactoryBaseClass || this.activeFactoryBaseClass), this.activeFactoryOverride);
      } else {
        this.klass.addRuntimeFactoryTypeOverride((this.defaultFactoryBaseClass || this.activeFactoryBaseClass), this.activeFactoryOverride);
      }
      this.createFactoryOverrideToggleOn = false;
      this.activeFactoryOverride = null;
    },
    onInputOfClassIdentifier (value) {
      const errors = [];
      let type;

      try {
        type = this.parseClassIdentifier(value);
        if (!type.match(/_c$/)) {
          errors.push("Must end in '_c'");
        }
      } catch (e) {
        errors.push(e.message);
      }
      this.errorMessages = errors;

      if (!errors.length) {
        this.klass.update({
          type
        });
      }
    },
    parseClassIdentifier (value) {
      return parseSystemVerilog(value, "ClassIdentifier");
    },
    createConstraint (option) {
      if (option) {
        this.$refs.addConstraintSelect.reset();
        this.$refs.addConstraintSelect.blur();
        this.klass.createConstraint({ instanceVariableId: option.instanceVariable.id });
      }
    }
  }
};
</script>

<style lang="css" scoped>

:deep(.inline-list .v-list-item) {
  padding-right: 0;
  padding-left: 0;
}

.expand-actions {
  text-align: right;
  width: 100%;
}

.create-factory-override__v-card {
  margin-left: 2px;
  margin-right: 2px;
}

</style>
