<template>
  <v-checkbox
    :model-value="subject[property]"
    hide-details
    @update:modelValue="onChange"
  />
</template>

<script>

export default {
  props: {
    subject: {
      type: Object,
      required: true
    },
    column: {
      type: Object,
      required: true
    }
  },
  computed: {
    property () {
      return this.column.key;
    }
  },
  methods: {
    onChange (value) {
      const data = {};
      data[this.property] = value;
      this.subject.update(data);
    }
  }
};

</script>
