<template>
  <collection-editor
    data-test="policy-collection-editor"
    :item-type="itemType"
    item-name-property="type"
    :is-neutral="false"
    :hasMaster="rootProject.hasMaster"
    :hasSlave="rootProject.hasSlave"
    :masterItems="masterPolicies"
    :slaveItems="slavePolicies"
    :newClassProperties="newSequencePolicyProperties"
    :add-button-is-disabled="baseClassCollection.length <= 1"
    :add-button-is-fab-condition="baseClassCollection.filter(option => !option.header && !option.divider).length > 0"
    @activate="$emit('activate', $event)"
  >
    <template v-slot:create-card="{ selectedItem, onSave, onCancel }">
      <class-create-card
        :selected-item="selectedItem"
        :on-save="onSave"
        :on-cancel="onCancel"
        :item-type="itemType"
        is-policy
        :is-neutral="false"
        new-item-hint="Type policy name"
        :new-item-placeholder="newItemPlaceholder"
        :create-new-item="createNewItem"
        :baseClassCollection="baseClassCollection"
        :defaultBaseClass="defaultBaseClass"
        :newClassProperties="newSequencePolicyProperties"
        @done="$emit('policy-done')"
        @activate="$emit('activate', $event)"
      />
    </template>
    <template v-slot:item-editor="{ selectedItem }">
      <class-editor
        :root-project="rootProject"
        :klass="selectedItem"
        :is-neutral="false"
        instance-variables-are-advanced
        subroutines-are-advanced
        :new-instance-variables-placeholder="`bit x\nint y`"
        :new-subroutine-placeholder="`virtual function int calc_it();\n  // ...\nendfunction`"
      />
    </template>
  </collection-editor>
</template>

<script>
import CollectionEditor from "@/components/collection-editor.vue";
import ClassCreateCard from "@/components/class-create-card.vue";
import ClassEditor from "@/components/class-editor.vue";
import get from "lodash.get";

export default {
  components: {
    CollectionEditor,
    ClassCreateCard,
    ClassEditor
  },
  props: {
    rootProject: {
      type: Object,
      required: true
    },
    newSequencePolicyProperties: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
    };
  },
  created () {
    this.itemType = "policy";
  },
  computed: {
    masterPolicies () {
      return get(this.rootProject.package, "masterPolicies", []);
    },
    slavePolicies () {
      return get(this.rootProject.package, "slavePolicies", []);
    },
    baseClassCollection () {
      const policyOptions = [];

      // [TODO] re-enable once supported: https://github.com/vuetifyjs/vuetify/issues/15721
      // policyOptions.push({ header: "Sequence item" });
      if (this.rootProject.sequenceItem) {
        policyOptions.push(this.rootProject.sequenceItem);
      }
      // policyOptions.push({ divider: true });

      return policyOptions
        // .concat([{ header: "Master sequences" }])
        .concat(get(this.rootProject.package, "masterSequences", []))
        // .concat([{ divider: true }, { header: "Slave sequences" }])
        .concat(get(this.rootProject.package, "slaveSequences", []));
    }
  },
  methods: {
    createNewItem (identifier, baseClass, master, creatingPolicyForSequence) {
      return baseClass.createFormPolicy({ type: identifier, master })
        .then(id => {
          if (creatingPolicyForSequence) {
            return new Promise(resolve => {
              this.addPolicyToSequence(id, resolve);
            })
              .then(() => {
                return id;
              });
          } else {
            return id;
          }
        });
    },
    addPolicyToSequence (id, resolve, numTries = 0) {
      if (numTries > 5) {
        throw new Error("Could not add policy.");
      }
      const policy = this.newSequencePolicyProperties.instanceVariable.klass.formPolicies.find(policy => policy.id === id);
      if (policy) {
        this.newSequencePolicyProperties.klass.addPolicy(policy, this.newSequencePolicyProperties.instanceVariable).then(resolve);
      } else {
        setTimeout(() => {
          this.addPolicyToSequence(id, resolve, numTries + 1);
        }, 1000);
      }
    },
    defaultBaseClass () {
      return this.baseClassCollection[1];
    },
    newItemPlaceholder (baseClass, isMaster) {
      return `${this.rootProject.name}_${isMaster ? "master_" : "slave_"}xyz_policy_c`;
    }
  }
};
</script>

<style lang="css" scoped>
</style>
