<template>
  <v-main style="height: 100%;">

    <top-nav
      :title="title"
      :dark="dark"
    />

    <div
     style="height: 100%"
      v-if="$store.getters.webToolEnabled"
      class="scrollbox scrollbox-ios-momentum"
    >
      <v-container
        style="height: 100%; width: 100%;"
        v-if="hasContainer"
        class="standard-layout__container scrollbox-content"
      >
        <slot></slot>
      </v-container>

      <div
        style="height: 100%; width: 100%;"
        v-else
        class="scrollbox-content"
      >
        <slot></slot>
      </div>
    </div>
  </v-main>
</template>

<script>
import TopNav from "@/components/top-nav.vue";

export default {
  components: {
    TopNav
  },
  props: {
    hasContainer: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "UVMGen"
    },
    dark: {
      type: Boolean,
      default: false
    }
  }
};

</script>

<style lang="css" scoped>

.standard-layout__container {
  max-width: 1000px;
}

</style>
