<template>
  <v-tooltip
    bottom
  >
    <template v-slot:activator="{ props }">
      <div
        v-bind="props"
      >
        <v-icon v-if="disabled">
          {{ iconName }}
        </v-icon>
        <div
          v-else
          class="parent-element-for-absolutes"
        >
          <v-switch
            data-test="data-input-table__toggle-switch"
            :input-value="state === 'off'"
            color="accent"
            density="compact"
            @click="onClick"
          />
          <v-icon v-if="state === 'on'" class="master-icon" color="white" size="18">mdi-alpha-m</v-icon>
          <v-icon v-if="state === 'off'" class="slave-icon" size="18">mdi-alpha-s</v-icon>
        </div>
      </div>
    </template>

    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script>

// [TODO] no longer used. Strip out after soak.
export default {
  props: {
    subject: {
      type: Object,
      required: true
    },
    column: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    };
  },
  computed: {
    iconName () {
      return "mdi-alpha-" + this.label.charAt(0).toLowerCase();
    },
    property () {
      return this.column.key;
    },
    buttonProperties () {
      return this.column["toggle-button"].properties(this.subject);
    },
    state () {
      return this.buttonProperties.state;
    },
    label () {
      return this.buttonProperties.label;
    },
    disabled () {
      return this.state === "disabled";
    }
  },
  methods: {
    onClick () {
      const data = {};
      data[this.property] = !this.subject[this.property];
      this.subject.update(data);
    }
  }
};

</script>

<style lang="css" scoped>

.v-btn:not(.v-btn--outline):not(.v-btn--disabled) {
  /*box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;*/
  /*box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2) !important;*/
 /* , 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;*/
}

:deep(.v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset) .v-input--switch__thumb) {
  color: var(--primary-color);
}

:deep(.theme--light.v-input--switch .v-input--switch__track) {
  color: var(--primary-color);
}

.parent-element-for-absolutes {
  position: relative; max-height: 30px;
}

.v-switch {
  margin-top: 0;
}

.master-icon {
  pointer-events: none;
  position: absolute;
  top: 5.5px;
  left: 0;
}

.slave-icon {
  pointer-events: none;
  position: absolute;
  top: 6px;
  left: 20px;
}

</style>
