<template>
  <div>
    <v-dialog
      persistent
      max-width="400"
      :model-value="!$store.getters.user.agreedToTerms"
    >
      <v-card>
        <v-card-title class="headline">
          Agreement of Terms
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-model="agreeToTermsChecked"
          >
            <template v-slot:label>
              <div>
                I agree and consent to the
                <a @click.stop="showTermsOfService = true">
                  Terms of Service</a>, it's policies, and the
                <a @click.stop="showPrivacyPolicy = true">
                  Privacy Policy</a>.
              </div>
            </template>
          </v-checkbox>
          Please agree to proceed.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="mr-2 mb-2"
            text
            color="primary"
            @click="onAgreementOfTerms"
            :disabled="!agreeToTermsChecked"
          >
            I Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div
      v-show="showTermsOfService || showPrivacyPolicy"
      class="dialog-container"
    >
      <v-card
        class="dialog-card"
        v-show="showTermsOfService"
      >
        <dialog-terms-of-service
          navIcon="mdi-close"
          @pop-dialog="showTermsOfService = false"
        />
      </v-card>

      <v-card
        class="dialog-card"
        v-show="showPrivacyPolicy"
      >
        <dialog-privacy-policy
          navIcon="mdi-close"
          @pop-dialog="showPrivacyPolicy = false"
        />
      </v-card>
    </div>

  </div>
</template>

<script>

export default {
  name: "agree-to-terms-modal",
  data () {
    return {
      agreeToTermsChecked: false,
      showTermsOfService: false,
      showPrivacyPolicy: false
    };
  },
  methods: {
    onAgreementOfTerms () {
      this.$store.getters.user.update({ agreedToTerms: true });
      this.$emit("user-agreed-to-terms");
    }
  }
};
</script>

<style lang="css" scoped>
.dialog-container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: calc(var(--agree-to-terms-modal-z-index) + 1);
}

.dialog-card {
  height: 100%;
  width: 100%;
}

@media all and (min-width: 601px) {
  .dialog-container {
    padding: 40px;
  }
  .dialog-card {
    margin: auto;
    max-width: 425px;
  }
}
</style>
