<template>
  <v-stepper
    ref="stepper"
    style="min-height: 425px;"
    :model-value="step"
    @update:modelValue="updateStep"
    elevation="0"
  >
    <v-stepper-header>
      <v-stepper-item
        data-test="download-app-step-button"
        editable
        :edit-icon="null"
        :complete="stepDownloadAppComplete"
        :value="stepDownloadApp"
      >
        {{ stepDownloadAppTitle }}
        <!--<small>Summarize if needed</small>-->
      </v-stepper-item>

      <v-stepper-item
        data-test="install-app-step-button"
        editable
        :edit-icon="null"
        :complete="stepInstallAppComplete"
        :value="stepInstallApp"
      >
        {{ stepInstallAppTitle }}
        <!--<small>Optional</small>-->
      </v-stepper-item>

      <v-stepper-item
        data-test="license-key-step-button"
        editable
        :edit-icon="null"
        :complete="stepLicenseKeyComplete"
        :value="stepLicenseKey"
      >
        {{ stepLicenseKeyTitle }}
        <!--<small>Summarize if needed</small>-->
      </v-stepper-item>
    </v-stepper-header>
    <div v-if="$route.query.step == stepDownloadApp" data-test="download-app-step-content">
      <div class="text-subtitle-1 d-md-none">{{ stepDownloadAppTitle }}</div>

      <v-card
        class="mt-6 mb-4"
        elevation="0"
      >
        <v-card-title class="pl-0">
          The UVMGen desktop app
        </v-card-title>
        <!--<v-card-subtitle class="font-weight-medium">-->
        <!--  Download-->
        <!--</v-card-subtitle>-->
        <v-card-text class="pl-0">
          Click the button to download the latest version of the UVMGen on-premise desktop application for Linux.
        </v-card-text>
        <v-card-actions class="mt-8 pl-0">
          <div
            v-for="itemRef in storageItems"
            :key="itemRef.name"
          >
            <v-btn
              variant="outlined"
              prepend-icon="mdi-download"
              @click="onDownloadDesktopApp(itemRef)"
            >
              DOWNLOAD
            </v-btn>
            <span class="ml-6">
              {{ itemRef.name }}
            </span>
          </div>
        </v-card-actions>
      </v-card>

      <div class="d-flex justify-end">
        <v-btn
          class="continue-button mt-12"
          color="primary"
          @click="nextStep(stepDownloadApp)"
        >CONTINUE</v-btn>
      </div>
    </div>

    <div data-test="install-app-step-content"
      v-if="$route.query.step == stepInstallApp">
      <div class="text-subtitle-1 d-md-none">{{ stepInstallAppTitle }}</div>

      <v-card
        class="mt-6 mb-4"
        elevation="0"
      >
        <v-card-title class="pl-0">
          Install instructions
        </v-card-title>
        <!--<v-card-subtitle class="font-weight-medium">-->
        <!--  Download-->
        <!--</v-card-subtitle>-->
        <v-card-text class="pl-0">
          <div class="mt-2">Extract the downloaded archive to a location accessible to the users of the uvmgen executable.</div>
          <v-text-field
            class="mt-6 code__text-field"
            spellcheck="false"
            autocapitalize="off"
            variant="solo"
            theme="dark"
            hide-details
            readonly
            :model-value="'$  ' + extractCmd"
          >
            <template v-slot:append-inner>
              <v-btn
                variant="flat"
                icon
                @click="onCopy(extractCmd)"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <div class="mt-12">Follow your corporations guidlines for making versioned software available to your users. The most basic way is to update $PATH.</div>
          <v-text-field
            class="mt-6 code__text-field"
            spellcheck="false"
            autocapitalize="off"
            variant="solo"
            theme="dark"
            hide-details
            readonly
            :model-value="'$  ' + pathCmd"
          >
            <template v-slot:append-inner>
              <v-btn
                bg-color="rgba(0, 0, 0, 0.6)"
                variant="flat"
                icon
                @click="onCopy(pathCmd)"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <div class="mt-12">Change to the version controlled directory or sub-directory, which holds your IP/VIP. In this directory, the uvmgen.json file will be created.</div>
          <v-text-field
            class="mt-6 code__text-field"
            spellcheck="false"
            autocapitalize="off"
            variant="solo"
            theme="dark"
            hide-details
            readonly
            :model-value="'$  ' + cdCmd"
          >
            <template v-slot:append-inner>
              <v-btn
                variant="flat"
                icon
                @click="onCopy(cdCmd)"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <div class="mt-12">Run the uvmgen executable.</div>
          <v-text-field
            class="mt-6 code__text-field"
            spellcheck="false"
            autocapitalize="off"
            variant="solo"
            theme="dark"
            hide-details
            readonly
            :model-value="'$  ' + runCmd"
          >
            <template v-slot:append-inner>
              <v-btn
                variant="flat"
                icon
                @click="onCopy(runCmd)"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-card-text>
      </v-card>

      <div class="d-flex justify-end">
        <v-btn
          class="continue-button mt-14"
          color="primary"
          @click="nextStep(stepInstallApp)"
        >CONTINUE</v-btn>
      </div>
    </div>

    <div data-test="license-key-step-content" v-if="$route.query.step == stepLicenseKey">
      <div class="text-subtitle-1 d-md-none">{{ stepLicenseKeyTitle }}</div>

      <v-card
        class="mt-6 mb-4"
        elevation="0"
      >
        <v-card-title class="pl-0">
          License key
        </v-card-title>
        <!--<v-card-subtitle class="font-weight-medium">-->
        <!--  {{ payment.licenseId }}-->
        <!--</v-card-subtitle>-->
        <v-card-text class="pl-0">
          <div class="mt-2">Copy this key into the UVMGen desktop app to start generating best in class VIP.</div>
          <license-key
            v-if="license"
            class="mt-4"
            :licenseKey="license.licenseKey"
          />
          <div class="mt-12">Check-in/commit the uvmgen.json file into your version control system. For example, here is the command if you use git.</div>
          <v-text-field
            class="mt-6 code__text-field"
            spellcheck="false"
            autocapitalize="off"
            variant="solo"
            theme="dark"
            hide-details
            readonly
            :model-value="'$  ' + commitCmd"
          >
            <template v-slot:append-inner>
              <v-btn
                variant="flat"
                icon
                @click="onCopy(commitCmd)"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-actions class="pl-0">
        </v-card-actions>
      </v-card>
    </div>
  </v-stepper>
</template>

<script>
import { VStepper, VStepperHeader, VStepperItem } from "vuetify/labs/VStepper";
import { ref, listAll, getBlob } from "firebase/storage";
import { storage } from "vue-app-utils-web";
import FileSaver from "@/utils/file-saver";
import semver from "semver";
import LicenseKey from "@/components/license-key.vue";
import { isNavigationFailure, NavigationFailureType } from "vue-router";

export default {
  components: {
    VStepper,
    VStepperHeader,
    VStepperItem,
    LicenseKey
  },
  props: {
    license: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      storageItems: []
    };
  },
  watch: {
    "license.active": {
      handler (active) {
        if (active) {
          const storageRef = ref(storage(), "uvmgen-versions");
          const storageItems = [];
          listAll(storageRef).then(res => {
            // res.prefixes.forEach(folderRef => {
            //   // All the prefixes under listRef.
            //   // You may call listAll() recursively on them.
            // });
            res.items.forEach(itemRef => {
              storageItems.push(itemRef);
            });

            const re = /^uvmgen-(.*)\.tar.gz$/;
            this.storageItems = storageItems.sort((a, b) => {
              const aVer = a.name.match(re)[1];
              if (!aVer || !semver.valid(aVer)) {
                throw new Error(`Storage file '${a.name}' is not in the expected format.`);
              }
              const bVer = b.name.match(re)[1];
              if (!bVer || !semver.valid(bVer)) {
                throw new Error(`Storage file '${b.name}' is not in the expected format.`);
              }

              if (semver.gt(aVer, bVer)) {
                return -1;
              } else if (semver.gt(bVer, aVer)) {
                return 1;
              } else {
                throw new Error(`Not expecting '${a.name}' and '${b.name}' to have equivelent semvers (${aVer} and ${bVer}).`);
              }
            })
              .slice(0, 1);
          });
        } else {
          this.storageItems = [];
        }
      },
      immediate: true
    }
  },
  created () {
    this.stepDownloadApp = 1;
    this.stepInstallApp = 2;
    this.stepLicenseKey = 3;
    this.stepDownloadAppTitle = "Download UVMGen";
    this.stepInstallAppTitle = "Install UVMGen";
    this.stepLicenseKeyTitle = "Copy license key";
  },
  mounted () {
    if (this.step === undefined) {
      this.updateStep(1);
    }
  },
  computed: {
    user () {
      return this.$store.getters.user;
    },
    step () {
      return parseInt(this.$route.query.step);
    },
    stepDownloadAppComplete () {
      return this.step > this.stepDownloadApp;
    },
    stepInstallAppComplete () {
      return this.step > this.stepInstallApp;
    },
    stepLicenseKeyComplete () {
      return this.step > this.stepLicenseKey;
    },
    pathToExtractTo () {
      return "/path/to/extract/to";
    },
    extractCmd () {
      return `tar -xzf ${this.storageItems[0] && this.storageItems[0].name} -C ${this.pathToExtractTo}/`;
    },
    cdCmd () {
      return "cd /path/to/your/repo";
    },
    pathCmd () {
      return `export PATH="$PATH:${this.pathToExtractTo}/${this.storageItems[0] && this.storageItems[0].name.replace(/\.tar\.gz$/, "")}"`;
    },
    runCmd () {
      return "uvmgen";
    },
    commitCmd () {
      return "git add uvmgen.json && git commit -m \"Initializes uvmgen.\"";
    }
  },
  methods: {
    nextStep (step) {
      let nextStep = step + 1;

      if (nextStep > this.stepLicenseKey) {
        nextStep = 1;
      }
      this.$router.updateQueryParams({ step: nextStep });
    },
    updateStep (step) {
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { step })
      })
        .catch((e) => {
          if (!isNavigationFailure(e, NavigationFailureType.duplicated)) {
            throw Error(e);
          }
        });
    },
    onDownloadDesktopApp (itemRef) {
      getBlob(itemRef).then(blob => {
        FileSaver.saveAs(blob, itemRef.name);
      });
    },
    onCopy (content) {
      navigator.clipboard.writeText(content);
      this.$store.commit("pushSnackbarMessageProps", {
        message: "Command copied."
      });
    }
  }
};

</script>

<style lang="css" scoped>

.code__text-field {
  max-width: 540px;
}

:deep(.v-stepper-item--selected .v-avatar) {
  background-color: rgb(var(--v-theme-primary));
}

:deep(.v-stepper-item .v-avatar i) {
  color: white;
}

@media all and (min-width: 0px) and (max-width: 960px) {
  :deep(.v-stepper-item__content) {
    display: none;
  }
}

</style>
