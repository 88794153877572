<template>
  <v-switch
    v-if="showPublicSwitch"
    class="mr-3"
    v-model="isPublic"
    density="compact"
    hide-details
    @update:modelValue="showDialog"
  >
    <template #prepend>
      <v-label>Public</v-label>
    </template>
  </v-switch>

  <account-icon class="ml-4 account-icon" :progressCircleColor="$vuetify.theme.current.colors.primaryLight" />
</template>

<script>
import config from "@/config";
import { AccountIcon } from "vue-app-components";

export default {
  components: {
    AccountIcon
  },
  data () {
    return {
      isPublic: true
    };
  },
  computed: {
    showPublicSwitch () {
      return this.$route.name === "uvcEdit";
    }
  },
  methods: {
    showDialog () {
      this.$store.commit("setOnPremiseDialogTitle", "Private VIP");
      this.$store.commit("setOnPremiseDialogParagraph", config.onPremiseMarketingParagraph);
      this.$dialog.override("on-premise-license");
      this.$nextTick(() => {
        this.isPublic = true;
      });
    }
  }
};

</script>

<style lang="css" scoped>

:deep(.v-input--reverse .v-input__slot) {
  flex-direction: row-reverse;
}

</style>
