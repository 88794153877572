<template>
  <standard-layout
    has-container
  >
    <div
      class="mt-4 mt-md-16 pb-16"
    >
      <div v-if="!onLocalhost">
        <div class="text-h5">Admin only accessible on localhost</div>
      </div>
      <div
        v-else-if="(user.role === 'admin') && user.priceSheet"
      >
        <div class="text-h5">Admin (<span :class="{ 'error--text': projectId === 'uvmgen-production' }">{{ projectId }}</span>)</div>

        <div class="mt-12 mb-10 step-subheading">
          Price sheet
        </div>

        <v-row
          v-for="(feature, featureIndex) in user.priceSheet.features"
          :key="featureIndex"
          class="mx-4"
        >
          <v-col>
            {{ feature.id }}
          </v-col>
          <v-col>
            <v-text-field
              style="max-width: 300px;"
              variant="underlined"
              label="Price per month"
              type="number"
              step="0.01"
              :min="0"
              placeholder="1.00"
              :model-value="feature.price"
              @update:modelValue="onFeatureUpdate(featureIndex, 'price', $event)"
            ></v-text-field>
          </v-col>
        </v-row>

        <div class="mt-12 mb-10 step-subheading">
          Waitlist
        </div>

        <v-table
          class="mt-4"
          density="compact"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  userId
                </th>
                <th class="text-left">
                  name
                </th>
                <th class="text-left">
                  email
                </th>
                <th class="text-left">
                  Waitlisted on
                </th>
                <th class="text-left">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="user in waitlistedUsers"
                :key="user.id"
              >
                <td>
                  {{ user.id }}
                </td>
                <td>
                  {{ user.displayName }}
                </td>
                <td>
                  {{ user.privateProfile && (user.privateProfile.paymentEmail || user.privateProfile.email) }}
                </td>
                <td>
                  {{ user.privateProfile && formatDate(new Date(user.privateProfile.waitlistedTime)) }}
                </td>
                <td>
                  <v-btn
                    color="primary"
                    text
                    @click="approveWaitlist(user)"
                  >
                    APPROVE
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>

        <div class="mt-16 mb-10 step-subheading">
          Global price adjustment function
        </div>

        <price-adjustment-simulator />

        <div class="mt-12 step-subheading">Price adjustment functions for Initial and Upgrade orders</div>
        <div class="mb-10 body-1">Applied on top of global price adjustment</div>

        <v-select
          class="mt-6 mb-8"
          variant="underlined"
          :items="users.map(user => { return { title: `${user.displayName} (${user.privateProfile && user.privateProfile.email})`, value: user.id }; })"
          label="User"
          :model-value="selectedUserCartId"
          @update:modelValue="selectedUserCartId = $event"
        />

        <cart-price-adjustment-simulator
          v-if="selectedUserCart"
          :cart="selectedUserCart"
        />

        <div class="mt-16 mb-10 step-subheading">
          <div>
            Price adjustment functions for Renewal orders
          </div>
        </div>

        <v-select
          class="mt-6 mb-8"
          variant="underlined"
          :items="licenses.map(license => { return { title: `${license.user.displayName} (${license.user.privateProfile && license.user.privateProfile.email}): ${license.durationInMonths} month license ends ${license.endDate && formatDate(license.endDate)} (${license.status})`, value: license.id, disabled: !license.active }; })"
          label="Licenses"
          :model-value="selectedLicenseId"
          @update:modelValue="selectedLicenseId = $event"
        />

        <renewal-price-adjustment-simulator
          v-if="selectedLicense"
          :license="selectedLicense"
        />

        <div class="mt-16 step-subheading">
          Emails
        </div>

        <div class="mt-8 text-primary h5">
          Users with Benefits
        </div>

        <div
          class="mt-4"
          v-for="user in usersWithBenefits"
          :key="user.id"
        >
          <admin-email-user :user="user" />
        </div>

        <div class="mt-8 text-primary h5">
          Users with Downloaded UVCs
        </div>

        <div
          class="mt-4"
          v-for="user in usersWithDownloadedUvcs"
          :key="user.id"
        >
          <admin-email-user :user="user" />
        </div>

        <div class="mt-8 text-primary h5">
          Users with UVCs
        </div>

        <div
          class="mt-4"
          v-for="user in usersWithoutDownloadedUvcs"
          :key="user.id"
        >
          <admin-email-user :user="user" />
        </div>

        <div class="mt-8 text-primary h5">
          Users without UVCs
        </div>

        <div
          class="mt-4"
          v-for="user in usersWithoutUvcs"
          :key="user.id"
        >
          <admin-email-user :user="user" />
        </div>

        <div class="mt-8 text-primary h5">
          Users without emails ({{ usersWithoutEmail.length }} - was 6)
        </div>

        <div
          class="mt-4"
          v-for="user in usersWithoutEmail"
          :key="user.id"
        >
          {{ user.id }}
          <span v-if="user.displayName">
            / {{ user.displayName }}
          </span>
          <span v-if="user.isAnonymous">
            (Anonymous)
          </span>
        </div>
      </div>
    </div>
  </standard-layout>
</template>

<script>
import session from "@/models/session-VITE_APP_PLATFORM";
import { db, dbFns } from "@/db-VITE_APP_PLATFORM";
import StandardLayout from "@/components/standard-layout.vue";
import PriceAdjustmentSimulator from "@/components/price-adjustment-simulator.vue";
import CartPriceAdjustmentSimulator from "@/components/cart-price-adjustment-simulator.vue";
import RenewalPriceAdjustmentSimulator from "@/components/renewal-price-adjustment-simulator.vue";
import AdminEmailUser from "@/components/admin-email-user.vue";
import { formatDate } from "vue-app-utils";
import { sendMail } from "vue-app-utils-web";

export default {
  components: {
    StandardLayout,
    PriceAdjustmentSimulator,
    CartPriceAdjustmentSimulator,
    RenewalPriceAdjustmentSimulator,
    AdminEmailUser
  },
  data () {
    return {
      users: [],
      asc: false,
      sortProp: "createdAt",
      selectedUserCartId: "",
      selectedLicenseId: ""
    };
  },
  created () {
    this.onLocalhost = location.hostname === "localhost";
    this.projectId = import.meta.env.VITE_APP_FIRESTORE_PROJECT_ID;
    this.formatDate = formatDate;

    const unwatch = this.$watch("user", (user) => {
      if (user && (user.role === "admin")) {
        this.getAllUsers();
        this.$nextTick(() => { unwatch(); });
      }
    }, { immediate: true });
  },
  beforeUnmount () {
    const promises = [];
    this.users.forEach((user) => {
      if (user.id !== this.user.id) {
        promises.push(user.unsubscribe());
      }
    });
    return Promise.all(promises);
  },
  computed: {
    user () {
      return this.$store.getters.user;
    },
    waitlistedUsers () {
      return this.users
        .filter(user => user.privateProfile && user.privateProfile.waitlistedTime && !user.privateProfile.waitlistApproved)
        .sort((a, b) => a.privateProfile.waitlistedTime - b.privateProfile.waitlistedTime);
    },
    usersWithEmail () {
      return this.users.filter(user => user.privateProfile && (user.privateProfile.email || user.privateProfile.paymentEmail));
    },
    usersWithoutEmail () {
      return this.users.filter(user => user.privateProfile && !(user.privateProfile.email || user.privateProfile.paymentEmail));
    },
    usersWithBenefits () {
      return this.usersWithEmail.filter(user => user.privateProfile && user.privateProfile.benefits.length);
    },
    usersWithoutBenefits () {
      return this.usersWithEmail.filter(user => !user.privateProfile || !user.privateProfile.benefits.length);
    },
    usersWithUvcs () {
      return this.usersWithoutBenefits.filter(user => user.uvcs.length);
    },
    usersWithDownloadedUvcs () {
      return this.usersWithUvcs.filter(user => user.uvcs.find(uvc => uvc.numDownloads > 0));
    },
    usersWithoutDownloadedUvcs () {
      return this.usersWithUvcs.filter(user => !user.uvcs.find(uvc => uvc.numDownloads > 0));
    },
    usersWithoutUvcs () {
      return this.usersWithEmail.filter(user => !this.usersWithBenefits.includes(user) && !this.usersWithUvcs.includes(user));
    },
    licenses () {
      return this.users.map(user => user.licenses).flat();
    },
    selectedUserCart () {
      const user = this.users.find(user => user.id === this.selectedUserCartId);
      return user && user.cart;
    },
    selectedLicense () {
      return this.licenses.find(license => license.id === this.selectedLicenseId);
    }
  },
  methods: {
    getAllUsers () {
      dbFns.getDocs(
        dbFns.query(
          dbFns.collection(db, "users"),
          dbFns.orderBy(this.sortProp, this.asc ? "asc" : "desc")
        )
      )
        .then((querySnapshot) => {
          this.users.push(this.user);
          querySnapshot.forEach((queryDocumentSnapshot) => {
            const id = queryDocumentSnapshot.ref.id;
            if ((id !== "uvmgen-user-id") && (id !== this.user.id)) {
              session.findUserById(id).then(user => {
                this.users.push(user);
              });
            }
          });
        })
        .catch((e) => {
          const error = new Error("dashboard::created::get: " + e.message);
          error.name = e.name;
          throw error;
        });
    },
    onFeatureUpdate (featureIndex, property, value) {
      const data = {};
      const features = new Array(...this.user.priceSheet.features);

      data[property] = parseFloat(value);
      features[featureIndex] = Object.assign({}, features[featureIndex], data);

      this.user.priceSheet.update({ features });
    },
    async approveWaitlist (user) {
      await user.privateProfile.immediateUpdate({ waitlistApproved: true });
      await this.sendWaitlistMail(user.privateProfile.paymentEmail || user.privateProfile.email);
    },
    async sendWaitlistMail (email) {
      const subject = "You have been promoted from the waitlist";
      const message = `Good news! The wait is over. You can now purchase your UVMGen license and start generating VIP on-premise with the UVMGen desktop app for Linux.

Go to www.uvmgen.com, open the side-nav and click 'Download desktop app' to get started.

All the best,
The UVMGen team
feedback@uvmgen.com
`;
      await sendMail(email, "feedback@uvmgen.com", "UVMGen <feedback@uvmgen.com>", subject, message, this.$store, "Waitlist email sent.");
    }
  }
};
</script>

<style lang="css" scoped>
</style>
