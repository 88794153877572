<template>
  <div
    class="v-card-container"
    :class="{ 'v-card-container--active1': vCardContainerActive1, 'v-card-container--active2': vCardContainerActive2, 'v-card-container--active3': vCardContainerActive3, 'v-card-container--active4': vCardContainerActive4, 'v-card-container--active5': vCardContainerActive5 }"
    :style="vCardStyles"
    ref="vCardContainer"
  >
    <v-card
      :class="vCardClasses"
      style="overflow: initial"
      v-on:click="active || $router.activateFileCard(model.key, true)"
    >

      <div
        class="code-container scrollbox"
        :class="{'scrollbox--hidden': !vCardContainerActive5, 'scrollbox--disable-on-mobile': !vCardContainerActive5}"
      >
        <div class="scrollbox-content">
          <pre
            v-html="codeBlock"
            data-test="file-content"
            style="user-select: none;"
            :data-test-file-path="filePath"
          />
        </div>
      </div>

      <v-card-actions>

        <div
          class="text-h5 ellipsis"
        >
          {{ model.fileName }}
        </div>

        <v-spacer/>

        <v-btn
          v-show="vCardContainerActive5"
          @click.stop="$router.deactivateFileCard(true)"
          text
        >
          DONE
        </v-btn>

      </v-card-actions>

    </v-card>
  </div>
</template>

<script>
import StringConverter from "@/utils/string-converter";

export default {
  name: "file-card",
  props: {
    directory: {
      type: String,
      required: true
    },
    model: {
      type: Object,
      required: true
    },
    transitionDuration: {
      type: Number,
      required: true
    },
    activeSemaphore: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      vCardPlaceholderTop: 0,
      vCardPlaceholderLeft: 0,
      vCardPlaceholderHeight: 0,
      vCardPlaceholderWidth: 0,
      vCardContainerActive1: false,
      vCardContainerActive2: false,
      vCardContainerActive3: false,
      vCardContainerActive4: false,
      vCardContainerActive5: false
    };
  },
  emits: ["pre-activate"],
  created () {
    this.activatedPromises = [];
    this.deactivatedResolves = [];
  },

  mounted () {
    if (this.active) {
      this.activate();
    }
  },

  beforeUnmount () {
    this.deactivatedResolves.forEach(resolve => {
      this.$router.deactivateFileCard(true);
      resolve();
      this.$store.commit("setFileCardDeactivated");
    });
  },

  watch: {

    active () {
      if (this.active) {
        this.activate();
      } else {
        this.deactivate();
      }
    }
  },

  computed: {

    active () {
      return this.$route.query.view === this.model.key;
    },

    filePath () {
      return `${this.model.collectionName}/${this.model.projectFilePath}`;
    },

    codeBlock () {
      return StringConverter.toHtml(this.model.code);
    },

    vCardClasses () {
      return {
        "v-card--hover": !this.vCardContainerActive1,
        "elevation-10": this.vCardContainerActive1
      };
    },

    vCardStyles () {
      return {
        "--v-card-placeholder-top": this.vCardPlaceholderTop + "px",
        "--v-card-placeholder-left": this.vCardPlaceholderLeft + "px",
        "--v-card-placeholder-height": this.vCardPlaceholderHeight + "px",
        "--v-card-placeholder-width": this.vCardPlaceholderWidth + "px"
      };
    }

  },

  methods: {

    activate () {
      const deactivatedPromise = new Promise(resolve => {
        this.deactivatedResolves.push(resolve);
      });
      this.activatedPromises.push(new Promise(resolve => {
        this.activeSemaphore.callFunction(async () => {
          let r;
          const preActivatePromise = new Promise(resolve => { r = resolve; });
          this.$emit("pre-activate", this.$el, r);
          await preActivatePromise;

          this.getCoordinates()
            .then(() => {
              this.vCardContainerActive1 = true;
              this.$store.commit("setFileCardActivated");
              setTimeout(() => {
                this.vCardContainerActive2 = true;
                setTimeout(() => {
                  this.vCardContainerActive3 = true;
                  setTimeout(() => {
                    this.vCardContainerActive4 = true;
                    setTimeout(() => {
                      this.vCardContainerActive5 = true;
                      resolve();
                    }, 10);
                  }, this.transitionDuration);
                }, 10);
              }, 10);
            })
            .catch(() => {
              // can happen when navigating to UVCs project cards
            });
          return deactivatedPromise;
        });
      }));
    },

    deactivate () {
      this.activatedPromises.shift().then(() => {
        this.getCoordinates()
          .then(() => {
            this.vCardContainerActive5 = false;
            setTimeout(() => {
              this.vCardContainerActive4 = false;
              setTimeout(() => {
                this.vCardContainerActive3 = false;
                setTimeout(() => {
                  this.vCardContainerActive2 = false;
                  this.vCardContainerActive1 = false;
                  if (!this.deactivatedResolves.length) {
                    throw new Error("deactivatedResolves = [] for " + this.model.key);
                  }
                  this.deactivatedResolves.shift()();
                  this.$store.commit("setFileCardDeactivated");
                }, this.transitionDuration);
              }, 10);
            }, 10);
          })
          .catch(() => {
            // can happen when navigating to UVCs project cards
          });
      });
    },

    getCoordinates () {
      const vCardPlaceholder = this.$el.parentElement;
      if (vCardPlaceholder) {
        const rect = vCardPlaceholder.getBoundingClientRect();
        this.vCardPlaceholderTop = rect.top;
        this.vCardPlaceholderLeft = rect.left;
        this.vCardPlaceholderHeight = rect.height;
        this.vCardPlaceholderWidth = rect.width;

        const vCardContainer = this.$refs.vCardContainer;
        if (vCardContainer) {
          return new Promise(resolve => {
            const poll = () => {
              if (
                vCardContainer &&
                Math.round(vCardContainer.style.getPropertyValue("--v-card-placeholder-top").replace("px", "")) === Math.round(this.vCardPlaceholderTop) &&
                Math.round(vCardContainer.style.getPropertyValue("--v-card-placeholder-left").replace("px", "")) === Math.round(this.vCardPlaceholderLeft) &&
                Math.round(vCardContainer.style.getPropertyValue("--v-card-placeholder-height").replace("px", "")) === Math.round(this.vCardPlaceholderHeight) &&
                Math.round(vCardContainer.style.getPropertyValue("--v-card-placeholder-width").replace("px", "")) === Math.round(this.vCardPlaceholderWidth)
              ) {
                resolve();
              } else {
                setTimeout(poll, 10);
              }
            };
            poll();
          });
        } else {
          return Promise.reject(new Error("vCardContainer not found."));
        }
      } else {
        throw new Error("Could not get coordinates of fileCard placeholder.");
      }
    }

  }
};
</script>

<style lang="css" scoped>

.v-card-container {
  pointer-events: none;
  height: 100%;
  width: 100%;
  padding: 0;
}

.v-card {
  border-radius: 4px;
  pointer-events: auto;
  height: 100%;
  width: 100%;
  max-width: 660px;
  margin: auto;
}

.v-card-container--active1 {
  z-index: var(--activating-file-card-z-index);
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 85px);
  padding: 24px;
}

.v-card-container--active2 {
}

.v-card-container--active3 {
}

.v-card-container--active1 .v-card {
  position: absolute;
  top: calc(var(--v-card-placeholder-top) - var(--file-grid-top) + 8px);
  left: calc(var(--v-card-placeholder-left) - var(--file-grid-left) + 8px);
  height: calc(var(--v-card-placeholder-height) - 16px);
  width: calc(var(--v-card-placeholder-width) - 16px);
}

.v-card-container--active2 .v-card {
  transition-property: transform, padding, height, width;
  transition-duration: var(--file-card-activate-duration);
}

.v-card-container--active3 .v-card {
  transform: translate(calc(var(--target-rect-left) - var(--v-card-placeholder-left) - 8px), calc(var(--target-rect-top) - var(--v-card-placeholder-top) - 8px));
  height: var(--target-rect-height);
  width: var(--target-rect-width);
}

.v-card-container--active4 .v-card {
  transition-duration: 0s;
}

.v-card-container--active5 .v-card {
  transform: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: relative;
}

.code-container {
  padding: 12px;
  height: calc(100% - var(--file-card-actions-height));
}

pre {
  font-size: 0.9em;
}

.v-card-actions {
  height: var(--file-card-actions-height);
  width: 100%;
  padding: 12px 12px 12px 18px;
  background-color: var(--primary-color-lighten1);
}

.v-card-container--primed .v-card__actions {
  transition-duration: var(--file-card-activate-duration);
  transition-property: background-color, box-shadow;
}

.v-card-container--active3 .v-card__actions {
  background-color: white;
  box-shadow: 0 -5px 5px -3px rgba(0,0,0,.2);
}

</style>
