<template>
  <project-list title="UVCs">
    <v-col cols="6" sm="4" md="3">
      <project-card
        title="Create new"
        icon="mdi-plus"
        dark
        @click="addUVC"
      />
    </v-col>
    <v-col
      v-for="uvc in uvcs"
      :key="uvc.id"
      cols="6" sm="4" md="3"
    >
      <project-card
        data-test="uvc-card"
        :title="uvc.name || 'uvc'"
        icon="mdi-compare-horizontal"
        has-actions
        @click="editUVC(uvc)"
        @destroy="onDestroy(uvc)"
      />
    </v-col>
  </project-list>
</template>

<script>
import ProjectList from "@/components/project-list.vue";
import ProjectCard from "@/components/project-card.vue";
import { validateLicenseRequest } from "@/utils/license-validator-VITE_APP_PLATFORM";

export default {
  name: "project-list-for-uvcs",
  components: {
    ProjectList,
    ProjectCard
  },
  props: {
    uvcs: {
      type: Array,
      required: true
    }
  },
  methods: {
    addUVC: function () {
      this.$router.push({ name: "uvcNew" })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    },
    editUVC: function (uvc) {
      this.$router.push({ name: "uvcEdit", params: { id: uvc.id } })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    },
    onDestroy (uvc) {
      const event = {
        type: "destroyed",
        assets: uvc.destroyedLicenseAssets
      };
      this.$store.getters.user.inAppAssets().then(inAppAssets => {
        validateLicenseRequest(this.$store.getters.user.l, inAppAssets, event).then(result => {
          if (!result.error) {
            uvc.destroy();
          }
        });
      });
    }
  }
};
</script>
