<template>
  <standard-layout>
    <section class="banner">
      <img src="/img/wave.webp" class="banner-wave"/>
      <v-container class="align-center justify-center fill-height">
        <div class="d-flex align-center justify-center">
          <div class="banner__message">
            <div>
              <div class="text-h4 font-weight-bold">Methodology Focused Code Generator for UVM</div>
              <div class="subheading">Easily generate industry-proven UVCs, environments and testbenches that instantiate with just a click.</div>
            </div>
            <v-btn
              color="secondary"
              class="text-h6 mt-12 ml-0 elevation-9"
              height="75px"
              width="250px"
              @click="addUVC()"
            >
              GENERATE MY UVC
            </v-btn>
          </div>
          <div class="ml-12 logo-background elevation-5 hidden-sm-and-down">
            <img src="/img/logo.svg" class="logo ma-12 hidden-sm-and-down" />
          </div>
        </div>
      </v-container>
    </section>

    <section class="benefits py-10 text-justify">
      <v-container style="max-width: 750px;" grid-list-lg>
        <v-row class="wrap align-space-between justify-center">
          <v-col cols="12" md="6" sm="9">
            <v-card flat hover>
              <v-card-text class="text-center">
                <v-icon class="text-primary">mdi-flash</v-icon>
              </v-card-text>
              <v-card-title primary-title class="layout justify-center text-center">
                <div class="text-h5">Verify more, faster</div>
              </v-card-title>
              <v-card-text v-if="true">
                Verify more features and meet aggressive timelines while preserving a rock solid verification infrastructure. With reactive UVM code generation, developing with UVMGen is a breeze.
              </v-card-text>
              <v-card-text v-else>
                Whether you're building for the first time or rebuilding a rotting testbench, developing with UVMGen is super fast. Meet aggressive timelines with a rock solid verification infrastructure. Timelines won't derail you from following the methodology. Contractors won't need to waste time figuring out your proprietary methodology. UVMGen frees your mind to think only of your business logic. With an intuitive UI, input is easy and output reacts as you type. The extensive and particular aspects of the methodology are handled automatically right before your eyes. Verification development becomes easy so stimulus, prediction and coverage get the love they deserve.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="9" md="6">
            <v-card flat hover>
              <v-card-text class="text-center">
                <v-icon x-large class="text-primary">mdi-widgets</v-icon>
              </v-card-text>
              <v-card-title primary-title class="layout justify-center">
                <div class="text-h5 text-center">Actualize the UVM promise</div>
              </v-card-title>
              <v-card-text v-if="true">
                Reuse at a click and scale with ease. UVMGen is a living model of UVM best practices and top conference paper techniques. Stay on the rails, making testbench rewrites a thing of the past.
              </v-card-text>
              <v-card-text v-else>
                UVMGen is a living model of best practices. Seamless vertical and horizontal reuse, abstracted stimulus and block-level self-checking is now a reality. UVMGen generates industry-proven DV collateral to ensure your testbench stays on the rails and scales with ease over the long term. No more infrastructure rot from seemingly innocuous manual shortcuts. With the best DV practices across the industry distilled and encoded into the UVMGen code generator you can stand on the shoulders of DV experts. Capitalize on the UVM promise. Increase your verification productivity and confidence.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="9" md="6">
            <v-card flat hover>
              <v-card-text class="text-center">
                <v-icon x-large class="text-primary">mdi-school</v-icon>
              </v-card-text>
              <v-card-title primary-title class="layout justify-center">
                <div class="text-h5 text-center">Newbies become gurus</div>
              </v-card-title>
              <v-card-text v-if="true">
                UVMGen uses only the best, industry-proven coding practices which surpass anyones hand-coding capabilities. With the generator, new engineers can learn UVM at their own pace, yet their VIP development capability is instantly supercharged.
              </v-card-text>
              <v-card-text v-else>
                UVMGen allows engineers to learn the UVM methodology at their own pace. The generator is a learning tool for industry and higher education as much as it is a solution for the largest silicon projects. Taken all at once, UVM is daunting and requires a computer science discipline rare among most DV engineers. Mistakes which can risk testbench longevity or verification confidence are prevented against and engineers are provided the time to learn from reactive output while still being productive. UVMGen makes the UVM methodology consumable and enjoyable.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="9" md="6">
            <v-card flat hover>
              <v-card-text class="text-center">
                <v-icon x-large class="text-primary">mdi-currency-usd</v-icon>
              </v-card-text>
              <v-card-title primary-title class="layout justify-center">
                <div class="text-h5 text-center">Reduce costs</div>
              </v-card-title>
              <v-card-text v-if="true">
                Verification at the block level improves debug productivity and saves on compute costs. With UVMGen, you are unconstrained by the weight of development enabling you to verify designs at the optimal granularity.
              </v-card-text>
              <v-card-text v-else>
                Aggressive timelines precipitate shortcuts with long term implications. Verification of large blocks when verification at a finer granularity is more appropriate is one such short cut. This has large scale impacts on engineer productivity and simulation compute costs. Large environments stall development, cause developer wait times and cause large compute costs due to heavy weight simulations. UVMGen makes vertical reuse quick and easy, eliminating barriers and opening design verification to appropriate levels of granularity.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="how-it-works py-10 text-justify">
      <v-container>
        <v-row column align="center" justify="center">
          <div class="text-h5">How it works</div>
        </v-row>
        <v-row wrap align="center" justify="center">
          <v-col class="content-flex" cols="12" sm="8" md="10" lg="8" xl="6">
            <v-container grid-list-xl>
              <v-row wrap align-space-between justify-center>
                <v-col class="mt-3" cols="12" md="7" lg="6">
                  <strong>Step 1: Follow the easy input editors.</strong>
                  <div class="mt-4 px-3 py-4 how-it-works__step-container">
                    <v-card>
                      <v-list lines="three">
                        <v-list-item title="">
                          <template v-slot:prepend>
                            <v-avatar>
                              <v-icon size="large" class="text-primary">mdi-compare-horizontal</v-icon>
                            </v-avatar>
                          </template>

                          <v-list-item-title>UVC code generator</v-list-item-title>
                          <v-list-item-subtitle>Name, pins, BFM, API sequences, etc.</v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </v-card>
                    <div class="line-of-arrow-container pt-2">
                      <div class="line-of-arrow" />
                    </div>
                    <div class="head-of-arrow-container">
                      <div class="head-of-arrow" />
                    </div>
                    <v-card class="mt-1">
                      <v-list lines="three">
                        <v-list-item title="">
                          <template v-slot:prepend>
                            <v-avatar>
                              <v-icon size="large" class="text-primary">mdi-developer-board</v-icon>
                            </v-avatar>
                          </template>

                          <v-list-item-title>Environment code generator</v-list-item-title>
                          <v-list-item-subtitle>Select UVCs and environments, code virtual sequences.</v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </v-card>
                    <div class="line-of-arrow-container pt-2">
                      <div class="line-of-arrow" />
                    </div>
                    <div class="head-of-arrow-container">
                      <div class="head-of-arrow" />
                    </div>
                    <v-card class="mt-1">
                      <v-list lines="three">
                        <v-list-item title="">
                          <template v-slot:prepend>
                            <v-avatar>
                              <v-icon size="large" class="text-primary">mdi-view-array</v-icon>
                            </v-avatar>
                          </template>

                          <v-list-item-title>Testbench code generator</v-list-item-title>
                          <v-list-item-subtitle>Connect interfaces to DUT pins.</v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </div>
                </v-col>
                <v-col class="mt-3" xs0 md1>
                </v-col>
                <v-col class="mt-3" cols="12" md="4" lg="5">
                  <strong>Step 2: Download.</strong>
                  <div class="mt-3 px-3 py-4 center-container" style="height: 100%; min-height: 312px;">
                    <div class="center-content">
                      <v-btn
                        style="width: 128px; height: 128px"
                        icon
                        color="accent"
                      >
                        <v-icon size="54">mdi-cloud-download</v-icon>
                      </v-btn>
                      <div class="line-of-arrow-container pt-2">
                        <div class="line-of-arrow" />
                      </div>
                      <div class="head-of-arrow-container">
                        <div class="head-of-arrow" />
                      </div>
                      <div class="folder-container">
                        <div class="folder">
                          <v-icon size="128" color="primary">mdi-folder</v-icon>
                          <span style="position: relative;">
                            <span style="position: absolute; top: 28px; left: -48px; color: white;">
                              .zip
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="false" class="demo-video py-10 text-justify">
      <v-container>
        <v-row class="wrap align-center justify-center">
          <v-col class="content-flex" cols="12" md="10" lg="7" xl="6">
            <div class="aspect-ratio">
              <iframe
                src="https://www.youtube.com/embed/n0h3iGsQH1Y?rel=0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
              ></iframe>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="features py-12" >
      <v-container>
        <v-row justify="center">
          <v-col class="align-center justify-center">
            <div class="text-h5 text-center">Start building</div>
            <div class="subheading text-center my-3">
              Generate individual components and compose them.
            </div>
          </v-col>
        </v-row>
        <v-row wrap align="center" justify="center">
          <v-col cols="12" sm="7" md="4">
            <v-card>
              <template v-slot:prepend>
                <v-avatar
                  class="ma-2"
                  size="56"
                >
                  <v-icon medium class="text-primary">mdi-compare-horizontal</v-icon>
                </v-avatar>
              </template>
              <template v-slot:title>
                <div class="text-h5">UVCs</div>
              </template>
              <v-card-text class="body-2" style="height: 120px;">
                {{ config.uvcMarketingParagraph }}
              </v-card-text>
              <v-card-actions class="pa-3">
                <v-btn
                  class="mt-4 my-2 bg-primary"
                  size="large"
                  block
                  @click="addUVC()"
                >
                  GENERATE
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="7" md="4">
            <v-card>
              <template v-slot:prepend>
                <v-avatar
                  class="ma-2"
                  size="56"
                >
                  <v-icon medium class="text-primary">mdi-developer-board</v-icon>
                </v-avatar>
              </template>
              <template v-slot:title>
                <div class="text-h5">Environments</div>
              </template>
              <v-card-text class="body-2" style="height: 120px;">
                {{ config.envMarketingParagraph }}
              </v-card-text>
              <v-card-actions class="pa-3">
                <v-btn
                  class="mt-4 my-2 bg-primary"
                  size="large"
                  block
                  @click="addEnv()"
                >
                  GENERATE
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="7" md="4">
            <v-card>
              <template v-slot:prepend>
                <v-avatar
                  class="ma-2"
                  size="56"
                >
                  <v-icon medium class="text-primary">mdi-view-array</v-icon>
                </v-avatar>
              </template>
              <template v-slot:title>
                <div class="text-h5">Testbenches</div>
              </template>
              <v-card-text class="body-2" style="height: 120px;">
                {{ config.tbMarketingParagraph }}
              </v-card-text>
              <v-card-actions class="pa-3">
                <v-btn
                  class="mt-4 my-2 bg-primary"
                  size="large"
                  block
                  @click="addTb()"
                >
                  GENERATE
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row column align-center justify-center v-if="false">
          <div class="subheading text-justify mt-3">
            You can choose to add your buisiness logic in the UVMGen UI or with your text editor in a sub-class. Come back to the UVMGen UI later to react to changes in your design. With UVMGen, there is no need to eject and go it alone. UVMGen continues to generate new verification collateral over the life of you design.
          </div>
        </v-row>
      </v-container>
    </section>

    <section class="app-preview" v-if="false">
      <v-img
        src="/img/app-in-action.png"
        height="650"
        contain
      />
    </section>

    <section class="axioms" v-if="false">
      <v-row
        align-center
        justify-center
        fill-height
      >
        <v-col class="content-flex" xs12>
          <v-row wrap align-center justify-center class="my-5">
            <v-col xs12 sm4 order-sm2 text-center>
              <!--<v-img-->
              <!--  src="/img/ben-on-laptop.jpg"-->
              <!--  height="400"-->
              <!--/>-->
              <v-icon size="200">playlist_add_check</v-icon>
            </v-col>
            <v-col cols="12" sm="8" order-sm1>
              <v-card class="elevation-0 transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="text-h5">Axioms</div>
                </v-card-title>
                <v-card-text>
                  <div class="mb-3">
                    These are some of the high-level UVM methodology principles which UVMGen promotes and follows.
                  </div>
                  <ul>
                    <li>
                      Every DUT pin is interacted with via a UVC transactor. Even single pins like clocks, driving an interrupt signal, waiting for an interrupt signal, side band signals, each have their own UVC transactor instance.
                    </li>
                    <li>
                      The meaning of pin toggles are abstracted into sequence items, which are consumed by or constructed by UVC transactors.
                    </li>
                    <li>
                      There are no "free running" BFMs. If needed, send sequences in a forever loop, but don't give away your ability to synchronize your stimulus with other sequences in the future.
                    </li>
                    <li>
                      Sequence items are composed into sequences. Sequences are composed into virtual sequences.
                    </li>
                    <li>
                      All info needed by a sequence must come from the test, config, parent sequence or a sequence response (no reaching across into other components).
                    </li>
                  </ul>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>

    <section v-if="false" class="sponsors py-10 text-justify">
      <v-row wrap align-center justify-center>
        <v-col class="content-flex" cols="12" md="8" xl="6">
          <v-container grid-list-lg>
            <v-row column align-center justify-center>
              <v-col>
                <!--<div class="text-h5">Gold sponsors</div>-->
                <div class="text-h5">Partnership</div>
              </v-col>
              <v-col>
                <div class="subheading mt-4 text-center">
                  UVMGen is looking to partner with an Austin company ready to benefit from an on-premise version of the UVMGen tool. An on-site support engineer will be provided to get your verification development off the ground quickly.
                </div>
              </v-col>
              <v-col>
                <v-btn
                  class="mt-5"
                  large
                  color=primary
                  href="mailto:feedback@uvmgen.com?subject=Partership inquiry"
                >
                  Partner with UVMGen
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="false" column align-center justify-center>
              <v-col>
                <!--<div class="text-h5">Gold sponsors</div>-->
                <div class="text-h5">Sponsors</div>
              </v-col>
              <v-col>
                <div class="subheading mt-4 text-center">
                  Want the DV community to know your company or product? Love using UVMGen and want to become a backer?
                </div>
              </v-col>
              <v-col>
                <v-btn
                  class="mt-5"
                  large
                  color=primary
                  href="https://www.patreon.com/uvmgen"
                >
                  Support UVMGen
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </section>

    <section class="about-us text-justify" v-if="false">
      <v-row
        align-center
        justify-center
        fill-height
      >
        <v-col class="content-flex" xs12>
          <v-row wrap align-center justify-center class="my-5">
            <v-col xs12 sm4 text-center>
              <!--<v-img-->
              <!--  src="/img/ben-on-laptop.jpg"-->
              <!--  height="400"-->
              <!--/>-->
              <v-icon size="200">group</v-icon>
            </v-col>
            <v-col xs12 sm8>
              <v-card class="elevation-0 transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="text-h5">Who we are</div>
                </v-card-title>
                <v-card-text>
                  We are UVMGen LLC. Top-tier DV engineering with experience at Intel, Qualcomm, Samsung and others.
                  <br /><br />
                  In all but the smallest projects, we've seen testbench infrastructures suffer from engineers unable to give due attention to methodology complexities within the aggressive timelines indicative of our industry. Testbenches start to rot and are difficult to enhance without jumping through some hoops which further adds to the decay. Hindsight then become 20/20, but its too late to do much about it.
                  <br /><br />
                  The result is that UVM is just not being used in the field as it was intended and therefore the industry is not fully capitalizing on its benefits. This has large costs for a company as well as for job satisfaction of the engineers maintaining them.
                  <br /><br />
                  We've successfully automated UVM methodology for large scale production projects and now we're making this type of technology available to the rest of the world. We feel very strongly automation is required for the hardware industry to level-up its productivity, actualize the UVM promise and enable more features at a smaller cost.
                  <br /><br />
                  We love the power of what software combined with community can do. We encourage your feedback to make this an extremely powerful solution.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </section>

    <section class="contact-info">
      <v-container justify-center>
        <v-row wrap class="my-12 justify-center fill-height">
          <v-col class="context-flex" cols="12" sm="7" md="3">
            <v-card class="elevation-0 transparent">
              <v-card-title primary-title class="layout text-white text-center">
                <div class="text-h5">Company info</div>
              </v-card-title>
              <v-card-text class="mt-4 body-1 text-white">
                UVMGen LLC aims at solving ubiquitous verification challenges with modern software. Founded by Benjamin Delsol, a verification engineer with a passion for efficiency, software and community. Speeding up the technological revolution while having fun is UVMGens primary purpose.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="mt-8 mt-md-0" cols="12" sm="7" md="5" offset-sm="1">
            <v-card class="elevation-0 transparent">
              <v-card-title primary-title class="layout text-white text-center">
                <div class="text-h5">Contact us</div>
              </v-card-title>
              <v-card-text class="mt-4 body-1 text-white">
                We want to hear from you.
              </v-card-text>
              <v-list class="transparent text-white">
                <v-list-item title="">
                  <template v-slot:prepend>
                    <v-icon class="text-white" icon="mdi-map-marker"></v-icon>
                  </template>
                  <v-list-item-title class="text-white">1700 S Lamar Blvd #338, Austin, TX 78704</v-list-item-title>
                </v-list-item>
                <v-list-item title="">
                  <template v-slot:prepend>
                    <v-icon class="text-white" icon="mdi-email"></v-icon>
                  </template>
                  <v-list-item-title class="text-white">feedback@uvmgen.com</v-list-item-title>
                </v-list-item>
                <v-list-item title="" href="https://twitter.com/uvmgen_com">
                  <template v-slot:prepend>
                    <v-icon class="text-white" icon="mdi-twitter"></v-icon>
                  </template>
                  <v-list-item-title class="text-white">@uvmgen_com</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-footer
      class="secondary darken-2"
      height="auto"
    >
      <div class="d-flex justify-center" style="width: 100%;">
        &copy; 2018 UVMGen LLC
      </div>
    </v-footer>
  </standard-layout>
</template>

<script>
import StandardLayout from "@/components/standard-layout.vue";
import config from "@/config";

export default {
  name: "home",
  components: {
    StandardLayout
  },
  data () {
    return {};
  },
  beforeCreate () {
    document.documentElement.className = "home";
  },
  created () {
    this.config = config;
  },
  inject: ["$dialog"],
  methods: {
    addUVC () {
      this.$router.push({ name: "uvcNew" })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    },
    addEnv () {
      this.$store.commit("setOnPremiseDialogTitle", "Environments available on Desktop");
      this.$store.commit("setOnPremiseDialogParagraph", "");
      // this.$store.commit("setOnPremiseDialogParagraph", config.envMarketingParagraph);
      this.$dialog.override("on-premise-license");
    },
    addTb () {
      this.$store.commit("setOnPremiseDialogTitle", "Testbenches available on Desktop");
      this.$store.commit("setOnPremiseDialogParagraph", "");
      // this.$store.commit("setOnPremiseDialogParagraph", config.tbMarketingParagraph);
      this.$dialog.override("on-premise-license");
    }
  }
};
</script>

<style lang="css">

:root {
  --banner-height: 550px;
}

@media all and (min-width: 0px) and (max-width: 960px) {
  :root {
    --banner-height: 400px;
  }
}

</style>

<style lang="css" scoped>

section.banner {
  position: relative;
  height: var(--banner-height);
  max-width: none;
  background: rgb(255,235,59);
  background: linear-gradient(130deg, rgba(255,235,59,1) 15%, rgba(251,140,0,1) 90%);
  overflow: hidden;
}

.banner-wave {
  position: absolute;
  bottom: 0px;
  right: 0px;
  opacity: 0.35;
  max-height: 100%;
  max-width: 100%;
}

.logo-background {
  background-color: var(--secondary-color);
  z-index: 0;
  border-radius: 3%;
  max-width: 300px;
}

.logo {
  padding-left: 5px;
  width: 200px;
  height: 200px;
}

section.banner .content-flex{
  max-width: 1200px;
}

section.banner .banner__message {
  max-width: 550px;
}

@media all and (min-width: 0px) and (max-width: 960px) {
  section.banner .banner__message {
    max-width: none;
  }
}
section.benefits {
  background-color: white;
}

section.benefits .content-flex {
  max-width: 750px;
}

section.benefits .v-card {
  height: 100%;
  min-height: 250px;
}

section.benefits .v-card__title {
  padding-top: 5px;
}

section.benefits .v-icon {
  font-size: 40px;
}

section.how-it-works {
  background-color: var(--background-color);
}

.how-it-works__step-container {
  border-style: solid;
  border-width: 1px;
  border-color: #888888;
}

.line-of-arrow-container {
  width: 100%;
  max-height: 30px;
  text-align: center;
}

.line-of-arrow {
  display: inline-block;
  height: 31px;
  width: 0px;
  border-style: solid;
  border-width: 2px;
  border-color: #555555;
}

.head-of-arrow-container {
  max-height: 22px;
  width: 100%;
  text-align: center;
}

.head-of-arrow {
  display: inline-block;
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 16px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
}

.folder-container {
  width: 100%;
  text-align: center;
}

.folder {
  width: 100%;
  display: inline-block;
}

section.demo-video {
  background-color: var(--primary-color);
}

section.demo-video .content-flex {
  max-width: 1561px;
}

section.demo-video .aspect-ratio {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

section.demo-video .aspect-ratio iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

section.features {
  /* background-color: var(--background-color); */
  background-color: white;
}

section.features .content-flex {
  max-width: 1000px;
}

section.features .grid-list-xl {
  padding: 24px 0;
}

section.features .v-card h5 {
  margin-left: 12px;
}

section.features .v-avatar {
  background-color: #e0e0e0;
}

section.features .v-card__text {
  min-height: 150px;
  padding: 0px 10px 12px 26px;
}

section.app-preview {
  background: rgb(255,235,59);
  background: linear-gradient(130deg, rgba(255,235,59,1) 15%, rgba(251,140,0,1) 90%);
}

section.axioms {
  background-color: white;
}

section.axioms .content-flex {
  max-width: 800px;
}

section.sponsors {
  background-color: white;
}

section.about-us {
  background-color: var(--background-color);
}

section.about-us .content-flex {
  max-width: 800px;
}

section.contact-info {
  background-color: var(--secondary-color);
}

section.contact-info .content-flex {
  max-width: 800px;
}

</style>
