<template>
  <standard-layout
    hasContainer
    dark
  >
    <project-list title="Projects">
      <v-col cols="6" sm="4" md="3">
        <project-card
          title="UVCs"
          icon="mdi-compare-horizontal"
          @click="navToUvcs"
        />
      </v-col>
      <v-col v-if="$store.getters.isDesktop" cols="6" sm="4" md="3">
        <project-card
          title="Environments"
          icon="mdi-developer-board"
          @click="navToEnvs"
        />
      </v-col>
      <v-col v-if="$store.getters.isDesktop" cols="6" sm="4" md="3">
        <project-card
          title="Testbenches"
          icon="mdi-view-array"
          @click="navToTbs"
        />
      </v-col>
    </project-list>
  </standard-layout>
</template>

<script>
import StandardLayout from "@/components/standard-layout.vue";
import ProjectList from "@/components/project-list.vue";
import ProjectCard from "@/components/project-card.vue";

export default {
  components: {
    StandardLayout,
    ProjectList,
    ProjectCard
  },
  data () {
    return {};
  },
  methods: {
    navToUvcs () {
      this.$router.push({ name: "uvcs" })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    },
    navToEnvs () {
      this.$router.push({ name: "envs" })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    },
    navToTbs () {
      this.$router.push({ name: "tbs" })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    }
  }
};
</script>

<style lang="css" scoped>
</style>
