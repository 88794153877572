<template>
  <collection-editor
    data-test="bfm-collection-editor"
    :item-type="itemType"
    item-name-property="type"
    list-item-icon="mdi-alpha-b-box"
    :neutral-items="uvc.bfms"
    :add-button-is-disabled="baseClassCollection.length <= 1"
    :add-button-is-fab-condition="baseClassCollection.filter(option => !option.header && !option.divider).length > 0"
    @activate="$emit('activate', $event)"
  >
    <template v-slot:list-item-subtitle="{ klass }">
      <v-list-item-subtitle v-if="klass.superclass">
        Extends {{ klass.superclass.type }}
      </v-list-item-subtitle>
    </template>
    <template v-slot:create-card="{ selectedItem, onSave, onCancel }">
      <class-create-card
        :selected-item="selectedItem"
        :on-save="onSave"
        :on-cancel="onCancel"
        :item-type="itemType"
        new-item-hint="Type BFM name"
        :new-item-placeholder="newItemPlaceholder"
        :create-new-item="createNewItem"
        :baseClassCollection="baseClassCollection"
        @activate="$emit('activate', $event)"
      />
    </template>
    <template v-slot:item-editor="{ selectedItem }">
      <class-editor
        :root-project="uvc"
        :klass="selectedItem"
        instance-variables-are-advanced
        new-instance-variables-placeholder="int num_pending_items"
        :new-subroutine-placeholder="`virtual task do_it(${uvc.sequenceItem.type} item);\n  // ...\nendtask`"
      />
    </template>
  </collection-editor>
</template>

<script>
import CollectionEditor from "@/components/collection-editor.vue";
import ClassCreateCard from "@/components/class-create-card.vue";
import ClassEditor from "@/components/class-editor.vue";

export default {
  components: {
    CollectionEditor,
    ClassCreateCard,
    ClassEditor
  },
  props: {
    uvc: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    };
  },
  created () {
    this.itemType = "bfm";
  },
  computed: {
    baseClassCollection () {
      let options = [];
      // [TODO] re-enable once supported: https://github.com/vuetifyjs/vuetify/issues/15721
      // options = options.concat([{ header: true, title: "BFM classes" }]);
      options = options.concat(this.uvc.bfms);
      return options;
    }
  },
  methods: {
    createNewItem (identifier, baseClass) {
      return baseClass.createFormSubclass({ type: identifier });
    },
    newItemPlaceholder (baseClass = null) {
      return baseClass
        ? baseClass.type.replace(/_bfm.*_c$/, "_xyz_bfm_c")
        : `${this.uvc.name}_master_xyz_bfm_c`;
    }
  }
};
</script>

<style lang="css" scoped>
</style>
