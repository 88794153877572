// Warning: Lazy loading routes could lead to failures since you have set workbox to skipWaiting.
//   https://stackoverflow.com/questions/51715127/what-are-the-downsides-to-using-skipwaiting-and-clientsclaim-with-workbox
// To rebuild a component because component is reused and lifecycle hooks aren't firing...
//   https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
import setDocumentTitle from "@/utils/set-document-title";
import NotFound from "@/views/not-found.vue";
import Blog from "@/views/blogs/show.vue";
import CheatSheet from "@/views/cheat-sheets/show.vue";
import UVCs from "@/views/projects/uvcs/index.vue";
import UVCsEdit from "@/views/projects/uvcs/edit.vue";

/*
 * Disabling dynamic import of components due to https://github.com/vitejs/vite/issues/11804
const NotFound = () => import("./views/not-found.vue");
const Blog = () => import("./views/blogs/show.vue");
const CheatSheet = () => import("./views/cheat-sheets/show.vue");
const UVCs = () => import("./views/projects/uvcs/index.vue");
const UVCsEdit = () => import("./views/projects/uvcs/edit.vue");
*/

const UVC_DESCRIPTION = "UVM Verification Components translate your interfaces pin-level protocol to and from abstracted constrained random sequence items.";

export default [
  {
    name: "blog",
    path: "/blogs/:id",
    component: Blog,
    meta: {},
    beforeEnter: (to, from, next) => {
      const blog = __APP_MARKDOWN_INFO__.blogs[to.params.id]; // eslint-disable-line no-undef
      setDocumentTitle(blog.title + " - UVMGen", blog.description, blog.jsonLd);
      next();
    }
  },
  {
    name: "cheatSheet",
    path: "/cheat-sheets/:id",
    component: CheatSheet,
    meta: {},
    beforeEnter: (to, from, next) => {
      const cheatSheet = __APP_MARKDOWN_INFO__["cheat-sheets"][to.params.id]; // eslint-disable-line no-undef
      setDocumentTitle(cheatSheet.title + " - UVMGen", cheatSheet.description, cheatSheet.jsonLd);
      next();
    }
  },
  {
    name: "uvcs",
    path: "/projects/uvcs",
    component: UVCs,
    meta: { },
    beforeEnter: (to, from, next) => {
      setDocumentTitle("UVCs - UVMGen", UVC_DESCRIPTION);
      next();
    }
  },
  {
    name: "uvcNew",
    path: "/projects/uvcs/new",
    meta: { isAToolRoute: true }
  },
  // [TODO] create a seperate route for read-only and requireAuth for uvcEdit.
  {
    name: "uvcEdit",
    path: "/projects/uvcs/:id/edit",
    component: UVCsEdit,
    meta: { isAToolRoute: true },
    beforeEnter: (to, from, next) => {
      setDocumentTitle("UVC - UVMGen", UVC_DESCRIPTION);
      next();
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound
  }
];
