<template>
  <collection-editor
    data-test="sequence-collection-editor"
    :item-type="itemType"
    item-name-property="type"
    :is-neutral="false"
    :hasMaster="rootProject.hasMaster"
    :hasSlave="rootProject.hasSlave"
    :masterItems="masterSequences"
    :slaveItems="slaveSequences"
    @activate="$emit('activate', $event)"
  >
    <template v-slot:create-card="{ selectedItem, onSave, onCancel }">
      <class-create-card
        :selected-item="selectedItem"
        :on-save="onSave"
        :on-cancel="onCancel"
        :item-type="itemType"
        new-item-hint="Type sequence name"
        :new-item-placeholder="newItemPlaceholder"
        :create-new-item="createNewItem"
        :baseClassCollection="baseClassCollection"
        @activate="$emit('activate', $event)"
      />
    </template>
    <template v-slot:item-editor="{ selectedItem }">
      <class-editor
        :root-project="rootProject"
        :klass="selectedItem"
        :is-neutral="false"
        instance-variables-are-advanced
        subroutines-are-advanced
        :new-instance-variables-placeholder="`bit x\nint y`"
        :new-subroutine-placeholder="`virtual task do_it();\n  // ...\nendtask`"
        @add-policy="$emit('add-policy', $event)"
      />
    </template>
  </collection-editor>
</template>

<script>
import CollectionEditor from "@/components/collection-editor.vue";
import ClassCreateCard from "@/components/class-create-card.vue";
import ClassEditor from "@/components/class-editor.vue";
import UvmSequence from "@/models/uvm/uvm-sequence";
import get from "lodash.get";

export default {
  components: {
    CollectionEditor,
    ClassCreateCard,
    ClassEditor
  },
  props: {
    rootProject: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    };
  },
  created () {
    this.itemType = "sequence";
  },
  computed: {
    masterSequences () {
      return get(this.rootProject.package, "masterSequences", []);
    },
    slaveSequences () {
      return get(this.rootProject.package, "slaveSequences", []);
    },
    uvmSequence () {
      return this.rootProject.sequenceItem && UvmSequence.singleton(this.rootProject.sequenceItem);
    },
    baseClassCollection () {
      const sequenceOptions = [];
      if (this.uvmSequence) {
        // [TODO] re-enable once supported: https://github.com/vuetifyjs/vuetify/issues/15721
        // sequenceOptions.push({ header: "Sequences" });
        sequenceOptions.push(this.uvmSequence);
        // sequenceOptions.push({ divider: true });
      }
      return sequenceOptions
        // .concat([{ header: "Master sequences" }])
        .concat(get(this.rootProject.package, "masterSequences", []))
        // .concat([{ divider: true }, { header: "Slave sequences" }])
        .concat(get(this.rootProject.package, "slaveSequences", []));
    }
  },
  methods: {
    createNewItem (identifier, baseClass, master) {
      return baseClass.createFormSubclass({ type: identifier, master });
    },
    newItemPlaceholder (baseClass, isMaster) {
      return `${this.rootProject.name}_${isMaster ? "master_" : "slave_"}xyz_sequence_c`;
    }
  }
};
</script>

<style lang="css" scoped>
</style>
