<template>
  <div v-if="cart && cart.readonlyCart && cart.result">
    <code-area
      label="Function"
      :placeholder="cart.readonlyCart.priceAdjustmentFunctionPlaceholder"
      :num-rows="5"
      :value="priceAdjustmentFunctionBuffer"
      @input="priceAdjustmentFunctionBuffer = $event"
      hint=""
    />

    <div class="d-flex justify-end">
      <div>
        <v-btn
          color="primary"
          block
          :disabled="priceAdjustmentFunctionBuffer === cart.readonlyCart.priceAdjustmentFunction"
          @click="cart.readonlyCart.immediateUpdate({ priceAdjustmentFunction: priceAdjustmentFunctionBuffer })"
        >
          PUBLISH FUNCTION
        </v-btn>
      </div>
    </div>

    <div>
      Order type: {{ cart.orderType || "initial" }}
    </div>
    <div>
      License duration: {{ cart.result.licenseDurationInMonths }} months
    </div>

    <v-row
      class="mt-8"
      no-gutters
    >
      <v-col>
        <v-row
          class="mx-4"
        >
          <v-col>
          </v-col>
          <v-col>
            <i>
              Monthly (includes global and custom discounts)
            </i>
          </v-col>
          <v-col>
            <i>
              Total (before tax)
            </i>
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-col>
            Original price
          </v-col>
          <v-col>
            {{ $stripeManager.formatPrice(cart.result.monthlyPriceWithGlobalAndCustomDiscount) }}
          </v-col>
          <v-col>
            {{ $stripeManager.formatPrice(cart.result.priceWithGlobalAndCustomDiscount) }}
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-col>
            Discount
          </v-col>
          <v-col>
            -{{ $stripeManager.formatPrice(cart.result.monthlyPriceWithGlobalAndCustomDiscount - monthlyPriceWithGlobalCustomAndRenewalDiscount) }}
          </v-col>
          <v-col>
            -{{ $stripeManager.formatPrice(cart.result.priceWithGlobalAndCustomDiscount - cartPriceTotal) }}
          </v-col>
        </v-row>
        <v-row class="mx-4">
          <v-col>
            New price
          </v-col>
          <v-col>
            {{ $stripeManager.formatPrice(monthlyPriceWithGlobalCustomAndRenewalDiscount) }}
          </v-col>
          <v-col>
            {{ $stripeManager.formatPrice(cartPriceTotal) }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CodeArea from "@/components/code-area.vue";

export default {
  props: {
    license: {
      type: Object,
      required: true
    }
  },
  components: {
    CodeArea
  },
  inject: ["$stripeManager"],
  data () {
    return {
      priceAdjustmentFunctionBuffer: ""
    };
  },
  watch: {
    "cart.readonlyCart.priceAdjustmentFunction": {
      handler: function (value) {
        this.priceAdjustmentFunctionBuffer = value;
      },
      immediate: true
    }
  },
  computed: {
    user () {
      return this.$store.getters.user;
    },
    cart () {
      return this.license.cart;
    },
    cartPriceTotal () {
      return this.monthlyPriceWithGlobalCustomAndRenewalDiscount * this.numMonthsToCharge;
    },
    numMonthsToCharge () {
      return this.cart.result.licenseDurationInMonths;
    },
    opts () {
      return {
        licenseDurationInMonths: this.cart.result.licenseDurationInMonths,
        monthlyPriceWithAllPreviousDiscounts: this.cart.result.monthlyPriceWithGlobalAndCustomDiscount
      };
    },
    monthlyPriceWithGlobalCustomAndRenewalDiscount () {
      let result;
      try {
        if (this.priceAdjustmentFunctionBuffer) {
          result = eval("(" + this.priceAdjustmentFunctionBuffer + ")")(this.opts); // eslint-disable-line no-eval
        } else {
          result = this.opts.monthlyPriceWithAllPreviousDiscounts;
        }
        if (isNaN(result) || ((typeof result) !== "number")) {
          console.error("Result was not a number, instead was:", JSON.stringify(result));
        }
        result = +result.toFixed(2);
      } catch (e) {
        result = JSON.stringify({
          type: "ERROR",
          content: this.priceAdjustmentFunctionBuffer,
          message: e.message
        });
      }
      return result;
    }
  }
};

</script>
