export default {
  data () {
    return {
      sequencesTab: 0,
      activeSequence: null,
      activeSequencePolicy: null,
      newSequencePolicyProperties: null
    };
  },
  watch: {
    sequencesTab (tab) {
      if (tab === 0) {
        if (this.activeSequence) {
          this.$router.activateFileCard(this.activeSequence.key);
        }
      } else {
        if (this.activeSequencePolicy) {
          this.$router.activateFileCard(this.activeSequencePolicy.key);
        }
      }
    }
  },
  methods: {
    setActiveSequence (model) {
      this.activeSequence = model;
      this.$router.activateFileCard(this.activeSequence.key);
    },
    setActiveSequencePolicy (model) {
      this.activeSequencePolicy = model;
      if (this.activeSequencePolicy) {
        this.$router.activateFileCard(this.activeSequencePolicy.key);
      }
    },
    addSequencePolicy (properties) {
      this.activeSequencePolicy = null;
      this.newSequencePolicyProperties = properties;
      this.sequencesTab = 1;
    },
    addSequencePolicyDone () {
      this.newSequencePolicyProperties = null;
    }
  }
};
