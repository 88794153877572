<template>
  <v-select
    v-if="itemsReady"
    variant="underlined"
    :menu-props="{origin: 'auto'}"
    density="compact"
    hide-details
    :items="items"
    :model-value="value"
    @update:modelValue="$emit('change', $event)"
    :disabled="disabled"
  >
    <template v-slot:append-item>
      <div v-intersect="onIntersect" class="pa-4">
        Loading...
      </div>
    </template>
  </v-select>
</template>

<script>

export default {
  components: {
  },
  props: {
    feature: {
      type: Object,
      required: true
    },
    packSize: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["change", "intersect"],
  data () {
    return {
      itemsReady: false,
      items: []
    };
  },
  watch: {
    value: {
      handler: function () {
        this.addValueToList();
      }
    }
  },
  created () {
    let len = this.items.length;
    let value = 0 - this.packSize;
    while (value < this.value) {
      value = value + this.packSize;
      this.items.splice(len, 0, { title: value.toString(), value });
      len++;
    }
    this.addMoreItems();
    this.addValueToList();
    this.itemsReady = true;
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    onIntersect (isIntersecting, entries, observer) {
      if (entries[0].isIntersecting) {
        this.$emit("intersect");
        this.addMoreItems();
      }
    },
    addMoreItems () {
      let len = this.items.length;
      let value = this.items[len - 1].value;
      const count = Math.max(20, parseInt(0.2 * len));
      for (let i = 0; i < count; i++) {
        value = value + this.packSize;
        this.items.splice(len, 0, { title: value.toString(), value });
        len++;
      }
    },
    addValueToList () {
      if (!this.items.find(item => item.value === this.value)) {
        const index = this.items.findIndex(item => this.value < item.value);
        this.items.splice(index, 0, { title: this.value.toString(), value: this.value });
      }
    }
  }
};
</script>

<style lang="css" scoped>
</style>
