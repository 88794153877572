<template>
  <v-menu
    v-model="isActive"
    ref="menu"
    content-class="elevation-10"
    :close-on-content-click="false"
    location="top start"
    origin="overlap"
    :offset="[23, 17]"
  >
    <template v-slot:activator="{ props }">
      <div
        data-test="menu-activator"
        class="field ellipsis"
        v-bind="props"
      >
        <span v-if="subject[getProperty]">
          {{ subject[getProperty] }}&nbsp;
        </span>
        <span v-else style="opacity: 0.3;">
          Not set<span v-if="subject[getProperty + 'Default']"> (default: {{ subject[getProperty + "Default"] }})</span>&nbsp;
        </span>
      </div>
    </template>

    <div
      v-if="isActive"
      class="menu-content"
      style="min-width: 225px;"
    >
      <v-combobox
        v-if="column.type === 'combobox'"
        ref="combobox"
        spellcheck="false"
        autocapitalize="off"
        variant="underlined"
        :disabled="subject[getProperty + 'Readonly']"
        single-line
        :placeholder="menuValue"
        hint="Select or type the connection to the DUT"
        :items="subject.suggestions"
        @update:modelValue="onInput"
        @keydown="onKeydown"
      ></v-combobox>
      <v-text-field
        v-else
        ref="textField"
        spellcheck="false"
        autocapitalize="off"
        variant="underlined"
        :disabled="subject[getProperty + 'Readonly']"
        :model-value="lastUpdatedValue || menuValue"
        :error-messages="errorMessages"
        @update:modelValue="onInput"
        @keydown="onKeydown"
      ></v-text-field>
    </div>
  </v-menu>
</template>

<script>

export default {
  props: {
    subject: {
      type: Object,
      required: true
    },
    column: {
      type: Object,
      required: true
    }
  },
  created () {
    this.originalValue = null;
  },
  data () {
    return {
      isActive: false,
      cancelled: false,
      menuValue: "",
      lastUpdatedValue: "",
      errorMessages: []
    };
  },
  watch: {
    isActive () {
      if (this.isActive) {
        this.originalValue = this.subject[this.setProperty];
        this.menuValue = this.subject[this.getProperty];
        this.lastUpdatedValue = "";
        this.errorMessages = [];
        setTimeout(() => {
          const input = (this.$refs.textField || this.$refs.combobox).$el.querySelector("input");
          if (input) { // Can be null when closed quickly
            input.focus();
          }
        }, 200);
      } else {
        if (!this.cancelled) {
          if (this.lastUpdatedValue && this.column.onCommit) {
            this.column.onCommit(this.lastUpdatedValue, this.subject).then(reupdate => {
              if (reupdate) {
                this.onInput(this.lastUpdatedValue);
              }
            });
          }
        } else {
          this.cancelled = false;
        }
      }
    }
  },
  computed: {
    setProperty () {
      return this.column.setProperty || this.column.key;
    },
    getProperty () {
      return this.column.key;
    }
  },
  methods: {
    open () {
      this.isActive = true;
    },
    onInput (value) {
      let info = {};

      const errors = [];
      try {
        info = this.parser(value);
      } catch (e) {
        errors.push(e.message);
      }
      this.errorMessages = errors;

      if ((errors.length === 0) && (info.data[this.setProperty] !== this.subject[this.setProperty])) {
        const data = {};
        this.lastUpdatedValue = value;
        data[this.setProperty] = info.data[this.setProperty];
        this.subject.update(data);
      }
    },
    parser (value) {
      if (this.column.parser) {
        return this.column.parser(value);
      } else {
        const info = { data: {} };
        info.data[this.setProperty] = value.trim();
        return info;
      }
    },
    onKeydown (key) {
      if (key.code === "Enter") {
        this.isActive = false;
      } else if (key.code === "Escape") {
        this.cancelled = true;
        this.isActive = false;
        if (this.originalValue !== this.subject[this.setProperty]) {
          const data = {};
          data[this.setProperty] = this.originalValue;
          this.subject.update(data);
        }
      } else if (key.code === "Tab") {
        this.isActive = false;
        this.$emit("tabKey");
      }
    }
  }
};
</script>

<style lang="css" scoped>

.field {
  width: 100%;
}

.menu-content {
  background-color: white;
  padding: 0 16px 4px 16px;
}

</style>
