import { Auth } from "vue-app-utils-web";
import router from "@/router";
import session from "@/models/session-VITE_APP_PLATFORM";
import dialog from "@/utils/dialog";
import store from "@/store";

const auth = new Auth();
auth.configure(router, store, session, dialog, ["github"]);

export default auth;
