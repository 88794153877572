import JSZip from "jszip";
import FileSaver from "@/utils/file-saver";

import { path } from "@/utils/node-VITE_APP_PLATFORM";

export default {
  created () {
    this.fabIcon = "mdi-cloud-download";
  },
  methods: {
    async generateFiles () {
      const zip = new JSZip();
      this.allModels.forEach((model) => {
        zip.file(path.join(model.collectionName, model.projectFilePath), model.fileContents);
      });
      await zip.generateAsync({ type: "blob" }).then((content) => {
        FileSaver.saveAs(content, `${this.projectName}.zip`);
      });
    }
  }
};
