import { h, resolveComponent } from "vue";
import { VBtn, VIcon } from "vuetify/components";
import { DialogLayout } from "vue-app-components";
import MarkdownWrapper from "../markdown/markdown-wrapper.vue";

const markdownFiles = import.meta.globEager("../markdown/**/*.md");

export default function registerMarkdownComponents (app) {
  for (const key in markdownFiles) {
    const fileName = key;

    if (fileName.match(/README\.md$/)) {
      continue;
    }

    const mdComponent = markdownFiles[fileName].default;
    const mdId = fileName.replace(/^\.\.\/markdown\//, "").replace(/\.md$/, ""); // cheat-sheets/my-cheat-sheet
    const mdName = fileName.match(/^.*\/(.*).md$/)[1]; // my-cheat-sheet
    const mdInfo = __APP_MARKDOWN_INFO__[mdId]; // eslint-disable-line no-undef
    const mdComponentName = `md-${mdInfo.key}`; // md-cheat-sheets-my-cheat-sheet
    const dialogComponentName = `dialog-${mdInfo.id}`; // dialog-my-cheat-sheet
    const isCheatSheet = mdId.match(/^cheat-sheets\//);

    app.component(mdComponentName, mdComponent);
    app.component(dialogComponentName, {
      components: {
        VBtn,
        VIcon
      },
      props: {
        navIcon: {
          type: String,
          required: true
        }
      },
      render: function () {
        const self = this;
        let childComponents = {};

        if (isCheatSheet) {
          const VButton = resolveComponent("v-btn");
          childComponents = {
            "toolbar-actions": () => h(
              VButton,
              {
                href: `https://github.com/uvmgen/cheatsheets/blob/master/${mdInfo.baseName}`,
                target: "_blank",
                icon: "mdi-pencil"
              }
            )
          };
        }

        return h(
          DialogLayout,
          {
            title: mdInfo.title,
            fullscreen: true,
            navIcon: self.navIcon,
            dark: true,
            onPopDialog: function () {
              self.$emit("pop-dialog");
            },
            emits: ["pop-dialog"]
          },
          {
            default: () => {
              return h(MarkdownWrapper, { name: mdName }, {
                default: () => {
                  const MDComponent = resolveComponent(mdComponentName);
                  return h(
                    MDComponent,
                    {
                      onLinkedClicked: function (name) {
                        self.$emit("push-dialog", name);
                      },
                      emits: ["push-dialog"]
                    }
                  );
                }
              });
            },
            ...childComponents
          }
        );
      }
    });
  }
}
