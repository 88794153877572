import { createVuetify } from "vuetify";
import "vuetify/styles";

const light = {
  dark: false,
  colors: {
    primary: "#3f51b5",
    primaryLight: "#e8eaf6",
    secondary: "#002984",
    // lighter: "#757de8",
    accent: "#fdd835",
    warning: "#f9a825"
    // primary: import.meta.env.VITE_APP_COLOR_PRIMARY,
    // primaryText: import.meta.env.VITE_APP_COLOR_PRIMARY_TEXT,
    // primaryLight: import.meta.env.VITE_APP_COLOR_PRIMARY_LIGHT,
    // primaryDark: import.meta.env.VITE_APP_COLOR_PRIMARY_DARK,
    // secondary: import.meta.env.VITE_APP_COLOR_SECONDARY,
    // secondaryLight: import.meta.env.VITE_APP_COLOR_SECONDARY_LIGHT,
    // secondaryDark: import.meta.env.VITE_APP_COLOR_SECONDARY_DARK,
    // background: import.meta.env.VITE_APP_COLOR_BACKGROUND,
    // backgroundDark: import.meta.env.VITE_APP_COLOR_BACKGROUND_DARK,
    // dragDrop: import.meta.env.VITE_APP_COLOR_DRAG_DROP,
    // anchor: import.meta.env.VITE_APP_COLOR_ANCHOR,
    // // accent: "#fdd835",
    // warning: import.meta.env.VITE_APP_COLOR_WARNING,
    // tapeBlack: "#1b1e1d"
  }
};

const vuetify = createVuetify({
  theme: {
    defaultTheme: "light",
    themes: {
      light,
      dark: Object.assign({}, light, { dark: true })
    }
  },
  defaults: {
    VTextField: {
      color: "primary"
    },
    VTextarea: {
      color: "primary"
    },
    VCombobox: {
      color: "primary"
    },
    VRadioGroup: {
      color: "primary"
    },
    VSlider: {
      color: "primary",
      density: "compact"
    },
    VRangeSlider: {
      color: "primary",
      density: "compact"
    }
  }
});

export default vuetify;
