<template>
  <standard-layout>
    <article-container
      :article="cheatSheet"
      :editable-at="githubEditHref"
    />
  </standard-layout>
</template>

<script>
import StandardLayout from "@/components/standard-layout.vue";
import ArticleContainer from "@/components/article-container.vue";

export default {
  components: {
    StandardLayout,
    ArticleContainer
  },
  created () {
    this.cheatSheets = __APP_MARKDOWN_INFO__["cheat-sheets"]; // eslint-disable-line no-undef
  },
  data () {
    return {};
  },
  computed: {
    cheatSheet () {
      return this.cheatSheets[this.$route.params.id];
    },
    githubEditHref () {
      return "https://github.com/uvmgen/cheatsheets/blob/master/" + this.cheatSheet.baseName;
    }
  }
};
</script>

<style lang="css" scoped>
</style>
