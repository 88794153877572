<template>
  <dialog-layout
    title="Select an environment"
    :fullscreen="fullscreen"
    :navIcon="navIcon"
    :dark="dark"
    @pop-dialog="$emit('pop-dialog')"
  >
    <v-card
      class="choose-env-card pt-4"
      color="transparent"
      elevation="0"
    >
      <!--<v-card-title class="text-h5">-->
      <!--  Select an environment-->
      <!--</v-card-title>-->
      <!--<v-card-subtitle v-if="creatingPolicyForSequence">-->
      <!--  On behalf of {{ newClassProperties.klass.type }}-->
      <!--</v-card-subtitle>-->
      <v-card-text class="my-2">
        <v-select
          variant="underlined"
          :items="user.envs.filter(env => !env.hasTb)"
          item-title="name"
          item-value="id"
          label="Environment"
          :model-value="selectedEnvId"
          @update:modelValue="selectedEnvId = $event"
        ></v-select>
      </v-card-text>
      <v-card-actions style="margin-top: 90px;">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :disabled="!selectedEnvId"
          @click="createTb"
        >
          SAVE
        </v-btn>
      </v-card-actions>
    </v-card>
  </dialog-layout>
</template>

<script>
import { DialogLayout } from "vue-app-components";

export default {
  name: "dialog-stripe-verify-microdeposits",
  components: {
    DialogLayout
  },
  props: {
    fullscreen: {
      type: Boolean,
      required: true
    },
    navIcon: {
      type: String,
      required: true
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  emits: ["pop-dialog"],
  data () {
    return {
      selectedEnvId: null
    };
  },
  computed: {
    user () {
      return this.$store.getters.user;
    }
  },
  methods: {
    async createTb () {
      await this.$dialog.close();
      this.$router.push({ name: "tbNew", query: { id: this.selectedEnvId } })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    }
  }
};
</script>

<style lang="css" scoped>
</style>
