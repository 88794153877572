<template>
  <data-input-table
    class="mt-3"
    data-test="data-input-table-for-ports"
    :subject="uvcInterface"
    :getCollection="uvcInterface.ports"
    setCollectionProperty="ports"
    collectionName="ports"
    :columns="columns"
    :parse-new-items="parsePortList"
    :before-save="createParametersForList"
    :new-items-placeholder="newItemsPlaceholder"
    new-items-hint="Input your interface ports here."
  >
    <template v-slot:expand="{ item }">
      <div>
        <v-checkbox
          label="Clock"
          :model-value="item.isClock"
          @update:modelValue="updateClock(item, $event)"
        />
        <v-checkbox
          label="Reset"
          :model-value="item.isReset"
          @update:modelValue="updateReset(item, $event)"
        />
      </div>
    </template>
  </data-input-table>
</template>

<script>
import DataInputTable from "@/components/data-input-table.vue";
import { EncodeTypeAndNameParameters } from "@/encode-decode-type-and-name-parameters";
import parseSystemVerilog from "@/utils/parse-system-verilog";
import PortKindEnum from "@/utils/port-kind-enum";

export default {
  mixins: [
    EncodeTypeAndNameParameters("PortHeader", "PortName")
  ],
  components: {
    DataInputTable
  },
  props: {
    uvcInterface: {
      type: Object,
      required: true
    },
    newItemsPlaceholder: {
      type: String,
      default: "valid\nready\ndata[31:0]"
    }
  },
  computed: {
    columns () {
      return [
        {
          title: "Source",
          key: "master",
          type: "select-button",
          "select-button": {
            listItems (port) {
              if (port.parent.isPipelinedRead) {
                return [
                  {
                    title: "Master",
                    subtitle: "Request channel",
                    data: {
                      master: true,
                      channel: "request"
                    }
                  },
                  {
                    title: "Master",
                    subtitle: "Response channel",
                    data: {
                      master: true,
                      channel: "response"
                    }
                  },
                  {
                    title: "Slave",
                    subtitle: "Request channel",
                    data: {
                      master: false,
                      channel: "request"
                    }
                  },
                  {
                    title: "Slave",
                    subtitle: "Response channel",
                    data: {
                      master: false,
                      channel: "response"
                    }
                  }
                ];
              } else if (port.parent.isPipelinedWrite) {
                return [
                  {
                    title: "Master",
                    subtitle: "Request channel",
                    data: {
                      master: true,
                      channel: "request"
                    }
                  },
                  {
                    title: "Master",
                    subtitle: "Data channel",
                    data: {
                      master: true,
                      channel: "data"
                    }
                  },
                  {
                    title: "Master",
                    subtitle: "Response channel",
                    data: {
                      master: true,
                      channel: "response"
                    }
                  },
                  {
                    title: "Slave",
                    subtitle: "Request channel",
                    data: {
                      master: false,
                      channel: "request"
                    }
                  },
                  {
                    title: "Slave",
                    subtitle: "Data channel",
                    data: {
                      master: false,
                      channel: "data"
                    }
                  },
                  {
                    title: "Slave",
                    subtitle: "Response channel",
                    data: {
                      master: false,
                      channel: "response"
                    }
                  }
                ];
              } else {
                return [
                  {
                    title: "Master",
                    data: {
                      master: true
                    }
                  },
                  {
                    title: "Slave",
                    data: {
                      master: false
                    }
                  }
                ];
              }
            },
            properties (port) {
              if (port.isClock) {
                return {
                  disabled: true,
                  label: "Clock"
                };
              } else if (port.isReset) {
                return {
                  disabled: true,
                  label: "Reset"
                };
              } else {
                const color = port.isMaster ? "primary" : "accent";
                // [TODO] could change this to use user.setting.masterWord/slaveWord
                const word = port.isMaster ? "Master" : "Slave";
                const label = port.parent.isPipelined ? `${word} in ${port.channel} channel` : word;
                let selectedIndex;
                if (port.parent.isPipelinedRead) {
                  if (port.isMaster) {
                    selectedIndex = (port.channel === "request") ? 0 : 1;
                  } else {
                    selectedIndex = (port.channel === "request") ? 2 : 3;
                  }
                } else if (port.parent.isPipelinedWrite) {
                  if (port.isMaster) {
                    if (port.channel === "request") {
                      selectedIndex = 0;
                    } else if (port.channel === "data") {
                      selectedIndex = 1;
                    } else {
                      selectedIndex = 2;
                    }
                  } else {
                    if (port.channel === "request") {
                      selectedIndex = 3;
                    } else if (port.channel === "data") {
                      selectedIndex = 4;
                    } else {
                      selectedIndex = 5;
                    }
                  }
                } else {
                  selectedIndex = port.isMaster ? 0 : 1;
                }
                return {
                  disabled: false,
                  color,
                  label,
                  selectedIndex
                };
              }
            }
          },
          sortable: false,
          width: "72px"
        },
        {
          title: "Type",
          key: "type",
          setProperty: "encodedType",
          sortable: false,
          width: "38%",
          parser: this.parseType,
          onCommit: this.createParametersForType
        },
        {
          title: "Name",
          key: "name",
          setProperty: "encodedName",
          sortable: false,
          width: "38%",
          parser: this.parseName,
          onCommit: this.createParametersForName
        },
        {
          title: "Reset value",
          key: "defaultValue",
          sortable: false,
          width: "24%"
        },
        {
          title: "",
          key: "data-table-expand",
          sortable: false,
          width: "56px"
        }
      ];
    }
  },
  methods: {
    parentOfParameters () {
      return this.uvcInterface;
    },
    parsePortList (list) {
      return parseSystemVerilog(list, "ListOfPortDeclarationsWithoutDirection");
    },
    updateClock (item, evt) {
      let kind;
      if (evt) {
        kind = PortKindEnum.getValue("clock");
      } else {
        kind = PortKindEnum.getValue("none");
      }
      item.update({ kind });
    },
    updateReset (item, evt) {
      let kind;
      if (evt) {
        kind = PortKindEnum.getValue("reset");
      } else {
        kind = PortKindEnum.getValue("none");
      }
      item.update({ kind });
    }
  }
};

</script>

<style lang="css" scoped>
</style>
