<template>
  <div>
    <code-area
      label="Function"
      :placeholder="user.priceSheet.priceAdjustmentFunctionPlaceholder"
      :num-rows="5"
      :value="priceAdjustmentFunctionBuffer"
      @input="priceAdjustmentFunctionBuffer = $event"
      hint=""
    />

    <div class="d-flex justify-end">
      <div>
        <v-btn
          color="primary"
          block
          :disabled="priceAdjustmentFunctionBuffer === user.priceSheet.priceAdjustmentFunction"
          @click="user.priceSheet.immediateUpdate({ priceAdjustmentFunction: priceAdjustmentFunctionBuffer })"
        >
          PUBLISH FUNCTION
        </v-btn>
      </div>
    </div>

    <v-row
      class="mt-8"
      no-gutters
    >
      <v-col>
        <v-row
          class="mx-4"
        >
          <v-col>
          </v-col>
          <v-col>
            <i>Price before</i>
          </v-col>
          <v-col>
            <i>Price after</i>
          </v-col>
        </v-row>
        <v-row
          v-for="(feature, featureIndex) in user.priceSheet.features"
          :key="featureIndex + 'a'"
          class="mx-4"
        >
          <v-col>
            {{ feature.id }}
          </v-col>
          <v-col>
            {{ $stripeManager.formatPrice(feature.price) }}
          </v-col>
          <v-col>
            {{ $stripeManager.formatPrice(globalPrice(feature)) }}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=3>
        <div class="mt-8">
          Term in months = {{ licenseDurationInMonths }}
          <v-slider
            :model-value="licenseDurationInMonths"
            :min="1"
            :max="Math.min(99999, +('1' + '0'.repeat(`${licenseDurationInMonths}`.length)))"
            thumb-label
            vertical
            @update:modelValue="licenseDurationInMonths = $event"
          />
        </div>
        <div class="mt-4">
          Quantity = {{ quantity }}
          <v-slider
            :model-value="quantity"
            :min="0"
            :max="Math.min(99999, +('1' + '0'.repeat(`${quantity}`.length)))"
            thumb-label
            persistent-thumb-label
            vertical
            @update:modelValue="quantity = $event"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CodeArea from "@/components/code-area.vue";

export default {
  components: {
    CodeArea
  },
  inject: ["$stripeManager"],
  data () {
    return {
      priceAdjustmentFunctionBuffer: "",
      licenseDurationInMonths: 1,
      quantity: 1
    };
  },
  watch: {
    "user.priceSheet.priceAdjustmentFunction": {
      handler: function (value) {
        this.priceAdjustmentFunctionBuffer = value;
      },
      immediate: true
    }
  },
  computed: {
    user () {
      return this.$store.getters.user;
    }
  },
  methods: {
    opts (feature) {
      return { feature, licenseDurationInMonths: this.licenseDurationInMonths, quantity: this.quantity };
    },
    globalPrice (feature) {
      let result;
      try {
        const opts = Object.assign({}, this.opts(feature));
        result = eval("(" + this.priceAdjustmentFunctionBuffer + ")")(opts); // eslint-disable-line no-eval
        if (isNaN(result) || ((typeof result) !== "number")) {
          console.error("Result was not a number, instead was:", JSON.stringify(result));
        }
      } catch (e) {
        result = JSON.stringify({
          type: "ERROR",
          content: this.priceAdjustmentFunctionBuffer,
          message: e.message
        });
      }
      return result;
    }
  }
};

</script>
