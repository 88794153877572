import { StripeManager } from "vue-app-utils-web";
import store from "@/store";
import router from "@/router";

// Exporting function rather than the object due to "Cannot access 'router' before initialization" exception
/*
export default function createStripeManager () {
  const STRIPE_PRODUCTION_KEY = import.meta.env.VITE_APP_FIRESTORE_PROJECT_ID === "uvmgen-staging"
    ? "pk_test_51N1tAhEtqh9yKPaLx2MQnM3Idiu9Detf1o39DHmw3J5CkyahIDieVOQa4gSYlwSGXOPto8mdazIJfgHFtawNQhZX00l3AYF5hz"
    : "pk_live_51N1tAhEtqh9yKPaLMefRR86P2aY8JsxXJJhTXVgpSgsuLFWErLKb9IjfAM8cKh9sBzlZdIFdlZSY4Sbo3BCrFca400RpFoLLzV";
  const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_NODE_ENV === "production"
    ? STRIPE_PRODUCTION_KEY
    : "pk_test_51N1tAhEtqh9yKPaLx2MQnM3Idiu9Detf1o39DHmw3J5CkyahIDieVOQa4gSYlwSGXOPto8mdazIJfgHFtawNQhZX00l3AYF5hz";

  const propsData = {
    publishableKey: STRIPE_PUBLISHABLE_KEY,
    store,
    router
  };

  return new StripeManager({ propsData });
};
*/
const STRIPE_PRODUCTION_KEY = import.meta.env.VITE_APP_FIRESTORE_PROJECT_ID === "uvmgen-staging"
  ? "pk_test_51N1tAhEtqh9yKPaLx2MQnM3Idiu9Detf1o39DHmw3J5CkyahIDieVOQa4gSYlwSGXOPto8mdazIJfgHFtawNQhZX00l3AYF5hz"
  : "pk_live_51N1tAhEtqh9yKPaLMefRR86P2aY8JsxXJJhTXVgpSgsuLFWErLKb9IjfAM8cKh9sBzlZdIFdlZSY4Sbo3BCrFca400RpFoLLzV";
const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_NODE_ENV === "production"
  ? STRIPE_PRODUCTION_KEY
  : "pk_test_51N1tAhEtqh9yKPaLx2MQnM3Idiu9Detf1o39DHmw3J5CkyahIDieVOQa4gSYlwSGXOPto8mdazIJfgHFtawNQhZX00l3AYF5hz";

const propsData = {
  publishableKey: STRIPE_PUBLISHABLE_KEY,
  store,
  router
};

const stripeManager = new StripeManager({ propsData });

export default stripeManager;
