<template>
  <v-app
    id="app"
    data-test="app"
    :style="appStyles"
    @click="$auth.bodyClicked && $auth.bodyClicked()"
  >
    <dialog-navigator isVDialog dark/>

    <side-nav/>

    <router-view
      v-if="!$store.getters.licenseRequired || $store.getters.licenseIsValid"
      :key="$route.path"
    />
    <dialog-license-key-input v-else />

    <snackbar-messages
      messageOnlyColor="white"
    />
  </v-app>
</template>

<script>
import SwUpdateMixin from "@/utils/sw-update-mixin-VITE_APP_PLATFORM";
import {
  DialogNavigator,
  SnackbarMessages
} from "vue-app-components";
import SideNav from "@/components/side-nav.vue";
import DialogLicenseKeyInput from "@/components/dialog-license-key-input-VITE_APP_PLATFORM.vue";

export default {
  mixins: [
    SwUpdateMixin
  ],
  components: {
    DialogNavigator,
    SnackbarMessages,
    SideNav,
    DialogLicenseKeyInput
  },
  inject: ["$auth"],
  data () {
    return {};
  },
  computed: {
    appStyles () {
      return {
        "--primary-color": this.$vuetify.theme.current.colors.primary,
        "--primary-color-lighten1": this.$vuetify.theme.current.colors.primaryLight,
        "--secondary-color": this.$vuetify.theme.current.colors.secondary,
        "--accent-color": this.$vuetify.theme.current.colors.accent,
        "--background-color": "#f5f5f5",
        "--project-card-height": "200px",
        "--project-card-actions-height": "56px"
      };
    }
  }
};

</script>

<style lang="css" scoped>
</style>
