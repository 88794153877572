// PWA still evolving. I've heard on mobile, which is what is
// important, that preventDefault does nothing. On desktop it
// does, but if user answers no, beforeinstallprompt fires
// I think when the route is changed. I'd have to save the user
// choice in the database for this to be useful. And in the end
// I never really wanted PWA for desktop. I've also heard that
// they are going to put install/uninstall in the chrome address
// bar. Maybe they'll do that for both desktop and mobile. I
// dunno, so I'll disable for desktop and wait.

// let deferredPrompt = null;
// let alreadyAnsweredNo = false;

window.addEventListener("beforeinstallprompt", (e) => {
  // deferredPrompt = e;
  e.preventDefault();
  // deferredPrompt.userChoice
  //   .then((choiceResult) => {
  //     if (choiceResult.outcome !== "accepted") {
  //       alreadyAnsweredNo = true;
  //     }
  //     deferredPrompt = null;
  //   });
});

export default function () {
  // if (!alreadyAnsweredNo) {
  //   window.addEventListener("click", () => {
  //     window.addEventListener("click", () => {
  //       window.addEventListener("click", () => {
  //         window.addEventListener("click", () => {
  //           window.addEventListener("click", () => {
  //             if (deferredPrompt !== null) {
  //               deferredPrompt.prompt();
  //             }
  //           }, {once: true});
  //         }, {once: true});
  //       }, {once: true});
  //     }, {once: true});
  //   }, {once: true});
  // }
}
