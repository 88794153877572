<template>
  <data-input-table
    class="mt-3"
    data-test="data-input-table-for-instance-variables"
    :subject="klass"
    :getCollection="klass.instanceVariables.length === 0 ? [] : klass.userConfigurableInstanceVariables"
    setCollectionProperty="instanceVariables"
    collectionName="instance variables"
    :columns="columns"
    :parse-new-items="parseInstanceVariables"
    :before-save="createParametersForList"
    :new-items-placeholder="newItemsPlaceholder"
    new-items-hint="Input your instance variables here."
    :override-readonly-fn="overrideComputedInstanceVariable"
  >
    <template v-slot:expand="{ item }">
      <div
        v-if="!item.isConst"
      >
        <div
          v-if="!klass.isComponent && !klass.isPolicy"
        >
          <instance-variable-panel :instanceVariable="item"/>
        </div>
        <div
          v-else-if="klass.isCoverage"
        >
          <coverpoint-code-area :instanceVariable="item"/>
        </div>
      </div>
    </template>
  </data-input-table>
</template>

<script>
import DataInputTable from "@/components/data-input-table.vue";
import InstanceVariablePanel from "@/components/instance-variable-panel.vue";
import CoverpointCodeArea from "@/components/coverpoint-code-area.vue";
import { EncodeTypeAndNameParameters } from "@/encode-decode-type-and-name-parameters";
import parseSystemVerilog from "@/utils/parse-system-verilog";

export default {
  mixins: [
    EncodeTypeAndNameParameters("ClassPropertyType", "VariableDeclAssignment")
  ],
  components: {
    DataInputTable,
    InstanceVariablePanel,
    CoverpointCodeArea
  },
  props: {
    klass: {
      type: Object,
      required: true
    },
    newItemsPlaceholder: {
      type: String,
      default: "local bit enable\nrand logic [DATA_MSB_MAX:0] data"
    }
  },
  computed: {
    columns () {
      return [
        {
          title: "Type",
          key: "type",
          setProperty: "encodedType",
          sortable: false,
          width: "70%",
          parser: this.parseType,
          onCommit: this.createParametersForType
        },
        {
          title: "Name",
          key: "name",
          setProperty: "encodedName",
          sortable: false,
          width: "30%",
          parser: this.parseName,
          onCommit: this.createParametersForName
        },
        {
          title: "",
          key: "data-table-expand",
          sortable: false,
          width: "56px"
        }
      ];
    }
  },
  methods: {
    overrideComputedInstanceVariable (instanceVariable) {
      const data = {
        encodedType: instanceVariable.type,
        encodedName: instanceVariable.name,
        overrideId: instanceVariable.id
      };
      if (instanceVariable.defaultValue) {
        data.defaultValue = instanceVariable.defaultValue;
      }
      if (instanceVariable.fieldMacro) {
        data.fieldMacro = instanceVariable.fieldMacro;
      }
      if (instanceVariable.fieldMacroFlagIds.length) {
        data.fieldMacroFlagIds = instanceVariable.fieldMacroFlagIds;
      }
      // constraintRequired: false,
      // setter: null,
      // setterOnly: false,
      // subEnvConfigHandlesWithoutDimensions: [],
      // subEnvConfigHandlesWithDimensions: [],
      // setterCallRequired: null,
      // appendedSetterArgsOverride: [],
      // getter: false,
      // getWord: "get",
      // unsetValueOverride: "",
      // coverpointOverride: null,
      // convert2stringOverride: null,
      return this.klass.createInstanceVariable(data);
    },
    parentOfParameters () {
      return this.klass.package;
    },
    parseInstanceVariables (value) {
      return parseSystemVerilog(value, "ListOfClassPropertyDeclarations");
    }
  }
};

</script>

<style lang="css" scoped>
</style>
