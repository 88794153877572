<template>
  <v-data-table
    :headers="columns"
    :items="getCollection"
    :no-data-text="'No ' + collectionName"
    :expanded="expanded"
    ref="vDataTable"
    :items-per-page="-1"
    :show-expand="showExpand"
    :density="dense ? 'compact' : 'default'"
  >

    <template v-slot:item="{ item, index, isExpanded }">
      <tr
        data-test="data-input-table__content-row"
        class="v-data-table__row"
        :class="{'v-data-table__expanded': isExpanded(item), 'v-data-table__expanded__row': isExpanded(item)}"
      >
        <td
          v-for="(header, headerIndex) in columns"
          :key="headerIndex"
          class="text-start"
          :class="{ 'expand-td': header.key === 'data-table-expand' }"
        >
          <div
            v-if="header.key === 'data-table-expand'"
            class="d-flex justify-end"
            style="cursor: pointer;"
            @click="toggleExpand(item, isExpanded)"
          >
            <v-icon>
              {{ isExpanded(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </div>
          <data-input-table-content
            v-else
            :ref="'td' + ((index * (columns.length - 1)) + headerIndex)"
            :subject="item.raw"
            :column="header"
            @tabKey="openMenu('td' + ((index * (columns.length - 1)) + headerIndex + 1))"
          />
        </td>
      </tr>
    </template>

    <template v-slot:expanded-row="{ columns, item }">
      <tr
        class="v-data-table__expanded v-data-table__expanded__content"
      >
        <td :colspan="columns.length">
          <div class="my-1">
            <item-heading
              :subject="item.raw"
              :allow-readonly-override="!!overrideReadonlyFn"
              @edit="overrideReadonlyFn(item.raw)"
            />
          </div>
          <div class="mt-5 mr-4">
            <slot name="expand" v-bind="{ item: item.raw }"></slot>
          </div>
        </td>
      </tr>
    </template>

    <template v-slot:tfoot>
      <tfoot v-if="canAddItems">
        <tr>
          <td
            class="data-input-table__footer-td"
            :colspan="columns.length"
          >
            <vue3-slide-up-down
              :model-value="addItemsIsActive"
            >
              <code-area
                class="pt-4 ml-4"
                ref="addItemsCodeArea"
                :label="'New ' + collectionName"
                :placeholder="newItemsPlaceholder"
                :value="newItemsValue"
                :hint="validatedNewItemsData.length ? 'Looks good.' : newItemsHint"
                :rules="[validateNewItemsInput]"
                :dialogHelp="dialogHelp"
                @input="newItemsValue = $event"
                @valid="isValid = $event"
              />
            </vue3-slide-up-down>

            <div class="add-items-actions__container">
              <div
                class="add-items-actions"
                :class="{'add-items-actions--active': addItemsIsActive}"
              >
                <transition name="fade" mode="out-in">

                  <div v-if="!addItemsIsActive" key="add-actions">
                    <v-btn
                      class="pt-1"
                      variant="text"
                      color="primary"
                      @click="showAddItems"
                    >
                      ADD
                    </v-btn>
                  </div>
                </transition>
                <transition name="fade" mode="out-in">
                  <div v-if="addItemsIsActive" class="pb-2" key="save-actions">
                    <v-btn
                      variant="text"
                      color="primary"
                      @click="onCancel"
                    >
                      CANCEL
                    </v-btn>
                    <v-btn
                      variant="text"
                      color="primary"
                      :disabled="saveButtonDisabled"
                      @click="onSave"
                    >
                      SAVE
                    </v-btn>
                  </div>

                </transition>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </template>
    <template v-slot:bottom>
    </template>

  </v-data-table>
</template>

<script>
import DataInputTableContent from "@/components/data-input-table-content.vue";
import ItemHeading from "@/components/item-heading.vue";
import CodeArea from "@/components/code-area.vue";
import pluralize from "pluralize-esm";
import { VDataTable } from "vuetify/labs/VDataTable";

export default {
  components: {
    DataInputTableContent,
    ItemHeading,
    CodeArea,
    VDataTable
  },
  props: {
    subject: {
      type: Object,
      required: true
    },
    getCollection: {
      type: Array,
      required: true
    },
    setCollectionProperty: {
      type: String,
      required: false
    },
    collectionName: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    showExpand: {
      type: Boolean,
      default: true
    },
    parseNewItems: {
      type: Function,
      required: false
    },
    beforeSave: {
      type: Function,
      default: function (validatedNewItemsData) { return Promise.resolve(validatedNewItemsData); }
    },
    newItemsPlaceholder: {
      type: String,
      required: false
    },
    newItemsHint: {
      type: String,
      required: false
    },
    newItemExtraCreateData: {
      type: Object,
      default: function () { return {}; }
    },
    dialogHelp: {
      type: String
    },
    dense: {
      type: Boolean,
      default: false
    },
    canAddItems: {
      type: Boolean,
      default: true
    },
    overrideReadonlyFn: {
      type: Function,
      default: null
    }
  },
  created () {
    if (this.canAddItems) {
      const setItemName = pluralize.singular(this.setCollectionProperty);
      this.className = setItemName.charAt(0).toUpperCase() + setItemName.substr(1);
    }
  },
  data () {
    return {
      expanded: [],
      newItemsValue: "",
      addItemsIsActive: false,
      validatedNewItemsData: [],
      isValid: true
    };
  },
  computed: {
    saveButtonDisabled () {
      return !this.isValid || (this.validatedNewItemsData.length === 0);
    }
  },
  methods: {
    openMenu (refName) {
      const ref = this.$refs[refName];
      if (ref) {
        ref[0].open();
      }
    },
    toggleExpand (item, isExpanded) {
      if (isExpanded(item)) {
        this.expanded = [];
      } else {
        this.expanded = [item.key];
      }
    },
    showAddItems () {
      this.addItemsIsActive = true;
    },
    onSave () {
      this.beforeSave(this.validatedNewItemsData).then(newItemsData => {
        this.addItemsIsActive = false;
        const promises = newItemsData.map((d) => {
          const data = Object.assign({}, this.newItemExtraCreateData, d.data);
          return this.subject[`create${this.className}`](data);
        });
        Promise.all(promises).then(() => { this.newItemsValue = ""; this.validatedNewItemsData = []; });
      });
    },
    onCancel () {
      setTimeout(() => { this.newItemsValue = ""; }, 1000);
      this.addItemsIsActive = false;
    },
    validateNewItemsInput (value) {
      let errorMessage;
      try {
        this.validatedNewItemsData = this.parseNewItems(value);
      } catch (e) {
        this.validatedNewItemsData = [];
        errorMessage = e.message;
      }
      if (errorMessage) {
        return errorMessage;
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="css" scoped>

:deep(table) {
  table-layout: fixed;
  border-radius: 7px;
}

:deep(th) {
  white-space: nowrap;
  overflow: hidden;
}

.t-body {
  background-color: white;
}

/* Doing this for 'Source' in ports table. */
:deep(tr > th:not(:first-child)) {
  text-overflow: ellipsis;
}

:deep(th:nth-last-child(2)) {
  overflow: visible;
}

:deep(td),
:deep(td > div),
:deep(td .v-menu),
:deep(td .v-menu > .v-menu__activator),
:deep(td .v-menu > .v-menu__activator > div) {
  width: 100%;
}

.v-menu,
:deep(.v-menu .v-menu__activator),
:deep(.v-menu .v-menu-activator > a) {
  height: 100%;
  width: 100%;
}

:deep(.v-data-table__wrapper) {
  overflow: hidden;
}

:deep(.v-data-table__expanded__content > td) {
  background: var(--background-color) !important;
  padding-right: 0 !important;
}

:deep(.v-data-table__expanded__content .v-select .v-label) {
  color: rgba(0,0,0,0.70);
}

:deep(.v-data-table__expanded__content .v-select .v-chip) {
  background-color: white;
  border-color: black;
}

.data-input-table__footer-td {
  padding-left: 0 !important;
}

.add-items-actions {
  text-align: right;
  white-space: nowrap;
  width: 0;
  transition: width 1.0s ease;
}

.add-items-actions.add-items-actions--active {
  width: 100%;
}

.add-items-actions button {
  padding: 0;
  margin: 2px 0 6px 0;
  min-width: 78px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.v-data-table {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  clip-path: inset(-15px 0 -15px 0);
}

.expand-td {
  padding-right: 12px !important;
}

</style>
