<template>
  <collection-editor
    v-if="tb.testBase"
    data-test="test-collection-editor"
    :item-type="itemType"
    item-name-property="type"
    list-item-icon="mdi-alpha-t-box"
    :neutral-items="tests"
    :add-button-is-disabled="baseClassCollection.length <= 1"
    :add-button-is-fab-condition="baseClassCollection.filter(option => !option.header && !option.divider).length > 0"
    @activate="$emit('activate', $event)"
  >
    <template v-slot:list-item-subtitle="{ klass }">
      <v-list-item-subtitle v-if="klass.superclass">
        Extends {{ klass.superclass.type }}
      </v-list-item-subtitle>
    </template>
    <template v-slot:create-card="{ selectedItem, onSave, onCancel }">
      <class-create-card
        :selected-item="selectedItem"
        :on-save="onSave"
        :on-cancel="onCancel"
        :item-type="itemType"
        new-item-hint="Type test name"
        :new-item-placeholder="newItemPlaceholder"
        :create-new-item="createNewItem"
        :baseClassCollection="baseClassCollection"
        @activate="$emit('activate', $event)"
      />
    </template>
    <template v-slot:item-editor="{ selectedItem }">
      <class-editor
        :root-project="tb"
        :klass="selectedItem"
        instance-variables-are-advanced
        subroutines-are-advanced
        :new-instance-variables-placeholder="`bit x\nint y`"
        :new-subroutine-placeholder="`virtual function void do_it();\n  // ...\nendfunction`"
      />
    </template>
  </collection-editor>
</template>

<script>
import CollectionEditor from "@/components/collection-editor.vue";
import ClassCreateCard from "@/components/class-create-card.vue";
import ClassEditor from "@/components/class-editor.vue";

export default {
  components: {
    CollectionEditor,
    ClassCreateCard,
    ClassEditor
  },
  props: {
    tb: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    };
  },
  created () {
    this.itemType = "test";
  },
  computed: {
    baseClassCollection () {
      let options = [];
      // [TODO] re-enable once supported: https://github.com/vuetifyjs/vuetify/issues/15721
      // options = options.concat([{ header: "Tests" }]);
      options = options.concat(this.tests.filter(test => test !== this.tb.testBase));
      return options;
    },
    tests () {
      return this.tb.tests;
    }
  },
  methods: {
    createNewItem (identifier, baseClass) {
      return baseClass.createFormSubclass({ type: identifier });
    },
    newItemPlaceholder () {
      return `${this.tb.name}_xyz_test_c`;
    }
  }
};
</script>

<style lang="css" scoped>
</style>
