<template>
  <v-container class="pa-0">
    <v-row
      class="item-heading pb-0"
      no-gutters
    >
      <v-col class="heading__flex flex-grow-1 flex-shrink-0">
        <div class="heading__label text-h5 ellipsis">{{ subject[itemName] }}</div>
        <p class="mb-0 subject-subheading body-2 font-weight-light ellipsis"><span v-if="subheading">{{ subheading }}</span><span v-else-if="subject[itemReadonly] && allowReadonlyOverride">Read only</span></p>
      </v-col>
      <v-col class="actions__flex flex-shrink-1 flex-grow-0">
        <v-menu
          v-if="hasMenu"
          v-model="openMenu"
          :close-on-content-click="!subject[itemReadonly]"
          location="end top"
          origin="top end"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="text"
              size="36"
              icon="mdi-dots-vertical"
            >
            </v-btn>
          </template>

          <v-list min-width="250px">
            <v-list-item
              v-if="showHideInstanceVariables"
              title=""
              @click="onShowHideInstanceVariablesToggle"
              :prepend-icon="showInstanceVariablesValue ? 'mdi-eye-off' : 'mdi-eye'"
            >
              <v-list-item-title>{{ showInstanceVariablesValue ? "Hide variables" : "Show variables" }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="showHideSubroutines"
              title=""
              @click="onShowHideSubroutinesToggle"
              :prepend-icon="showSubroutinesValue ? 'mdi-eye-off' : 'mdi-eye'"
            >
              <v-list-item-title>{{ showSubroutinesValue ? "Hide subroutines" : "Show subroutines" }}</v-list-item-title>
            </v-list-item>

            <v-dialog
              v-if="rename"
              v-model="openRenameDialog"
              max-width="400px"
            >
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  title=""
                  :disabled="subject[itemReadonly]"
                  :prepend-icon="subject[itemReadonly] ? 'mdi-pencil-off' : 'mdi-pencil'"
                >
                  <v-list-item-title>Rename</v-list-item-title>
                </v-list-item>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Rename</span>
                </v-card-title>
                <v-card-text class="mt-4">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <v-text-field
                        spellcheck="false"
                        autocapitalize="off"
                        autofocus
                        variant="underlined"
                        :label="renameLabel"
                        :hint="renameHint"
                        :error-messages="renameErrorMessages"
                        :model-value="subject[renameProperty || itemName]"
                        @update:modelValue="$emit('rename', $event)"
                        @keydown="onKeydown"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="openRenameDialog = false"
                  >
                    DONE
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-list-item
              v-if="subject[itemReadonly] && allowReadonlyOverride"
              title=""
              @click="$emit('edit')"
              prepend-icon="mdi-pencil"
            >
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-else
              title=""
              :disabled="subject[itemReadonly]"
              @click.stop="onDestroy"
              :prepend-icon="subject[itemReadonly] ? 'mdi-delete-off' : 'mdi-delete'"
            >
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>

            <v-dialog
              v-model="openDeleteDialog"
              max-width="400px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">Delete</span>
                </v-card-title>
                <v-card-text class="mt-4">
                  {{ subject.confirmDestroy }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="openDeleteDialog = false"
                  >
                    CANCEL
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="onConfirmDestroy"
                  >
                    DELETE
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  props: {
    subject: {
      type: Object,
      required: true
    },
    hasMenu: {
      type: Boolean,
      default: true
    },
    itemName: {
      type: String,
      default: "name"
    },
    itemReadonly: {
      type: String,
      default: "computed"
    },
    allowReadonlyOverride: {
      type: Boolean,
      default: false
    },
    subheading: {
      type: String,
      default: ""
    },
    rename: {
      type: Boolean,
      default: false
    },
    renameLabel: {
      type: String,
      default: "Class"
    },
    renameProperty: {
      type: String,
      default: ""
    },
    renameHint: {
      type: String,
      default: ""
    },
    showHideInstanceVariables: {
      type: Boolean,
      default: false
    },
    showHideSubroutines: {
      type: Boolean,
      default: false
    },
    showInstanceVariablesValue: {
      type: Boolean,
      default: false
    },
    showSubroutinesValue: {
      type: Boolean,
      default: false
    },
    renameErrorMessages: {
      type: Array,
      default: function () {
        return [];
      }
    },
    licenseAssetDestroyFn: {
      type: Function,
      default: function (destroyFn) {
        destroyFn();
      }
    }
  },
  data () {
    return {
      openMenu: false,
      openRenameDialog: false,
      openDeleteDialog: false
    };
  },
  watch: {
    openRenameDialog (value) {
      if (!value) {
        this.openMenu = false;
      }
    },
    openDeleteDialog (value) {
      if (!value) {
        this.openMenu = false;
      }
    }
  },
  methods: {
    // beforeDestroy () {
    //   if (this.$route.query.view === this.subject.key) {
    //     this.$router.deactivateFileCard();
    //     return this.$store.getters.waitForFileCardToDeactivate;
    //   } else {
    //     return Promise.resolve();
    //   }
    // },
    onShowHideInstanceVariablesToggle () {
      this.openMenu = false;
      this.$emit("change:show-instance-variables", !this.showInstanceVariablesValue);
    },
    onShowHideSubroutinesToggle () {
      this.openMenu = false;
      this.$emit("change:show-subroutines", !this.showSubroutinesValue);
    },
    onDestroy () {
      if (this.subject.confirmDestroy) {
        this.openDeleteDialog = true;
      } else {
        this.destroySubject();
      }
    },
    onConfirmDestroy () {
      this.openDeleteDialog = false;
      return this.destroySubject();
    },
    destroySubject () {
      this.licenseAssetDestroyFn(() => {
        if ("beforeDirectDestroy" in this.subject) {
          return this.subject.beforeDirectDestroy().then(() => {
            return this.subject.destroy();
          });
        } else {
          return this.subject.destroy();
        }
      });
    },
    onKeydown (evt) {
      if (evt.code === "Enter") {
        this.openRenameDialog = false;
      }
    }
  }
};
</script>

<style lang="css" scoped>

.item-heading {
  padding: 16px 5px 16px 0;
}

.heading__label {
  margin-top: 3px;
  color: var(--primary-color);
}

.heading__flex {
  min-width: 0;
}

.subheading {
  margin-top: 6px;
}

.actions__flex {
  text-align: right;
  width: 36px;
}

.v-menu {
  display: inline-block;
}

</style>
