<template>
  <div data-test="code-area__textarea">
    <v-textarea
      ref="textArea"
      :autofocus="!value"
      spellcheck="false"
      autocapitalize="off"
      variant="outlined"
      auto-grow
      :rows="numRows"
      :disabled="disabled"
      :label="label"
      :placeholder="placeholder"
      :hint="hint"
      :error-messages="errorMessages"
      :model-value="timePaddedValue"
      @update:modelValue="onInput"
      @focus="$emit('focus')"
    >
      <template v-slot:append-inner>
        <div
          class="d-flex align-start justify-start"
        >
          <v-tooltip
            v-if="!!dialogHelp && (showPlaceholderIcon || destroyable)"
            :disabled="!showPlaceholderIcon"
            bottom
          >
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                variant="text"
                size="small"
                :icon="appendIcon"
                @click="onAppendClick"
              >
              </v-btn>
            </template>
            Paste placeholder
          </v-tooltip>
          <v-tooltip
            v-if="!!dialogHelp || showPlaceholderIcon || destroyable"
            :disabled="!!dialogHelp || !showPlaceholderIcon"
            bottom
          >
            <template v-slot:activator="{ props }">
              <v-btn
                data-test="code-area__append-outter-btn"
                v-bind="props"
                variant="text"
                size="small"
                :icon="dialogHelp ? 'mdi-help-circle-outline' : appendIcon"
                @click="onAppendOuterClick"
              >
              </v-btn>
            </template>
            Paste placeholder
          </v-tooltip>
        </div>
      </template>
    </v-textarea>
  </div>
</template>

<script>
import debounce from "lodash.debounce";

const DEBOUNCE_TIME = 100;

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: " "
    },
    numRows: {
      type: Number,
      default: 5
    },
    value: {
      type: String,
      default: ""
    },
    beforeInputEvent: {
      type: Function,
      default: function () { return Promise.resolve(); }
    },
    hint: {
      type: String,
      required: true
    },
    rules: {
      type: Array,
      default: function () { return []; }
    },
    destroyable: {
      type: Boolean,
      default: false
    },
    dialogHelp: {
      type: String
    }
  },
  data () {
    return {
      destroying: false,
      textareaBlank: true,
      errorMessages: [],
      latestValue: "",
      showLatestValue: false
    };
  },
  emits: ["focus", "input", "destroy", "valid"],
  watch: {
    value: {
      handler: function (value) {
        this.textareaBlank = !value;
        if (this.beforeInputEventCompleted && (value === this.latestValue)) {
          this.showLatestValue = false;
        }
      },
      immediate: true
    }
  },
  created () {
    this.firstInput = true;
    this.beforeInputEventCompleted = false;
    this.debouncedValidate = debounce((value) => {
      this.errorMessages = this.rules.map(rule => rule(value)).filter(errorMessage => errorMessage !== true);
      if (this.errorMessages.length === 0) {
        this.$emit("valid", true);
      }
    }, DEBOUNCE_TIME * 2);
  },
  computed: {
    appendIcon () {
      if (this.showPlaceholderIcon) {
        return "mdi-flip-to-front";
      } else if (this.destroyable) {
        return "mdi-delete-outline";
      } else {
        return "";
      }
    },
    showPlaceholderIcon () {
      return this.textareaBlank && this.placeholder.trim().length;
    },
    timePaddedValue () {
      return this.showLatestValue ? this.latestValue : this.value;
    }
  },
  methods: {
    onInput (value) {
      this.textareaBlank = !value;
      this.$emit("valid", false);
      this.latestValue = value;

      if (this.firstInput) {
        this.firstInput = false;
        this.showLatestValue = true;
        this.bufferedInputEvents = this.beforeInputEvent().then(() => {
          this.beforeInputEventCompleted = true;
        });
      }

      this.bufferedInputEvents = this.bufferedInputEvents.then(() => {
        this.$emit("input", value);
      });

      this.debouncedValidate(value);
    },
    onAppendClick () {
      if (this.showPlaceholderIcon) {
        this.onClickCopyPlaceholder();
      } else if (this.destroyable && !this.destroying) {
        this.destroying = true;
        this.$emit("destroy");
      }
    },
    onClickCopyPlaceholder () {
      this.onInput(this.placeholder);
    },
    onAppendOuterClick () {
      if (this.dialogHelp) {
        this.$dialog.override(this.dialogHelp, "toolDialog");
      } else {
        this.onAppendClick();
      }
    },
    focus () {
      this.$refs.textArea.focus();
    }
  }
};

</script>

<style lang="css" scoped>

:deep(.v-textarea .v-input__control) {
  background-color: white !important;
}

:deep(.v-textarea textarea) {
  font-family: monospace, monospace;
  font-size: 14px;
  line-height: 22px;
  white-space: pre;
  overflow-wrap: normal;
  max-height: 260px;
}

:deep(.v-field--appended) {
  -webkit-padding-end: 0;
  padding-inline-end: 0;
}

:deep(.v-textarea .v-field__append-inner) {
  padding-top: 0;
  position: absolute;
  top: 4px;
  right: 4px;
}

</style>
