<template>
  <standard-layout>
    <article-container :article="blog" />
  </standard-layout>
</template>

<script>
import StandardLayout from "@/components/standard-layout.vue";
import ArticleContainer from "@/components/article-container.vue";

export default {
  components: {
    StandardLayout,
    ArticleContainer
  },
  created () {
    this.blogs = __APP_MARKDOWN_INFO__.blogs; // eslint-disable-line no-undef
  },
  data () {
    return {};
  },
  computed: {
    blog () {
      return this.blogs[this.$route.params.id];
    }
  }
};
</script>

<style lang="css" scoped>
</style>
