<template>
  <div v-if="cartResult">
    <v-row v-if="user.priceSheet">
      <v-col class="mb-8" cols="12" md="6">
        <div class="step-subheading">{{ isUpgradeOrder ? 'License upgrade parameters' : 'License parameters' }}</div>

        <v-table
          class="mt-6"
          style="max-width: 350px"
          density="compact"
        >
          <template v-slot:default>
            <thead v-if="false">
              <tr>
                <th>
                  Feature
                </th>
                <th>
                  Quantity
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(feature, featureIndex) in user.priceSheet.features"
                :key="featureIndex"
              >
                <td>{{ titles[feature.id] }}</td>
                <td v-if="completed">
                  {{ cart.quantities.find(quantity => quantity.id === feature.id).quantity }}
                </td>
                <td v-else>
                  {{ cart[feature.id + "Quantity"] }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </v-col>
      <v-col
        class="mb-8"
        :class="{ 'd-flex': !completed, 'justify-md-end': !completed }"
        cols="12"
        md="6"
      >
        <div>
          <div class="step-subheading">{{ completed ? "Payment" : "Order" }} summary</div>
          <v-table
            class="mt-6"
            style="max-width: 445px"
            density="compact"
          >
            <template v-slot:default>
              <thead v-if="false">
                <tr>
                  <th>
                    Order summary
                  </th>
                  <th>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border-bottom-style: none;">{{ paymentMethodType }}</td>
                  <td style="border-bottom-style: none;">{{ paymentMethodDescription }}</td>
                </tr>
                <tr>
                  <td style="border-bottom-style: none;">{{ licenseDurationInMonths }}-month license discount</td>
                  <td
                    style="border-bottom-style: none; color: #00C853"
                  >
                    -{{ $stripeManager.formatPrice(cartResult.globalDiscountFullTerm) }}
                  </td>
                </tr>
                <tr v-if="cartResult.customDiscountFullTerm">
                  <td style="border-bottom-style: none;">Custom discount</td>
                  <td
                    style="border-bottom-style: none; color: #00C853"
                  >
                    -{{ $stripeManager.formatPrice(cartResult.customDiscountFullTerm) }}
                  </td>
                </tr>
                <tr v-if="cartResult.renewalDiscountFullTerm">
                  <td style="border-bottom-style: none;">Custom renewal discount</td>
                  <td
                    style="border-bottom-style: none; color: #00C853"
                  >
                    -{{ $stripeManager.formatPrice(cartResult.renewalDiscountFullTerm) }}
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom-style: none;">Total before tax</td>
                  <td style="border-bottom-style: none;">{{ $stripeManager.formatPrice(isRenewalOrder ? cartResult.priceWithRenewalDiscountFullTerm : cartResult.priceWithGlobalAndCustomDiscountFullTerm) }}</td>
                </tr>
                <tr v-if="isUpgradeOrder && (cartResult.priceWithGlobalAndCustomDiscountFullTerm !== cartResult.priceWithGlobalAndCustomDiscountProrated)">
                  <td style="border-bottom-style: none;">Prorated for {{ cartResult.proratedDurationInMonths }} months left in term</td>
                  <td style="border-bottom-style: none;">{{ $stripeManager.formatPrice(cartResult.priceWithGlobalAndCustomDiscountProrated) }}</td>
                </tr>
                <tr>
                  <td>Tax to be collected</td>
                  <td>{{ $stripeManager.formatPrice(cartResult["tax" + term]) }}</td>
                </tr>
                <tr>
                  <td>Order total</td>
                  <td>{{ $stripeManager.formatPrice(cartResult["priceWithTax" + term]) }}</td>
                </tr>
              </tbody>
            </template>
          </v-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import featureTitles from "@/utils/feature-titles";

export default {
  components: {
  },
  props: {
    cart: {
      type: Object,
      required: true
    },
    paymentMethodType: {
      type: String,
      required: true
    },
    paymentMethodDescription: {
      type: String,
      required: true
    },
    licenseDurationInMonths: {
      type: Number,
      required: true
    },
    completed: {
      type: Boolean,
      default: false
    }
  },
  inject: ["$stripeManager"],
  data () {
    return {
    };
  },
  created () {
    this.titles = featureTitles;
  },
  computed: {
    user () {
      return this.$store.getters.user;
    },
    isUpgradeOrder () {
      return this.cart.orderType === "upgrade";
    },
    isRenewalOrder () {
      return this.cart.orderType === "renewal";
    },
    term () {
      return this.isUpgradeOrder ? "Prorated" : "FullTerm";
    },
    cartResult () {
      return this.completed ? this.cart : this.cart.result;
    }
  }
};
</script>

<style lang="css" scoped>
</style>
