<template>
  <v-container :class="`markdown markdown--${name}`">
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: "markdown-wrapper",
  props: {
    name: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="css" scoped>

.markdown {
  max-width: 750px;
}

:deep(p),
:deep(ul),
:deep(ol) {
  padding-bottom: 24px;
}

:deep(ul),
:deep(ol) {
  padding-left: 36px;
}

</style>
