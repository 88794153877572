<template>
  <dialog-layout
    title="Send feedback"
    :fullscreen="fullscreen"
    :navIcon="navIcon"
    :dark="dark"
    @pop-dialog="$emit('pop-dialog')"
  >

    <v-list expand lines="three">
      <v-list-item title="" @click="copyOrNav('https://github.com/uvmgen/issues/issues')" prepend-icon="mdi-github">
        <v-list-item-title>Issues</v-list-item-title>
        <v-list-item-subtitle>For general support, discussion, enhancement requests and filing bugs, please submit an issue on github.</v-list-item-subtitle>
      </v-list-item>

      <v-list-item title="" @click="copyOrNav('https://github.com/uvmgen/cheatsheets')" prepend-icon="mdi-clipboard-text">
        <v-list-item-title>Cheat sheets</v-list-item-title>
        <v-list-item-subtitle>To contribute to cheat sheets, fork the uvmgen/cheatsheets repo and submit a PR.</v-list-item-subtitle>
      </v-list-item>

      <v-list-item title="" @click="copyToClipboard('feedback@uvmgen.com')" prepend-icon="mdi-email">
        <v-list-item-title>Email</v-list-item-title>
        <v-list-item-subtitle>To send other types of feedback, please email us.</v-list-item-subtitle>
        <v-list-item-subtitle>feedback@uvmgen.com</v-list-item-subtitle>
      </v-list-item>
    </v-list>

  </dialog-layout>
</template>

<script>
import { DialogLayout } from "vue-app-components";

export default {
  name: "dialog-send-feedback",
  components: {
    DialogLayout
  },
  props: {
    fullscreen: {
      type: Boolean,
      required: true
    },
    navIcon: {
      type: String,
      required: true
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    copyOrNav (url) {
      if (this.$store.getters.isDesktop) {
        this.copyToClipboard(url);
      } else {
        window.open(url, "_blank").focus();
      }
    },
    copyToClipboard (content) {
      navigator.clipboard.writeText(content);
      this.$store.commit("pushSnackbarMessageProps", {
        message: `'${content}' copied to clipboard.`
      });
    }
  }
};
</script>

<style lang="css" scoped>
</style>
