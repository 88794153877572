<template>
  <div>
    <v-icon
      v-if="column.type === 'icon'"
      size="20"
    >
      {{ subject[column.key] }}
    </v-icon>
    <div
      v-else-if="column.type === 'readonly'"
      class="field ellipsis"
    >
      {{ subject[column.key] }}&nbsp;
    </div>
    <data-input-table-checkbox
      v-else-if="isCheckbox"
      :subject="subject"
      :column="column"
    />
    <data-input-table-toggle-button
      v-else-if="isToggleButton"
      :subject="subject"
      :column="column"
    />
    <data-input-table-select-button
      v-else-if="isSelectButton"
      :subject="subject"
      :column="column"
    />
    <data-input-table-menu
      v-else
      ref="menu"
      :subject="subject"
      :column="column"
      @tabKey="$emit('tabKey')"
    />
  </div>
</template>

<script>
import DataInputTableCheckbox from "@/components/data-input-table-checkbox.vue";
import DataInputTableToggleButton from "@/components/data-input-table-toggle-button.vue";
import DataInputTableSelectButton from "@/components/data-input-table-select-button.vue";
import DataInputTableMenu from "@/components/data-input-table-menu.vue";

export default {
  components: {
    DataInputTableCheckbox,
    DataInputTableToggleButton,
    DataInputTableSelectButton,
    DataInputTableMenu
  },
  props: {
    subject: {
      type: Object,
      required: true
    },
    column: {
      type: Object,
      required: true
    }
  },
  computed: {
    isCheckbox () {
      return this.column.type === "checkbox";
    },
    isToggleButton () {
      return this.column.type === "toggle-button";
    },
    isSelectButton () {
      return this.column.type === "select-button";
    }
  },
  methods: {
    open () {
      if (this.$refs.menu) {
        this.$refs.menu.open();
      } else {
        this.$emit("tabKey");
      }
    }
  }
};

</script>

<style lang="css" scoped>

.field {
  width: 100%;
}

</style>
