<template>
  <standard-layout
    title="UVMGen licensing"
    has-container
  >
    <div
      v-if="payment && (payment.success || payment.processing || payment.failed || payment.refunded)"
      class="mt-4 mt-md-16 pb-16"
    >
      <div class="text-h5">
        {{ payment.fullType }}
        <span v-if="payment.success">
          completed!
        </span>
        <span v-else-if="payment.processing">
          processing...
        </span>
        <span v-else-if="payment.failed">
          failed.
        </span>
        <span v-else-if="payment.refunded">
          refunded.
        </span>
      </div>
      <div
        v-if="payment.processing"
        class="mt-8"
      >
        You will receive an email when the ACH debit completes. This typically takes 4 business days.
      </div>

      <div
        v-if="payment.errorMessage"
        class="mt-2 error--text"
      >
        {{ payment.errorMessage }}
      </div>

      <div class="mt-10 step-subheading">Term</div>
      <div class="mt-4 ml-4">
        {{ formatDate(payment.licenseTermStartDate) }} - {{ formatDate(payment.licenseTermEndDate) }}
      </div>

      <order-review
        v-if="('priceWithTaxFullTerm' in payment.cart) && !isNaN(payment.cart.priceWithTaxFullTerm) && license"
        class="mt-12"
        :cart="payment.cart"
        :payment-method-type="paymentMethodType"
        :payment-method-description="paymentMethodDescription"
        :licenseDurationInMonths="license.durationInMonths"
        completed
      />

      <div v-if="license && license.active && payment.isInitialOrder">
        <div class="mt-10 step-subheading">Next steps</div>

        <on-premise-installation-stepper
          v-if="user.privateProfile && user.privateProfile.readonlyProfile && user.privateProfile.readonlyProfile.hasActiveLicense"
          class="mt-4 mx-0"
          :license="license"
        />
      </div>
      <div
        v-if="!payment.isInitialOrder"
      >
        <v-btn
          class="mt-16"
          color="primary"
          @click="goToLicense()"
        >VIEW LICENSE</v-btn>
      </div>
    </div>
    <div
      v-else-if="payment && loadTimeExpired"
      class="mt-4 mt-md-16 pb-16"
    >
      <div
        class="text-h5"
      >
        {{ payment.fullType }} in unexpected state.
      </div>

      <div
        v-if="!payment.success"
        class="mt-2 error--text"
      >
        The status of your order is "{{ payment.stripePaymentIntent.status }}". This is not expected.
      </div>
      <div
        v-if="payment.errorMessage"
        class="mt-2 error--text"
      >
        {{ payment.errorMessage }}
      </div>
    </div>
    <div
      v-else-if="!payment && loadTimeExpired"
      class="mt-4 mt-md-16 pb-16"
    >
      <div class="text-h5">
        Order not found
      </div>
    </div>
    <v-container
      v-else
      class="center-vh"
    >
      <div class="d-flex fill-height align-center justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
          size="48"
        />
      </div>
    </v-container>
  </standard-layout>
</template>

<script>
import StandardLayout from "@/components/standard-layout.vue";
import OrderReview from "@/components/order-review.vue";
import OnPremiseInstallationStepper from "@/components/on-premise-installation-stepper.vue";
import { formatDate } from "vue-app-utils";

export default {
  components: {
    StandardLayout,
    OrderReview,
    OnPremiseInstallationStepper
  },
  data () {
    return {
      loadTimeExpired: false
    };
  },
  created () {
    this.formatDate = formatDate;

    const unwatch = this.$watch("license", (license) => {
      if (license) {
        if (this.payment.isRenewalOrder) {
          license.lazyCartCalled = false;
          license.lazyCart = null;
        } else {
          this.user.lazyCartCalled = false;
          this.user.lazyCart = null;
        }
        this.$nextTick(() => { unwatch(); });
      }
    }, { immediate: true });
  },
  mounted () {
    setTimeout(() => {
      this.loadTimeExpired = true;
    }, 10000);
  },
  computed: {
    user () {
      return this.$store.getters.user;
    },
    license () {
      return this.payment
        ? this.user.licenses.find(license => license.id === this.payment.licenseId)
        : null;
    },
    payment () {
      return this.user.payments.find(payment => payment.id === this.$route.params.id);
    },
    paymentMethodType () {
      const type = this.payment.stripePaymentMethod.type;
      if (type) {
        return (type === "card") ? "Card" : "Account";
      } else {
        return "";
      }
    },
    paymentMethodDescription () {
      if (this.paymentMethodType === "Card") {
        const details = this.payment.stripePaymentMethod.card;
        return `${details.brand.toUpperCase()} (** ${details.last4})`;
      } else if (this.paymentMethodType === "Account") {
        const details = this.payment.stripePaymentMethod.us_bank_account;
        let type = details.account_type;
        type = type.slice(0, 1).toUpperCase() + type.slice(1);
        return `${type} (**${details.last4})`;
      } else {
        return "";
      }
    }
  },
  methods: {
    goToLicense () {
      this.$router.push({ name: "licensesShow", params: { id: this.payment.licenseId } })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    }
  }
};
</script>

<style lang="css" scoped>
</style>
