<template>
  <v-expansion-panels
    data-test="code-panels"
    variant="accordion"
    :model-value="subject.panel"
    @update:modelValue="onPanelUpdate"
  >
    <v-expansion-panel
      v-for="item in items"
      :key="item.overrideId || item.id"
    >
      <v-expansion-panel-title>
        <div
          class="ellipsis"
        >
          {{ item.prototype || item.todo }}
        </div>
        <template v-slot:actions="{ expanded }">
          <v-badge
            style="max-width: calc(100% - 40px);"
            :model-value="item.needsAttention"
            color="error"
            dot
          >
            <v-icon v-if="expanded">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-badge>
        </template>
      </v-expansion-panel-title>

      <v-expansion-panel-text eager>
        <div class="expand-content">
          <code-area
            class="mt-8"
            ref="addItemsCodeArea"
            :label="item.identifier || itemNameUpper"
            :placeholder="item.placeholder || newItemPlaceholder"
            :value="item.content"
            hint=""
            :rules="[item.validateContent.bind(item)]"
            :dialogHelp="item.cheatSheet"
            :before-input-event="item.createPersistable && item.createPersistable.bind(item)"
            @input="item.update({ content: $event })"
            :destroyable="!item.computed && !item.overriddenSubroutine"
            @destroy="destroy(item)"
          />
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>

    <v-expansion-panel class="add-panel" disabled>
      <v-expansion-panel-title hide-actions>
        <v-btn
          class="add-panel__button"
          color="primary"
          variant="text"
          @click="addPanel"
        >ADD</v-btn>
      </v-expansion-panel-title>
    </v-expansion-panel>

  </v-expansion-panels>
</template>

<script>
import CodeArea from "@/components/code-area.vue";
import pluralize from "pluralize-esm";

export default {
  components: {
    CodeArea
  },
  props: {
    subject: {
      type: Object,
      required: true
    },
    getCollectionProperty: {
      type: String,
      required: true
    },
    setCollectionProperty: {
      type: String,
      required: true
    },
    collectionName: {
      type: String,
      required: true
    },
    newItemPlaceholder: {
      type: String,
      required: true
    }
  },
  created () {
    this.itemName = pluralize.singular(this.collectionName);
    this.itemNameUpper = this.itemName.charAt(0).toUpperCase() + this.itemName.substr(1);
    const setItemName = pluralize.singular(this.setCollectionProperty);
    this.className = setItemName.charAt(0).toUpperCase() + setItemName.substr(1);
    this.createMethod = `create${this.className}`;
  },
  data () {
    return {};
  },
  watch: {
    items (items) {
      if (this.addingPanel) {
        this.addingPanel = false;
        this.subject.panel = items.length - 1; // eslint-disable-line vue/no-mutating-props
      }
    }
  },
  computed: {
    items () {
      return this.subject[this.getCollectionProperty];
    }
  },
  methods: {
    onPanelUpdate (panel) {
      this.subject.panel = panel; // eslint-disable-line vue/no-mutating-props
    },
    addPanel () {
      this.addingPanel = true;
      return this.subject[this.createMethod]({});
    },
    destroy (subroutine) {
      return this.$store.getters.waitForZeroTransactions.then(() => subroutine.destroy());
    }
  }
};
</script>

<style lang="css" scoped>

.v-expansion-panels {
  clip-path: inset(-15px 0 -15px 0);
}

.expand-content {
  margin: 0 0 0 7px;
}

:deep(.v-expansion-panel-title) {
  padding-right: 12px;
  min-height: 52px;
}

:deep(.v-expansion-panel-text__wrapper) {
  background-color: var(--background-color);
  padding: 0 0 12px 0;
}

:deep(.add-panel .v-expansion-panel-title) {
  padding: 1px 0;
}

:deep(.v-expansion-panel-title__overlay) {
  background-color: transparent;
}

.add-panel__button {
  pointer-events: auto;
  padding: 0;
  margin: 5px 0 5px 6px;
  min-width: 78px;
}

</style>
