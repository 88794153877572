<template>
  <div
    v-if="tb.env && tb.env.clkFrequencyPolicies.length && frequencyUnitOptions.length && tb.runWithResetsPolicy && tb.env.rstPulseLengthPolicies.length && tb.env.rstIntervalPolicies.length && tb.mainVirtualSequence && tb.mainVirtualSequence.numTransactionVariables.length"
  >
    <div
      v-for="clkFrequencyPolicy in tb.env.clkFrequencyPolicies"
      :key="clkFrequencyPolicy.key"
    >
      <div class="step-subheading mb-4">Clock {{ clkFrequencyPolicy.uvcInstance.suffix }}</div>

      <v-layout
        align-start
        justify-end
      >
        <v-col class="frequency-slider__flex">
          <v-slider
            :min="1"
            :max="Math.min(99999, +('1' + '0'.repeat(`${clkFrequencyPolicy.freq}`.length)))"
            :step="1"
            thumb-label
            direction="vertical"
            :model-value="clkFrequencyPolicy.freq"
            @end="clkFrequencyPolicy.update({ freq: $event })"
            @mousedown="$router.activateFileCard(clkFrequencyPolicy.key)"
          >
            <template v-slot:append>
            </template>
          </v-slider>
        </v-col>
        <v-col class="frequency-text-fields__flex">
          <v-row class="frequency-text-fields__row">
            <v-col cols="12" sm="4">
              <v-text-field
                style="width: 100%;"
                variant="underlined"
                label="Frequency"
                type="number"
                :model-value="clkFrequencyPolicy.freq"
                @update:modelValue="clkFrequencyPolicy.update({ freq: $event })"
                @click="$router.activateFileCard(clkFrequencyPolicy.key)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <v-select
                style="width: 100%;"
                variant="underlined"
                :items="frequencyUnitOptions"
                label="Unit"
                :model-value="clkFrequencyPolicy.unit"
                @update:modelValue="clkFrequencyPolicy.update({ unit: $event })"
                @click="$router.activateFileCard(clkFrequencyPolicy.key)"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pr-0">
              <div data-test="slider">
                <v-list-subheader class="pl-0">Duty cycle</v-list-subheader>
                <v-slider
                  class="duty-cycle__slider"
                  :model-value="clkFrequencyPolicy.dutyCycle / 100"
                  :min="0.1"
                  :max="0.99"
                  :step="0.01"
                  thumb-label
                  @end="clkFrequencyPolicy.update({ dutyCycle: Math.round($event * 100) })"
                  @mousedown="$router.activateFileCard(clkFrequencyPolicy.key)"
                >
                  <template v-slot:append>
                    <v-text-field
                      class="slider__text-field"
                      variant="underlined"
                      type="number"
                      step="0.01"
                      :model-value="clkFrequencyPolicy.dutyCycle / 100"
                      @update:modelValue="clkFrequencyPolicy.update({ dutyCycle: Math.round($event * 100) })"
                    ></v-text-field>
                  </template>
                </v-slider>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-layout>
    </div>

    <div class="step-subheading mt-8 mb-2">Reset</div>
    <div data-test="slider">
      <v-list-subheader class="subheader--standard">Number of resets per test</v-list-subheader>
      <v-slider
        class="slider--standard"
        :model-value="tb.runWithResetsPolicy.numMidSimResets"
        :min="1"
        :max="tb.user.setting.edaPlayground ? config.edaPlaygroundMaxNumResetsPerTest : Math.min(99999, +('1' + '0'.repeat(`${tb.runWithResetsPolicy.numMidSimResets}`.length)))"
        :step="1"
        thumb-label
        @end="tb.runWithResetsPolicy.update({ numMidSimResetsOverride: `${$event}` })"
        @mousedown="$router.activateFileCard(tb.runWithResetsPolicy.key)"
      >
        <template v-slot:append>
          <v-text-field
            class="slider__text-field"
            variant="underlined"
            type="number"
            :step="1"
            :model-value="tb.runWithResetsPolicy.numMidSimResets"
            @update:modelValue="tb.runWithResetsPolicy.update({ numMidSimResetsOverride: `${$event}` })"
            @click="$router.activateFileCard(tb.runWithResetsPolicy.key)"
          ></v-text-field>
        </template>
      </v-slider>
    </div>

    <div
      v-for="rstPulseLengthPolicy in tb.env.rstPulseLengthPolicies"
      :key="rstPulseLengthPolicy.key"
    >
      <div data-test="slider">
        <v-list-subheader class="subheader--standard">Duration of {{rstPulseLengthPolicy.uvcInstance.suffix }} reset pulse (num clocks)</v-list-subheader>
        <v-range-slider
          :model-value="[rstPulseLengthPolicy.lengthMin, rstPulseLengthPolicy.lengthMax]"
          :min="1"
          :max="Math.min(99999, +rstPulseLengthPolicy.lengthMax * 1.5)"
          :step="1"
          thumb-label
          @end="rstPulseLengthPolicy.update({ lengthMin: `${$event[0]}`, lengthMax: `${$event[1]}` })"
          @mousedown="$router.activateFileCard(rstPulseLengthPolicy.key)"
        >
          <template v-slot:prepend>
            <v-text-field
              class="slider__text-field"
              variant="underlined"
              type="number"
              :model-value="rstPulseLengthPolicy.lengthMin"
              @update:modelValue="rstPulseLengthPolicy.update({ lengthMin: `${$event}` })"
              @click="$router.activateFileCard(rstPulseLengthPolicy.key)"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
              class="slider__text-field"
              variant="underlined"
              type="number"
              :model-value="rstPulseLengthPolicy.lengthMax"
              @update:modelValue="rstPulseLengthPolicy.update({ lengthMax: `${$event}` })"
              @click="$router.activateFileCard(rstPulseLengthPolicy.key)"
            ></v-text-field>
          </template>
        </v-range-slider>
      </div>
    </div>

    <div class="step-subheading mt-8 mb-2">Transactions</div>

    <div
      v-for="rstIntervalPolicy in tb.env.rstIntervalPolicies"
      :key="rstIntervalPolicy.key"
    >
      <div data-test="slider">
        <v-list-subheader class="subheader--standard">Duration of activity between {{ rstIntervalPolicy.uvcInstance.suffix }} resets (num clocks)</v-list-subheader>
        <v-range-slider
          :model-value="[rstIntervalPolicy.midSimResetDelayMin, rstIntervalPolicy.midSimResetDelayMax]"
          :min="1"
          :max="Math.min(99999, +rstIntervalPolicy.midSimResetDelayMax * 1.5)"
          :step="1"
          thumb-label
          @end="rstIntervalPolicy.update({ midSimResetDelayMinOverride: `${$event[0]}`, midSimResetDelayMaxOverride: `${$event[1]}` })"
          @mousedown="$router.activateFileCard(rstIntervalPolicy.key)"
        >
          <template v-slot:prepend>
            <v-text-field
              class="slider__text-field"
              variant="underlined"
              type="number"
              :model-value="rstIntervalPolicy.midSimResetDelayMin"
              @update:modelValue="rstIntervalPolicy.update({ midSimResetDelayMinOverride: `${$event}` })"
              @click="$router.activateFileCard(rstIntervalPolicy.key)"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
              class="slider__text-field"
              variant="underlined"
              type="number"
              :model-value="rstIntervalPolicy.midSimResetDelayMax"
              @update:modelValue="rstIntervalPolicy.update({ midSimResetDelayMaxOverride: `${$event}` })"
              @click="$router.activateFileCard(rstIntervalPolicy.key)"
            ></v-text-field>
          </template>
        </v-range-slider>
      </div>
    </div>

    <div
      v-for="mainNumTransactionsPolicy in tb.env.mainNumTransactionsPolicies"
      :key="mainNumTransactionsPolicy.id"
    >
      <div data-test="slider">
        <v-list-subheader class="subheader--standard">Max number of {{ mainNumTransactionsPolicy.uvcInstance.uvc.name }} transactions per reset</v-list-subheader>
        <v-slider
          class="slider--standard"
          :model-value="mainNumTransactionsPolicy.value"
          :min="1"
          :max="Math.min(99999, +('1' + '0'.repeat(`${mainNumTransactionsPolicy.value}`.length)))"
          :step="1"
          thumb-label
          @end="mainNumTransactionsPolicy.update({ valueOverride: `${$event}` })"
          @mousedown="$router.activateFileCard(mainNumTransactionsPolicy.key)"
        >
          <template v-slot:append>
            <v-text-field
              class="slider__text-field"
              variant="underlined"
              type="number"
              :model-value="mainNumTransactionsPolicy.value"
              @update:modelValue="mainNumTransactionsPolicy.update({ valueOverride: `${$event}` })"
              @click="$router.activateFileCard(mainNumTransactionsPolicy.key)"
            ></v-text-field>
          </template>
        </v-slider>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import get from "lodash.get";

export default {
  props: {
    tb: {
      type: Object,
      required: true
    }
  },
  created () {
    this.config = config;
  },
  computed: {
    frequencyUnitOptions () {
      const typedef = get(this.tb, "envInstance.clkUvcInstances[0].package.typedefs", [])
        .find(typedef => typedef.name === "clk_frequency_unit_e");
      return typedef
        ? typedef.typeInfo.enumNameDeclarations.map(decl => { return { title: decl.identifier.replace(/.*_/, ""), value: decl.identifier }; }).filter(option => option.title !== "NA")
        : [];
    }
  }
};
</script>

<style lang="css" scoped>

:deep(.duty-cycle__slider.v-slider.v-input--horizontal) {
  margin-left: 0;
}

.frequency-slider__flex {
  min-width: 56px;
  max-width: 56px;
}

:deep(.frequency-slider__flex > .v-slider.v-input--vertical > .v-input__control) {
  min-height: 174px;
}

.frequency-text-fields__flex {
  min-width: 0;
  margin-left: 24px;
}

.frequency-text-fields__row {
  padding-right: 72px;
}

.slider__text-field {
  margin-top: 0;
  padding-top: 0;
  width: 60px;
}

.subheader--standard {
  margin-left: 92px;
}

.slider--standard {
  margin-left: 92px;
}

:deep(.v-range-slider) {
  margin-left: 16px;
}

</style>
