<template>
  <v-tooltip
  >
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        variant="text"
        icon="mdi-download-box-outline"
        color="primary"
        href="#0"
        @click="onClick"
      >
      </v-btn>
    </template>
    Download Software License Agreement
  </v-tooltip>
</template>

<script>

export default {
  methods: {
    async onClick (event) {
      event.preventDefault();

      const createSoftwareLicenseAgreementPdf = await import("@/utils/create-software-license-agreement-pdf");
      createSoftwareLicenseAgreementPdf.default()
        .download("UVMGen - Software License Agreement.pdf");
    }
  }
};
</script>
