<template>
  <dialog-layout
    title="Help"
    :fullscreen="fullscreen"
    :navIcon="navIcon"
    :dark="dark"
    @pop-dialog="$emit('pop-dialog')"
  >
    <template v-slot:toolbar-actions>
      <v-menu class="dialog__menu" location="bottom end">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            icon
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item title="" @click="$emit('push-dialog', 'terms-of-service')">
            <v-list-item-title>Terms of service</v-list-item-title>
          </v-list-item>

          <v-list-item title="" @click="$emit('push-dialog', 'privacy-policy')">
            <v-list-item-title>Privacy policy</v-list-item-title>
          </v-list-item>
        </v-list>

      </v-menu>
    </template>

    <v-list>

      <v-list-subheader>Contact us</v-list-subheader>

      <v-list-item title="" @click="$emit('push-dialog', 'send-feedback')" prepend-icon="mdi-message-alert">
        <v-list-item-title>
          Send feedback
        </v-list-item-title>
      </v-list-item>

      <v-list-subheader>Cheat sheets</v-list-subheader>

      <v-list-item
        v-for="cheatSheet in cheatSheets"
        :key="cheatSheet.key"
        title=""
        @click="$emit('push-dialog', cheatSheet.id)"
        prepend-icon="mdi-clipboard-text"
      >
        <v-list-item-title>
          {{ cheatSheet.title }}
        </v-list-item-title>
      </v-list-item>

    </v-list>

  </dialog-layout>
</template>

<script>
import { DialogLayout } from "vue-app-components";

export default {
  name: "dialog-help",
  components: {
    DialogLayout
  },
  props: {
    fullscreen: {
      type: Boolean,
      required: true
    },
    navIcon: {
      type: String,
      required: true
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.cheatSheets = __APP_MARKDOWN_INFO__["cheat-sheets"]; // eslint-disable-line no-undef
  }
};
</script>

<style lang="css" scoped></style>
