<template>
  <v-layout>
    <agree-to-terms-modal
      v-if="$store.getters.isWeb && $store.getters.isAuthenticated"
      @user-agreed-to-terms="$emit('user-agreed-to-terms')"
    />

    <top-nav
      :title="projectName"
      dark
    >
      <template v-if="smAndDown" v-slot:extension>
        <v-tabs
          :modelValue="tab"
          slider-color="accent"
          grow
          stacked
          @update:modelValue="updateTab"
        >

          <v-tab
            key="edit-tab"
          >
            <v-icon class="mb-1">mdi-pencil</v-icon>
            EDIT
          </v-tab>

          <v-tab
            key="code-tab"
          >
            <v-icon class="mb-1">mdi-repeat</v-icon>
            GENERATED CODE
          </v-tab>

        </v-tabs>
      </template>
    </top-nav>

    <v-main>
      <v-container
        v-if="loading"
        class="center-vh"
      >
        <div class="d-flex fill-height align-center justify-center">
          <v-progress-circular
            v-if="true"
            indeterminate
            color="primary"
            size="48"
          />
          <v-progress-linear
            v-else
            style="max-width: 150px;"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </div>
      </v-container>

      <div
        v-else-if="$store.getters.webToolEnabled"
        style="position: relative; height: 100%; width: 100%;"
      >
        <v-container
          class="edit-container"
          :class="editContainerClasses"
          fluid
        >
          <div class="edit-layout d-flex align-start justify-start">
            <div
              ref="stepperScroll"
              class="steps scrollbox scrollbox--hidden"
            >
              <div class="scrollbox-content">

                <uvmgen-stepper
                  v-if="editable"
                  class="stepper"
                  ref="stepper"
                  :value="step"
                  @change.self="updateStep"
                  vertical
                  elevation="0"
                >
                  <slot></slot>
                </uvmgen-stepper>

                <slot v-if="editable" name="after-stepper"></slot>

                <div
                  v-else
                  class="mt-16 text-center text-h5"
                >
                  <div v-if="$store.getters.isAuthenticated">
                    This project is read-only for non-owners.
                  </div>
                  <div v-else>
                    Sign in to edit.
                  </div>
                </div>

              </div>
            </div>

            <div
              class="file-viewer-container"
            >
              <div
                class="file-viewer scrollbox scrollbox--hidden"
              >
                <div class="scrollbox-content">
                  <file-grid
                    class="file-grid"
                    ref="fileGrid"
                    :project-name="projectName"
                    :root-project="rootProject"
                    :all-models="allModels"
                    :models="models"
                    :fab-disabled="fabDisabled"
                  />
                </div>
              </div>
            </div>

          </div>
        </v-container>
      </div>

      <v-container
        v-else
        class="center-vh"
      >
        <div class="d-flex fill-height align-center justify-center">
          <offline/>
        </div>
      </v-container>
    </v-main>
  </v-layout>
</template>

<script>
import UvmgenStepper from "@/components/uvmgen-stepper.vue";
import StepperMixin from "@/utils/stepper-mixin";
import AgreeToTermsModal from "@/components/agree-to-terms-modal.vue";
import TopNav from "@/components/top-nav.vue";
import FileGrid from "@/components/file-grid.vue";
import Offline from "@/components/offline.vue";
import { useDisplay } from "vuetify";

export default {
  name: "tool-layout",
  mixins: [
    StepperMixin
  ],
  components: {
    UvmgenStepper,
    AgreeToTermsModal,
    TopNav,
    FileGrid,
    Offline
  },
  props: {
    projectName: {
      type: String
    },
    rootProject: {
      type: Object,
      required: false
    },
    allModels: {
      type: Array,
      required: true
    },
    models: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    },
    fabDisabled: {
      type: Boolean,
      required: true
    }
  },
  beforeCreate () {
    document.documentElement.className = "edit";
  },
  created () {
    this.updateTimer = null;
  },
  updated () {
    this.$nextTick(() => {
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(() => {
        this.animateTabs = true;
      }, 500);
    });
  },
  data () {
    return {
      animateTabs: false
    };
  },
  computed: {
    smAndDown () {
      return useDisplay().smAndDown.value;
    },
    fileGrid () {
      return this.$refs.fileGrid;
    },
    tab () {
      return parseInt(this.$route.query.tab || "0");
    },
    editContainerClasses () {
      return {
        "animate-tabs": this.animateTabs,
        "tab-mode-left": (this.tab === 0),
        "tab-mode-right": (this.tab === 1)
      };
    }
  },
  methods: {
    scrollOnMounted () {
      if (this.editable) {
        if (this.step === undefined) {
          this.updateStep(1);
        }
        this.scrollToStep(this.step);
      }
    },
    updateTab (tab) {
      this.$router.updateQueryParams({ tab });
    }
  }
};
</script>

<style lang="css">

:root {
  --toolbar-height: 130px;
  --stepper-max-width: 720px;
}

@media all and (min-width: 961px) {
  :root {
    --toolbar-height: 64px;
    --stepper-max-width: 620px;
  }
}

</style>

<style lang="css" scoped>

.v-toolbar--fixed {
  z-index: var(--toolbar-z-index);
}

.v-toolbar__extension {
  padding-left: 0;
  padding-right: 0;
}

.edit-content,
.edit-container,
.edit-layout,
.steps,
.file-viewer-container,
.file-viewer {
  height: 100%;
  width: 100%;
}

.file-viewer-container {
  position: relative;
}

.edit-container {
  padding: 0;
  background-color: var(--background-color);
}

.stepper {
  padding-left: 9px;
}

.file-grid {
  padding: 24px;
}

.steps {
  padding-right: 24px;
}

@media all and (min-width: 961px) {
  .steps {
    width: var(--stepper-max-width);
  }
  .file-viewer-container {
    width: calc(100% - var(--stepper-max-width));
    min-width: 460px;
  }
}

@media all and (min-width: 0px) and (max-width: 960px) {

  .stepper {
    left: -8px;
  }

  .steps,
  .file-viewer-container {
    max-width: 50%;
  }

  .edit-container.tab-mode-left,
  .edit-container.tab-mode-right {
    min-width: 200%;
    position: absolute;
  }

  .tab-mode-left {
    left: 0;
  }

  .tab-mode-right {
    left: -100%;
  }

  .animate-tabs.tab-mode-left,
  .animate-tabs.tab-mode-right {
    transition: left 0.18s ease-in;
  }

}

@media all and (min-width: 1264px) {
  .file-viewer-container {
    max-width: none;
  }
  .file-grid {
    padding: 24px 72px 24px 56px;
  }
}

.stepper {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  max-width: var(--stepper-max-width);
}

</style>
