<template>
  <div
    v-if="randomObjectDoHandle"
    class="policy-select"
  >
    <v-select
      class="mt-3"
      ref="select"
      variant="underlined"
      :items="items"
      item-title="type"
      item-value="uniqueId"
      :no-data-text="`No policies for ${randomObjectDoHandle.type}`"
      return-object
      :menu-props="{ allowOverflow: true, offsetOverflow: true, contentClass: 'menu-add-policy--' + randomObjectDoHandle.identifier }"
      multiple
      chips
      closable-chips
      :label="`Constrains ${randomObjectDoHandle.type} ${randomObjectDoHandle.identifier} (${randomObjectDoHandle.parent.type})`"
      :persistent-hint="selectedItems.length === 0"
      hint="Select or create a new policy"
      :model-value="selectedItems"
      @update:modelValue="addRemovePolicy($event)"
    >
      <template v-slot:append-item>
        <v-divider class="mb-2"></v-divider>
        <v-list-item
          title=""
          @click="addPolicy"
        >
          <template v-slot:prepend>
            <v-btn
              class="select-menu__add-btn"
              variant="text"
              prepend-icon="mdi-plus"
            >
              ADD
            </v-btn>
          </template>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
import parseSystemVerilog from "@/utils/parse-system-verilog";
import get from "lodash.get";

export default {
  props: {
    rootProject: {
      type: Object,
      required: true
    },
    klass: {
      type: Object,
      required: true
    },
    isNeutral: {
      type: Boolean,
      default: true
    },
    randomObjectDoHandle: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      errorMessages: []
    };
  },
  computed: {
    policies () {
      if (this.isNeutral) {
        return get(this.rootProject.package, "allPolicies", []);
      } else {
        return this.klass.isMaster
          ? get(this.rootProject.package, "masterPolicies", [])
          : get(this.rootProject.package, "slavePolicies", []);
      }
    },
    items () {
      const policies = this.policies
        .filter(policy => policy.subject.uniqueId === this.randomObjectDoHandle.klass.uniqueId);
      return this.toItems(policies);
    },
    selectedItems () {
      const policies = this.klass.policyHandles
        .filter(handle => handle.relations.randomObjectDoHandle.id === this.randomObjectDoHandle.id)
        .map(handle => handle.klass);
      return this.toItems(policies);
    }
  },
  watch: {
    items () {
      if (this.waitForFormPolicyId) {
        const item = this.items.find(item => item.policy.id === this.waitForFormPolicyId);
        if (item) {
          this.waitForFormPolicyId = null;
          this.klass.addPolicy(item.policy, this.randomObjectDoHandle);
        }
      }
    }
  },
  methods: {
    toItems (policies) {
      return policies.map(policy => {
        let type = policy.type;
        const superclass = this.klass.superclasses.find(superclass => !!superclass.allPolicyHandles.find(handle => handle.klass === policy));
        if (superclass) {
          type = type + ` (applied by ${superclass.type})`;
        }
        return { type, uniqueId: policy.uniqueId, disabled: !!superclass, policy };
      });
    },
    addPolicy () {
      const menu = this.$refs.select.$el.getElementsByTagName("input")[0];
      menu.blur();
      setTimeout(() => {
        this.$emit("add-policy");
      }, 100);
    },
    addRemovePolicy (items) {
      const updatedPolicies = items.map(item => item.policy);
      const currentPolicies = this.selectedItems.map(item => item.policy);

      updatedPolicies.forEach(updatedPolicy => {
        if (!currentPolicies.includes(updatedPolicy)) {
          this.klass.addPolicy(updatedPolicy, this.randomObjectDoHandle);
        }
      });
      currentPolicies.forEach(currentPolicy => {
        if (!updatedPolicies.includes(currentPolicy)) {
          this.klass.removePolicy(currentPolicy, this.randomObjectDoHandle);
        }
      });
    },
    onInputOfClassIdentifier (event) {
      let type = null;
      const errors = [];

      try {
        type = this.parseClassIdentifier(event.target.value);
      } catch (e) {
        errors.push(e.message);
      }
      if (!type.match(/_c$/)) {
        errors.push("Must end in '_c'");
      }
      this.errorMessages = errors;
    },
    parseClassIdentifier (value) {
      return parseSystemVerilog(value, "ClassIdentifier");
    }
  }
};
</script>

<style lang="css" scoped>

.v-select {
  max-width: 440px;
}

.select-menu__add-btn {
  color: var(--primary-color);
}

.new-policy__text-field {
  margin-top: 0;
  padding-top: 0;
}

</style>
