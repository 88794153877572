<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>

export default {
  created () {
    this.isUvmGenStepper = true;
  }
};

</script>
