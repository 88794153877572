<template>
  <div>

    <section class="banner">
      <v-container>
        <h1 class="heading font-weight-bold">
          {{ article.title }}
          <v-btn
            v-if="!!editableAt"
            icon="mdi-pencil"
            :href="editableAt"
          >
          </v-btn>
        </h1>
      </v-container>
    </section>
    <markdown-wrapper name="test-blog">
      <component
        class="mt-4"
        v-bind:is="'md-' + article.key"
        @link-clicked="articleClicked"
      >
      </component>
    </markdown-wrapper>
  </div>
</template>

<script>
import MarkdownWrapper from "../markdown/markdown-wrapper.vue";

export default {
  name: "article-container",
  components: {
    MarkdownWrapper
  },
  props: {
    article: {
      type: Object,
      required: true
    },
    editableAt: {
      type: String,
      required: false
    }
  },
  data () {
    return {};
  },
  methods: {
    articleClicked (article) {
      this.$router.push({ path: article });
    }
  }
};
</script>

<style lang="css" scoped>

section.banner {
  height: 230px;
  max-width: none;
  background: rgb(255,235,59);
  background: linear-gradient(130deg, rgba(255,235,59,1) 15%, rgba(251,140,0,1) 90%);
  overflow: hidden;
}

section.banner {
  padding-top: 120px;
}

</style>
