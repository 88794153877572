<template>
  <code-area
    class="constraints-code-area pb-4"
    :disabled="!constraint.instanceVariable.isRandom"
    :label="constraint.parent.type"
    :placeholder="constraint.placeholder"
    :num-rows="0"
    :value="constraint.content"
    hint="Add constraints."
    dialog-help="constraints"
    :before-input-event="constraint.createPersistable && constraint.createPersistable.bind(constraint)"
    @input="constraint.update({ content: $event })"
    :destroyable="!constraint.instanceVariable.computed"
    @destroy="destroy"
    @focus="$router.activateFileCard(constraint.parent.key)"
  >
  </code-area>
</template>

<script>
import CodeArea from "@/components/code-area.vue";

export default {
  components: {
    CodeArea
  },
  props: {
    constraint: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    };
  },
  computed: {
  },
  methods: {
    destroy () {
      return this.$store.getters.waitForZeroTransactions.then(() => this.constraint.immediateUpdate({ content: "", deleted: true }));
    }
  }
};

</script>

<style lang="css" scoped>

.constraints-code-area {
  margin-top: 0 !important;
}

</style>
