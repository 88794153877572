<template>
  <div class="uvmgen-stepper-content">
    <vue3-slide-up-down :model-value="expanded">
      <slot></slot>
    </vue3-slide-up-down>
  </div>
</template>

<script>

export default {
  props: {
    step: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    expanded () {
      return +this.$route.query.step === this.step;
    }
  }
};

</script>

<style scoped>

.uvmgen-stepper-content {
  border-left: solid 2px rgba(0,0,0,0.13);
  margin-left: 27px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

</style>
