<template>
  <div>
    <v-tabs
      v-model="tab"
      center-active
      fixed-tabs
      background-color="transparent"
    >

      <v-tab>
        BASICS
      </v-tab>
      <v-tab>
        RANDOMIZATION
      </v-tab>
      <v-tab v-if="hasCoverage">
        COVERAGE
      </v-tab>
    </v-tabs>
    <v-window
      v-model="tab"
      class="pt-2"
    >
      <v-window-item class="pb-4">
        <v-row
          no-gutters
        >
          <v-col class="mb-3 pr-sm-4" cols="12" sm="5">
            <v-select
              :items="uvmFieldMacros"
              :disabled="instanceVariable.computed"
              variant="outlined"
              label="UVM field macro"
              :clearable="!!instanceVariable.fieldMacro"
              :model-value="instanceVariable.fieldMacroType"
              @update:modelValue="instanceVariable.updateFieldMacro($event)"
            />
          </v-col>
          <v-col cols="12" sm="7">
            <v-select
              :items="fieldMacroFlagOptions"
              :disabled="instanceVariable.computed"
              variant="outlined"
              chips
              closable-chips
              label="Flags"
              multiple
              :model-value="instanceVariable.selectedFieldMacroFlagOptions"
              @update:modelValue="instanceVariable.update({fieldMacroFlagIds: $event.sort()})"
            />
          </v-col>
        </v-row>
        <div v-if="!instanceVariable.isRandom">
          <v-text-field
            class="mt-8"
            :disabled="instanceVariable.computed"
            spellcheck="false"
            autocapitalize="off"
            :autofocus="!instanceVariable.defaultValue"
            variant="underlined"
            label="Default value"
            placeholder="0"
            :model-value="instanceVariable.defaultValue"
            @update:modelValue="instanceVariable.update({ defaultValue: $event })"
          />
        </div>
      </v-window-item>
      <v-window-item>
        <v-row
          class="mb-8"
          no-gutters
        >
          <v-col class="mb-3" cols="12" sm="4">
            <v-checkbox
              label="Random"
              :model-value="instanceVariable.isRandom"
              :disabled="instanceVariable.computed"
              @update:modelValue="makeRand($event, instanceVariable)"
            />
          </v-col>
          <v-col cols="12" sm="8">
            <v-select
              class="v-select__add-constraint"
              variant="outlined"
              ref="addConstraintSelect"
              :disabled="!instanceVariable.isRandom || instanceVariable.computed"
              :items="emptyConstraintClassOptions"
              label="Add constraint"
              @update:modelValue="createConstraint($event)"
            />
          </v-col>
        </v-row>
        <div
          v-for="constraint in constraints"
          :key="constraint.overrideId || constraint.id"
        >
          <constraint-code-area
            v-if="!constraint.deleted"
            :constraint="constraint"
          />
        </div>
      </v-window-item>
      <v-window-item v-if="hasCoverage">
        <coverpoint-code-area
          :instance-variable="instanceVariable"
        />
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import ConstraintCodeArea from "@/components/constraint-code-area.vue";
import CoverpointCodeArea from "@/components/coverpoint-code-area.vue";
import { fieldMacroFlagOptions, uvmFieldMacros } from "@/utils/app-config";

export default {
  components: {
    ConstraintCodeArea,
    CoverpointCodeArea
  },
  props: {
    instanceVariable: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tab: null
    };
  },
  created () {
    this.fieldMacroFlagOptions = fieldMacroFlagOptions;
    this.uvmFieldMacros = uvmFieldMacros;
  },
  computed: {
    hasCoverage () {
      return this.instanceVariable.parent.isSequenceItem && !this.instanceVariable.parent.isSequence;
    },
    constraintClasses () {
      return this.instanceVariable.parent.isConfig
        ? [this.instanceVariable.parent]
        : this.instanceVariable.parent.policiesAndMe
          .filter(policy => policy.isSequenceItem || (!this.instanceVariable.associatedPort || (policy.master === this.instanceVariable.associatedPort.master)));
    },
    constraints () {
      const constraints = [];
      this.constraintClasses
        .forEach(policy => {
          policy.allEnabledAndDisabledConstraints.forEach(constraint => {
            if (constraint.instanceVariable && (constraint.instanceVariable.id === this.instanceVariable.id)) {
              constraints.push(constraint);
            }
          });
        });
      return constraints;
    },
    emptyConstraintClassOptions () {
      return this.constraintClasses
        .filter(policy => {
          const computedConstraint = policy.computedConstraints.find(constraint => constraint.instanceVariable && (constraint.instanceVariable.id === this.instanceVariable.id));
          const constraint = policy.constraints.find(constraint => constraint.instanceVariable && (constraint.instanceVariable.id === this.instanceVariable.id));
          if (constraint) {
            return constraint.deleted;
          } else if (computedConstraint) {
            return false;
          } else {
            return true;
          }
        })
        .map(policy => policy.type);
    }
  },
  methods: {
    makeRand (value, instanceVariable) {
      if (value) {
        instanceVariable.update({ encodedType: `rand ${instanceVariable.encodedType}` });
      } else {
        instanceVariable.update({ encodedType: instanceVariable.encodedType.replace(/rand /, "") });
      }
    },
    createConstraint (policyType) {
      if (policyType) {
        this.$refs.addConstraintSelect.reset();
        this.$refs.addConstraintSelect.blur();
        const klass = this.constraintClasses.find(c => c.type === policyType);
        const deletedConstraint = klass.constraints.find(constraint => constraint.instanceVariable.id === this.instanceVariable.id);
        if (deletedConstraint) {
          deletedConstraint.immediateUpdate({ deleted: false });
        } else {
          klass.createConstraint({ instanceVariableId: this.instanceVariable.id });
        }
      }
    }
  }
};

</script>

<style lang="css" scoped>

.v-window {
  margin-top: 36px;
  padding-bottom: 36px;
  background-color: transparent;
}

:deep(.v-select__slot) {
  min-width: 100%;
}

:deep(.v-select__add-constraint) {
  margin-right: 0;
}

</style>
