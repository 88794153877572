<template>
  <tool-layout
    v-if="readyToRender"
    ref="toolLayout"
    :projectName="uvc && uvc.name"
    :root-project="uvc"
    :all-models="(uvc && uvc.isLoaded) ? uvc.allGeneratableFileModels : []"
    :models="(uvc && uvc.isLoaded) ? uvc.generatableFileModels : []"
    :loading="!uvc || !uvc.isLoaded"
    :editable="editable"
    :fab-disabled="!allStepsComplete"
    @user-agreed-to-terms="$refs.uvcNameInput && $refs.uvcNameInput.focus()"
  >
    <div v-if="!(!uvc || !uvc.isLoaded)">
      <uvmgen-stepper-step
        data-test="uvc-basics-step-button"
        editable
        edit-icon="$vuetify.icons.complete"
        :complete="stepUvcBasicComplete"
        :step="stepUvcBasic"
      >
        UVC basics
        <!--<small>Summarize if needed</small>-->
      </uvmgen-stepper-step>

      <uvmgen-stepper-content
        data-test="uvc-basics-step-content"
        :step="stepUvcBasic"
      >
        <div v-if="$route.query.step == stepUvcBasic">
          <v-text-field
            ref="uvcNameInput"
            spellcheck="false"
            autocapitalize="off"
            autofocus
            variant="underlined"
            label="UVC name"
            :placeholder="uvcNamePlaceholder"
            :error-messages="errorMessages"
            :model-value="uvc.name"
            @update:modelValue="onInputOfUvcName"
            @keydown="onKeydown"
          />

          <v-checkbox
            style="margin-left: -10px;"
            :key="pipelinedCheckboxKey"
            label="Supports out of order responses"
            :model-value="uvc.isPipelined"
            @update:modelValue="onOutOfOrderResponsesCheckbox"
          />
          <v-radio-group
            v-if="uvc.isPipelined"
            class="ml-8"
            :model-value="uvc.pipelined"
            @update:modelValue="uvc.immediateUpdate({ pipelined: $event })"
          >
            <v-radio
              label="Read channels"
              value="read"
            ></v-radio>
            <v-radio
              label="Write channels"
              value="write"
            ></v-radio>
          </v-radio-group>
          <div>
            <v-btn
              class="continue-button mt-12"
              color="primary"
              :disabled="!stepUvcBasicComplete"
              @click="nextStep(stepUvcBasic)"
            >CONTINUE</v-btn>
          </div>
        </div>
      </uvmgen-stepper-content>

      <uvmgen-stepper-step
        data-test="interface-signals-step-button"
        :editable="stepUvcBasicComplete"
        edit-icon="$vuetify.icons.complete"
        :complete="stepInterfaceSignalsComplete"
        :step="stepInterfaceSignals"
      >Interface signals</uvmgen-stepper-step>

      <uvmgen-stepper-content
        data-test="interface-signals-step-content"
        :step="stepInterfaceSignals"
      >
        <div v-if="$route.query.step == stepInterfaceSignals">
          <div v-if="!!uvc.uvcInterface">

            <vue3-slide-up-down
              class="mb-8"
              :model-value="($route.query.step == stepInterfaceSignals) && !!(uvc.uvcInterface.parameters.length || uvc.uvcInterface.ports.length)"
            >
              <div class="step-subheading">Interface parameters</div>

              <data-input-table-for-parameters
                :parent="uvc.uvcInterface"
                :new-items-placeholder="newParametersPlaceholder"
              />
            </vue3-slide-up-down>

            <div class="step-subheading">Master and slave nets</div>

            <data-input-table-for-ports
              :uvcInterface="uvc.uvcInterface"
              :new-items-placeholder="newPortsPlaceholder"
            />

            <div>
              <v-btn
                class="continue-button mt-12"
                color="primary"
                :disabled="!stepInterfaceSignalsComplete"
                @click="nextStep(stepInterfaceSignals)"
              >CONTINUE</v-btn>
            </div>

          </div>
        </div>
      </uvmgen-stepper-content>

      <uvmgen-stepper-step
        data-test="high-level-transaction-step-button"
        :editable="stepInterfaceSignalsComplete"
        edit-icon="$vuetify.icons.complete"
        :complete="stepTransactionComplete"
        :step="stepTransaction"
      >Sequence item</uvmgen-stepper-step>

      <uvmgen-stepper-content
        data-test="high-level-transaction-step-content"
        :step="stepTransaction"
      >
        <div v-if="$route.query.step == stepTransaction">
          <div v-if="!!uvc.sequenceItem">

            <vue3-slide-up-down
              class="mb-8"
              :model-value="($route.query.step == stepTransaction) && !!(uvc.package.parameters.length || uvc.sequenceItem.instanceVariables.length)"
            >
              <div class="step-subheading">Package parameters</div>

              <data-input-table-for-parameters
                :parent="uvc.package"
                new-items-placeholder="PARAM = 7"
              />
            </vue3-slide-up-down>

            <class-editor
              :root-project="uvc"
              :klass="uvc.sequenceItem"
              subroutines-are-advanced
              :new-instance-variables-placeholder="newSequenceItemInstanceVariablesPlaceholder"
              :new-subroutine-placeholder="`virtual function string convert2string();\n  // ...\nendfunction`"
            />

            <div class="d-flex justify-end">
              <!--[TODO] enable once register stuff better implemented -->
              <!--[TODO] in the model, need to convert from using a reg sequence item to a reg policy -->
              <v-checkbox
                style="margin-left: -10px;"
                v-if="false"
                label="Register adapter"
                :model-value="uvc.hasRegisterOverride"
                @update:modelValue="uvc.immediateUpdate({hasRegisterOverride: $event})"
              />
              <!--[TODO] needs bus2reg task here-->
            </div>

            <div>
              <v-btn
                class="continue-button mt-12"
                color="primary"
                :disabled="!stepTransactionComplete"
                @click="nextStep(stepTransaction)"
              >CONTINUE</v-btn>
            </div>

          </div>
        </div>
      </uvmgen-stepper-content>

      <uvmgen-stepper-step
        data-test="pin-level-protocol-step-button"
        :editable="stepTransactionComplete"
        edit-icon="$vuetify.icons.complete"
        :complete="stepTransactionComplete && stepProtocolComplete"
        :step="stepProtocol"
      >
        Bus protocol
      </uvmgen-stepper-step>
      <uvmgen-stepper-content
        data-test="pin-level-protocol-step-content"
        :step="stepProtocol"
      >
        <div v-if="$route.query.step == stepProtocol">
          <div v-if="!!uvc.uvcInterface">

            <v-tabs
              class="px-4"
              v-model="protocolTab"
              center-active
              fixed-tabs
              background-color="transparent"
            >

              <v-tab>
                BFMS
              </v-tab>
              <v-tab>
                PROTOCOL CHECKER
              </v-tab>
            </v-tabs>
            <v-window
              v-model="protocolTab"
            >
              <v-window-item>
                <v-checkbox
                  style="margin-left: -10px;"
                  label="Has a master driver"
                  hide-details
                  :model-value="uvc.hasMaster"
                  @update:modelValue="uvc.immediateUpdate({ hasMasterOverride: $event })"
                />
                <v-checkbox
                  style="margin-left: -10px;"
                  :label="'Has a slave ' + uvc.slaveDriverWord"
                  hide-details
                  :model-value="uvc.hasSlave"
                  @update:modelValue="uvc.immediateUpdate({ hasSlaveOverride: $event })"
                />
                <v-checkbox
                  style="margin-left: -10px;"
                  label="Has a monitor"
                  hide-details
                  :model-value="uvc.hasMonitor"
                  @modelValue="uvc.immediateUpdate({ hasMonitorOverride: $event })"
                />
                <bfm-collection-editor
                  data-test="bfm-collection-editor"
                  class="mt-10"
                  :uvc="uvc"
                  @activate="setActiveBfm"
                />
              </v-window-item>
              <v-window-item>
                <v-checkbox
                  style="margin-left: -10px;"
                  label="Has a protocol checker"
                  :model-value="uvc.hasProtocolChecker"
                  @update:modelValue="uvc.immediateUpdate({ hasProtocolCheckerOverride: $event })"
                />

                <code-area
                  class="mt-4"
                  v-if="uvc.protocolChecker"
                  label="Assertions"
                  :value="uvc.protocolChecker.content"
                  hint=""
                  dialogHelp="constraints"
                  @input="uvc.protocolChecker.update({ contentOverride: $event })"
                />
              </v-window-item>
            </v-window>

            <div>
              <v-btn
                class="continue-button mt-12"
                color="primary"
                :disabled="!stepProtocolComplete"
                @click="nextStep(stepProtocol)"
              >CONTINUE</v-btn>
            </div>

          </div>
        </div>
      </uvmgen-stepper-content>

      <uvmgen-stepper-step
        data-test="sequences-step-button"
        :editable="stepTransactionComplete && stepProtocolComplete"
        edit-icon="$vuetify.icons.complete"
        :complete="stepSequencesComplete"
        :step="stepSequences"
        subtitle="Optional"
      >
        Sequences and policies
      </uvmgen-stepper-step>
      <uvmgen-stepper-content
        id="sequencesStepperContent"
        style="position: relative;"
        data-test="sequences-step-content"
        :step="stepSequences"
      >
        <div v-if="$route.query.step == stepSequences">
          <v-tabs
            class="px-4"
            v-model="sequencesTab"
            center-active
            fixed-tabs
            background-color="transparent"
          >

            <v-tab>
              SEQUENCES
            </v-tab>
            <v-tab>
              POLICIES
            </v-tab>
          </v-tabs>
          <v-window
            v-model="sequencesTab"
          >
            <v-window-item>
              <sequence-collection-editor
                :root-project="uvc"
                @add-policy="addSequencePolicy"
                @activate="setActiveSequence"
              />
            </v-window-item>
            <v-window-item class="pb-4" eager>
              <policy-collection-editor
                :root-project="uvc"
                :newSequencePolicyProperties="newSequencePolicyProperties"
                @policy-done="addSequencePolicyDone"
                @activate="setActiveSequencePolicy"
              />
            </v-window-item>
          </v-window>

          <v-btn
            class="continue-button mt-12"
            color="primary"
            @click="nextStep(stepSequences)"
          >CONTINUE</v-btn>
        </div>
      </uvmgen-stepper-content>

      <uvmgen-stepper-step
        data-test="configuration-step-button"
        :editable="stepTransactionComplete && stepProtocolComplete"
        edit-icon="$vuetify.icons.complete"
        :complete="stepConfigurationComplete"
        :step="stepConfiguration"
        subtitle="Optional"
      >
        Configuration
      </uvmgen-stepper-step>
      <uvmgen-stepper-content
        data-test="configuration-step-content"
        :step="stepConfiguration"
      >
        <div v-if="$route.query.step == stepConfiguration">
          <div v-if="!!uvc.config">

            <class-editor
              data-test="configuration-class-editor"
              :root-project="uvc"
              :klass="uvc.config"
              :hasHeadingMenu="false"
              :new-instance-variables-placeholder="newConfigInstanceVariablesPlaceholder"
              :new-subroutine-placeholder="newConfigSubroutinePlaceholder"
            />

            <div>
              <v-btn
                class="continue-button mt-12"
                color="primary"
                @click="nextStep(stepConfiguration)"
              >CONTINUE</v-btn>
            </div>

          </div>
        </div>
      </uvmgen-stepper-content>

      <uvmgen-stepper-step
        data-test="development-testbench-step-button"
        :editable="stepTransactionComplete && stepProtocolComplete"
        edit-icon="$vuetify.icons.complete"
        :complete="stepDevelopmentTestbenchComplete"
        :step="stepDevelopmentTestbench"
        subtitle="Optional"
      >
        Test
      </uvmgen-stepper-step>
      <uvmgen-stepper-content
        data-test="development-testbench-step-content"
        :step="stepDevelopmentTestbench"
      >
        <div v-if="$route.query.step == stepDevelopmentTestbench">
          <v-checkbox
            style="margin-left: -10px;"
            hide-details
            :key="devTbCheckboxKey"
            label="Generate a development testbench"
            :model-value="uvc.hasStandaloneTb"
            @update:modelValue="onChangeDevTb($event)"
          />

          <div v-if="genDevTb">
            <v-tabs
              class="mt-6 px-4"
              v-model="testTab"
              center-active
              fixed-tabs
              background-color="transparent"
            >

              <v-tab>
                DEFAULTS
              </v-tab>
              <v-tab>
                TESTS
              </v-tab>
              <v-tab>
                PARAMS
              </v-tab>
            </v-tabs>

            <v-layout
              v-if="!uvc.standaloneTb"
              class="mt-16"
              fill-height
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </v-layout>
            <v-window
              v-else
              v-model="testTab"
            >
              <v-window-item>
                <test-defaults
                  v-if="uvc.standaloneTb"
                  :tb="uvc.standaloneTb"
                >
                </test-defaults>
              </v-window-item>
              <v-window-item>
                <test-collection-editor
                  v-if="uvc.standaloneTb"
                  :tb="uvc.standaloneTb"
                  @activate="setActiveTest"
                />
              </v-window-item>
              <v-window-item>
                <package-collection-editor
                  v-if="uvc.standaloneTb"
                  :tb="uvc.standaloneTb"
                  @activate="setActiveTestParameterPackage"
                />
              </v-window-item>
            </v-window>
          </div>

          <div>
            <v-btn
              class="continue-button mt-12"
              color="primary"
              @click="nextStep(stepDevelopmentTestbench)"
            >CONTINUE</v-btn>
          </div>
        </div>
      </uvmgen-stepper-content>

      <uvmgen-stepper-step
        data-test="feedback-step-button"
        :editable="stepTransactionComplete && stepProtocolComplete"
        edit-icon="$vuetify.icons.complete"
        :complete="stepGenerateComplete"
        :step="stepGenerate"
      >{{ generateFilesWord.titleCase }}</uvmgen-stepper-step>
      <uvmgen-stepper-content
        data-test="feedback-step-content"
        :step="stepGenerate"
      >
        <div
          v-if="$route.query.step == stepGenerate"
          class="pt-3"
        >
          <v-checkbox
            v-if="genDevTb && $store.getters.user.setting && supportEdaPlayground"
            style="margin-left: -10px;"
            hide-details
            label="Configure for edaplayground.com"
            :model-value="$store.getters.user.setting.edaPlayground"
            @update:modelValue="$store.getters.user.setting.immediateUpdate({ edaPlayground: $event })"
          />

          <div class="step-subheading mt-8">Click the {{ generateFilesWord.titleCase }} button</div>
          <p class="mt-10">The {{ generateFilesWord.titleCase }} button is at the bottom right corner of the file viewer. Once you have completed all the required steps, the {{ generateFilesWord.titleCase }} button will turn yellow. Click it to {{ generateFilesWord.lowerCase }} {{ uvc.name }} source code.</p>
        </div>
      </uvmgen-stepper-content>
      <div class="scrollability-spacer" style="height: 400px;">
      </div>
    </div>
  </tool-layout>
</template>

<script>
import { db, dbFns } from "@/db-VITE_APP_PLATFORM";
import session from "@/models/session-VITE_APP_PLATFORM";
import { validateLicenseRequest } from "@/utils/license-validator-VITE_APP_PLATFORM";
import ToolLayout from "@/components/tool-layout.vue";
import UvmgenStepperStep from "@/components/uvmgen-stepper-step.vue";
import UvmgenStepperContent from "@/components/uvmgen-stepper-content.vue";
import DataInputTableForParameters from "@/components/data-input-table-for-parameters.vue";
import DataInputTableForPorts from "@/components/data-input-table-for-ports.vue";
import CodeArea from "@/components/code-area.vue";
import ClassEditor from "@/components/class-editor.vue";
import BfmCollectionEditor from "@/components/bfm-collection-editor.vue";
import SequenceCollectionEditor from "@/components/sequence-collection-editor.vue";
import PolicyCollectionEditor from "@/components/policy-collection-editor.vue";
import SequencePolicyUtils from "@/utils/sequence-policy-utils";
import TestCollectionEditor from "@/components/test-collection-editor.vue";
import PackageCollectionEditor from "@/components/package-collection-editor.vue";
import TestDefaults from "@/components/test-defaults.vue";
import parseSystemVerilog from "@/utils/parse-system-verilog";
import generateFilesWord from "@/utils/generate-files-word-VITE_APP_PLATFORM";
import get from "lodash.get";

const { collection, doc, getDoc } = dbFns;

export default {
  // name: "home",
  components: {
    ToolLayout,
    UvmgenStepperStep,
    UvmgenStepperContent,
    DataInputTableForParameters,
    DataInputTableForPorts,
    CodeArea,
    ClassEditor,
    BfmCollectionEditor,
    SequenceCollectionEditor,
    PolicyCollectionEditor,
    TestCollectionEditor,
    PackageCollectionEditor,
    TestDefaults
  },
  mixins: [
    SequencePolicyUtils
  ],
  data () {
    return {
      uvc: null,
      readyToRender: false,
      editable: false,
      otherUser: null,
      errorMessages: [],
      protocolTab: 0,
      testTab: 0,
      activeBfm: null,
      activeTest: null,
      activeTestParameterPackage: null,
      genDevTb: false,
      pipelinedCheckboxKey: 0,
      devTbCheckboxKey: 0
    };
  },
  inject: ["$dialog"],
  watch: {
    "uvc.computeGeneratableFileModelsNotRendered": {
      immediate: true,
      handler () {
        // getting file models fully loaded
      }
    },
    protocolTab (tab) {
      if (tab === 0) {
        if (this.activeBfm) {
          this.$router.activateFileCard(this.activeBfm.key);
        }
      } else {
        if (this.uvc.protocolChecker) {
          this.$router.activateFileCard(this.uvc.protocolChecker.key);
        }
      }
    },
    testTab (tab) {
      if (tab === 1) {
        if (this.activeTest) {
          this.$router.activateFileCard(this.activeTest.key);
        }
      } else if (tab === 2) {
        if (this.activeTestParameterPackage) {
          this.$router.activateFileCard(this.activeTestParameterPackage.key);
        }
      }
    },
    "$route.query.step": {
      immediate: false,
      handler (step) {
        setTimeout(() => {
          const s = parseInt(step);
          if ((s === this.stepInterfaceSignals) || (s === this.stepProtocol)) {
            if (this.uvc.uvcInterface) {
              this.$router.activateFileCard(this.uvc.interface.key);
            }
          } else if (s === this.stepTransaction) {
            if (this.uvc.sequenceItem) {
              this.$router.activateFileCard(this.uvc.sequenceItem.key);
            }
          } else if (s === this.stepConfiguration) {
            if (this.uvc.config) {
              this.$router.activateFileCard(this.uvc.config.key);
            }
          } else {
            this.$router.deactivateFileCard();
          }
        }, 100);
      }
    },
    "uvc.hasMonitor": function (val) {
      if (val) {
        this.$refs.monitorCodeArea && this.$refs.monitorCodeArea.focus();
      }
    },
    "uvc.hasStandaloneTb": {
      immediate: true,
      handler (value) {
        this.genDevTb = value;
      }
    },
    "uvc.name": {
      handler: function (name) {
        if (name) {
          document.title = name + " | UVMGen UVC";
        }
      },
      immediate: true
    }
  },
  created () {
    this.supportEdaPlayground = (this.$store.getters.isWeb || ("Cypress" in window));
    this.stepUvcBasic = 1;
    this.stepInterfaceSignals = 2;
    this.stepTransaction = 3;
    this.stepProtocol = 4;
    this.stepSequences = 5;
    this.stepConfiguration = 6;
    this.stepDevelopmentTestbench = 7;
    this.stepGenerate = 8;
    this.generateFilesWord = generateFilesWord;

    getDoc(doc(collection(db, "uvcs"), this.$route.params.id))
      .then((docSnapshot) => {
        const otherId = docSnapshot.data().userId;
        this.editable = (otherId === get(this.$store.getters.user, "id"));
        this.readyToRender = true;
        if (!this.editable) {
          session.findUserById(otherId).then(user => {
            this.otherUser = user;
          });
        }
      })
      .catch((e) => {
        const error = new Error("uvcs::created::get: " + e.message);
        error.name = e.name;
        throw error;
      });
  },
  mounted () {
    setTimeout(() => {
      const unwatch = this.$watch("userUvcsAndRouteId", () => {
        if (!this.uvc) {
          this.uvc = get(this.uvcOwner, "uvcs", [])
            .find(uvc => uvc.id === this.$route.params.id);
          this.uvc && this.uvc.load().then(() => {
            this.$nextTick(() => { unwatch(); });
          });
        }
      }, { immediate: true });
    }, 10);
  },
  computed: {
    userUvcsAndRouteId () {
      return {
        user: this.uvcOwner,
        uvcs: this.uvcOwner ? this.uvcOwner.uvcs : [],
        id: this.$route.params.id
      };
    },
    uvcOwner () {
      if (this.editable) {
        return this.$store.getters.user;
      } else {
        return this.otherUser;
      }
    },
    fileGrid () {
      return this.toolLayout.fileGrid;
    },
    toolLayout () {
      return this.$refs.toolLayout;
    },
    uvcNamePlaceholder () {
      if (this.uvc.isPipelinedRead) {
        return "axir";
      } else if (this.uvc.isPipelinedWrite) {
        return "axiw";
      } else {
        return "apb";
      }
    },
    newParametersPlaceholder () {
      return [
        "ADDR_MSB = 63, DATA_MSB = 31"
      ]
        .join("\n");
    },
    newPortsPlaceholder () {
      if (this.uvc.isPipelinedRead) {
        return [
          "clk",
          "reset_n",
          // Address channel
          //   Master
          "arid[ARID_MSB:0]",
          "araddr[ARADDR_MSB:ARADDR_LSB]",
          "arlen[ARLEN_MSB:0]",
          "arvalid", // defaultValue: "'0",
          //   Slave
          "arready", // defaultValue: "'0",
          // Response channel
          //   Slave
          "rid[RID_MSB:0]",
          "rdata[RDATA_MSB:0]",
          "rresp[RRESP_MSB:0]",
          "rvalid", // defaultValue: "'0",
          //   Master
          "rready" // defaultValue: "'0",
        ]
          .join("\n");
      } else if (this.uvc.isPipelinedWrite) {
        return [
          "clk",
          "reset_n",
          // Address channel
          //   Master
          "awid[AWID_MSB:0]",
          "awaddr[AWADDR_MSB:AWADDR_LSB]",
          "awlen[AWLEN_MSB:0]",
          "awvalid", // defaultValue: "'0",
          //   Slave
          "awready", // defaultValue: "'0",
          // Data channel
          //   Master
          "wid[WID_MSB:0]",
          "wdata[WDATA_MSB:0]",
          "wvalid", // defaultValue: "'0",
          "wlast",
          //   Slave
          "wready", // defaultValue: "'0",
          // Response channel
          //   Slave
          "bid[BID_MSB:0]",
          "bresp[BRESP_MSB:0]",
          "bvalid", // defaultValue: "'0",
          //   Master
          "bready" // defaultValue: "'0",
        ]
          .join("\n");
      } else {
        const masterSignals = [
          "pclk",
          "preset_n",
          "psel",
          "paddr[ADDR_MSB:ADDR_LSB]",
          "penable",
          "pwrite",
          "pwdata[DATA_MSB:0]",
          "pstrb[((DATA_MSB+1)/8)-1:0]"
        ];
        const slaveSignals = [
          "pready",
          "prdata[DATA_MSB:0]",
          "pslverr"
        ];
        return masterSignals.concat(slaveSignals).join("\n");
      }
    },
    newInterfaceSubroutine () {
      return "task automatic wait_for_signal();\n  wait(slv.ready);\nendtask";
    },
    newSequenceItemInstanceVariablesPlaceholder () {
      const placeholders = this.uvc.uvcInterface.masterAndSlavePorts
        .map(port => port.sequenceItemVariablePlaceholder)
        .filter(p => p);

      const wdataIndex = placeholders.findIndex(p => p.match(/ wdata$/));
      const rdataIndex = placeholders.findIndex(p => p.match(/ rdata$/));
      if ((wdataIndex >= 0) && (rdataIndex >= 0)) {
        placeholders[wdataIndex] = placeholders[wdataIndex].replace(/ wdata$/, " data");
        placeholders.splice(rdataIndex, 1);
      }

      return placeholders.join("\n");
    },
    newConfigInstanceVariablesPlaceholder () {
      return "rand int num_knobs;\nprotected bit knob_enabled;";
    },
    newConfigSubroutinePlaceholder () {
      return "virtual function bit get_knob_enabled();\n  return knob_enabled;\nendfunction";
    },
    allStepsComplete () {
      return this.stepUvcBasicComplete &&
        this.stepInterfaceSignalsComplete &&
        this.stepTransactionComplete &&
        this.stepProtocolComplete;
    },
    stepUvcBasicComplete () {
      return !!(this.uvc && this.uvc.name);
    },
    stepInterfaceSignalsComplete () {
      return !!(this.uvc.uvcInterface && this.uvc.uvcInterface.ports.length && (this.uvc.hasMaster || this.uvc.hasSlave));
    },
    stepTransactionComplete () {
      return !!(this.uvc.sequenceItem && this.uvc.sequenceItem.instanceVariables.length);
    },
    stepProtocolComplete () {
      return (
        this.stepInterfaceSignalsComplete &&
        this.uvc.bfms.every(bfm => !bfm.needsAttention)
      );
    },
    stepSequencesComplete () {
      return !!get(this.uvc, "package.masterSequences", []).concat(get(this.uvc, "package.slaveSequences", [])).filter(sequence => sequence.isFormClass).length;
    },
    stepConfigurationComplete () {
      return !!(this.uvc.config && (this.uvc.config.instanceVariables.length || this.uvc.config.subroutines.length));
    },
    stepDevelopmentTestbenchComplete () {
      return this.uvc.hasStandaloneTb;
    },
    stepGenerateComplete () {
      return this.uvc.numDownloads > 0;
    }
  },
  methods: {
    onOutOfOrderResponsesCheckbox (value) {
      if (import.meta.env.VITE_APP_PLATFORM === "desktop") {
        this.changeUvcRequest(value);
      } else {
        this.$store.commit("setOnPremiseDialogTitle", "Pipelined UVCs available on Desktop");
        this.$store.commit("setOnPremiseDialogParagraph", "With UVMGen pipelined UVCs you can quickly create UVCs that support out-of-order responses. These types of protocols are typically a heavy lift for DV engineers. Until now.");
        this.$dialog.override("on-premise-license");
        this.pipelinedCheckboxKey = this.pipelinedCheckboxKey + 1;
      }
    },
    async changeUvcRequest (value) {
      const event = {
        type: "destroyed",
        assets: [
          {
            id: this.uvc.id,
            type: value ? "uvc" : "uvcPipelined"
          }
        ]
      };
      const inAppAssets = await this.$store.getters.user.inAppAssets();
      const result = await validateLicenseRequest(this.$store.getters.user.l, inAppAssets, event);
      if (result.error) {
        this.pipelinedCheckboxKey = this.pipelinedCheckboxKey + 1;
      } else {
        await this.createUvcRequest(value);
      }
    },
    // [TODO] a lot of magic strings all this code. change out for constants
    async createUvcRequest (value) {
      const event = {
        type: "created",
        assetType: value ? "uvcPipelined" : "uvc"
      };
      const inAppAssets = (await this.$store.getters.user.inAppAssets())
        .filter(asset => !((asset.id === this.uvc.id) && (asset.type === (value ? "uvc" : "uvcPipelined"))));
      const result = await validateLicenseRequest(this.$store.getters.user.l, inAppAssets, event);
      if (result.error) {
        this.pipelinedCheckboxKey = this.pipelinedCheckboxKey + 1;
      } else {
        await this.uvc.immediateUpdate({ pipelined: value ? "read" : "" });
      }
    },
    onChangeDevTb (value) {
      if (import.meta.env.VITE_APP_PLATFORM === "desktop") {
        this.changeDevTb(value);
      } else {
        this.$store.commit("setOnPremiseDialogTitle", "UVC testbenches available on Desktop");
        this.$store.commit("setOnPremiseDialogParagraph", "With UVMGen you can instantly create state-of-the-art development testbenches for your UVCs. A development testbench allows you to verify your master, slave and monitor are correct, even before your DUT is available.");
        this.$dialog.override("on-premise-license");
        this.devTbCheckboxKey = this.devTbCheckboxKey + 1;
      }
    },
    changeDevTb (value) {
      const event = value
        ? {
          type: "created",
          assetType: "uvcDevTb"
        }
        : {
          type: "destroyed",
          assets: [
            {
              id: this.uvc.id,
              type: "uvcDevTb"
            }
          ]
        };
      this.$store.getters.user.inAppAssets().then(inAppAssets => {
        validateLicenseRequest(this.$store.getters.user.l, inAppAssets, event).then(result => {
          if (result.error) {
            this.devTbCheckboxKey = this.devTbCheckboxKey + 1;
          } else {
            this.genDevTb = value;
            setTimeout(() => {
              this.uvc.immediateUpdate({ hasStandaloneTbOverride: value });
            }, 10);
          }
        });
      });
    },
    setActiveBfm (model) {
      this.activeBfm = model;
      this.$router.activateFileCard(this.activeBfm.key);
    },
    setActiveTest (model) {
      this.activeTest = model;
      this.$router.activateFileCard(this.activeTest.key);
    },
    setActiveTestParameterPackage (model) {
      this.activeTestParameterPackage = model;
      this.$router.activateFileCard(this.activeTestParameterPackage.key);
    },
    nextStep (step) {
      let nextStep = step + 1;

      if (nextStep > this.stepGenerate) {
        nextStep = 1;
      }
      this.$router.updateQueryParams({ step: nextStep });
    },
    get (object, properties) {
      return get(object, properties);
    },
    onInputOfUvcName (value) {
      let name = null;

      const errors = [];
      try {
        name = this.parseClassIdentifier(value);
      } catch (e) {
        errors.push(e.message);
      }
      this.errorMessages = errors;

      if ((errors.length === 0) && (name !== this.uvc.name)) {
        this.uvc.update({ name });
      }
    },
    parseClassIdentifier (value) {
      return parseSystemVerilog(value, "ClassIdentifier");
    },
    onKeydown (evt) {
      if (evt.code === "Enter") {
        if (!this.$route.query.step) {
          throw new Error("did not expect route step to be: " + JSON.stringify(this.$route.query.step));
        }
        this.nextStep(+this.$route.query.step);
      }
    }
  }
};
</script>

<style lang="css" scoped>
</style>
