export default {
  methods: {
    onPageLoadDone () {
      return new Promise(resolve => {
        setTimeout(() => {
          this.$store.getters.waitForZeroTransactions.then(() => {
            setTimeout(() => {
              this.$nextTick(() => {
                setTimeout(() => {
                  resolve();
                }, 200);
              });
            }, 200);
          });
        }, 200);
      });
    }
  }
};
