<template>
  <standard-layout
    has-container
  >
    <div class="mt-4 mt-md-16 pb-16">
      <div
        v-if="!!user.paymentMethods.length || !!user.licenses.length"
      >
        <div class="text-h5">Account</div>

        <div class="mt-12 step-subheading">{{ user.licenses.length > 1 ? 'Licenses' : 'License' }}</div>

        <v-table
          class="mt-6"
          style="max-width: 600px"
          density="compact"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="d-none d-sm-table-cell">
                  Acquired
                </th>
                <th>
                  Duration
                </th>
                <th>
                  Status
                </th>
                <th>
                  Details
                </th>
                <th>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="license in user.licenses"
                :key="license.id"
              >
                <td class="d-none d-sm-table-cell license-table__col">{{ license.startDate && formatDate(license.startDate) }}</td>
                <td class="license-table__col">{{ license.durationInMonths }} months</td>
                <td class="license-table__col">{{ license.status }}</td>
                <td class="license-table__col">
                  <v-badge
                    style="max-width: calc(100% - 40px);"
                    :model-value="license.needsAttention"
                    color="error"
                    dot
                    offset-x="-12"
                    offset-y="14"
                  >
                    <v-btn
                      style="margin-left: -16px;"
                      color="primary"
                      variant="text"
                      @click="goToLicense(license)"
                    >
                      VIEW
                    </v-btn>
                  </v-badge>
                </td>
                <td class="license-table__col">
                  <v-btn
                    v-if="user.cart && user.priceSheet"
                    style="margin-left: -16px;"
                    color="primary"
                    variant="text"
                    :disabled="!license.active || license.needsAttention || license.termPaymentProcessing"
                    @click="upgradeLicense(license)"
                  >
                    UPGRADE
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>

        <stripe-cards
          class="mt-10"
          v-if="user.privateProfile"
          :full-name="user.displayName"
          :add-card-button-disabled="!user.privateProfile.paymentEmail.length"
        >
          <template v-slot:list-title>
            <div class="step-subheading mt-4">
              Default payment method
            </div>
          </template>
          <template v-slot:add-card-title>
            <div class="step-subheading mt-4">
              Payment information
            </div>
          </template>
          <extra-stripe-elements />
        </stripe-cards>
      </div>
      <account-maintenance
        v-else-if="loaded"
        class="mt-16"
      />
      <v-btn
        v-if="false"
        @click="determineIndexes"
      >
        DETERMINE INDEXES
      </v-btn>
    </div>
  </standard-layout>
</template>

<script>
import { AccountMaintenance, StripeCards } from "vue-app-components";
import StandardLayout from "@/components/standard-layout.vue";
import ExtraStripeElements from "@/components/extra-stripe-elements.vue";
import createUpgradeLicenseBundle from "@/utils/create-upgrade-license-bundle";
import { formatDate } from "vue-app-utils";

export default {
  components: {
    StandardLayout,
    AccountMaintenance,
    StripeCards,
    ExtraStripeElements
  },
  data () {
    return {
      loaded: false
    };
  },
  created () {
    this.formatDate = formatDate;

    this.user.paymentMethods; // eslint-disable-line no-unused-expressions
    this.user.licenses; // eslint-disable-line no-unused-expressions
    Promise.all([
      this.user.promisePaymentMethods,
      this.user.promiseLicenses
    ])
      .then(() => {
        this.loaded = true;
      });
  },
  computed: {
    user () {
      return this.$store.getters.user;
    }
  },
  methods: {
    goToLicense (license) {
      this.$router.push({ name: "licensesShow", params: { id: license.id } })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    },
    async upgradeLicense (license) {
      await createUpgradeLicenseBundle(this.user.priceSheet, this.user.cart, license.id);
      this.$router.push({ name: "cartsEdit", params: { id: this.user.cart.id }, query: { step: 2 } })
        .catch((e) => {
          if (!e.message.match(/navigation guard/)) {
            throw e;
          }
        });
    },
    determineIndexes () {
      this.$store.getters.user.determineIndexes().then(indexes => {
        const x = indexes.map(i => i.collectionGroup + ":" + i.fields.map(f => f.fieldPath).sort().join(",")).sort();
        const m = {};
        x.forEach(y => {
          m[y] = m[y] || 0;
          m[y]++;
        });
        // 1. Check for duplicates
        console.log(m);
        // 2. Uncomment below, remove duplicates manually found in 1, copy into firestore.indexes.json retaining the bottom two manual indexes (payments and licenseAssets).
        // console.log(JSON.stringify({
        //   indexes,
        //   fieldOverrides: []
        // }, null, 2));
      });
    }
  }
};
</script>

<style lang="css" scoped>

.license-table__col {
  padding: 0 4px 0 16px !important;
}

</style>
