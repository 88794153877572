<template>
  <div>
  </div>
</template>

<script>

export default {
  name: "dialog-license-assets"
};
</script>

<style lang="css" scoped>
</style>
