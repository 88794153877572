<template>
  <a
    class="project-anchor"
    @click.stop="$emit('click')"
  >
    <v-card
      class="project-card"
      style="overflow: initial"
      hover
    >
      <div class="project-card__content center-container">
        <v-icon
          class="text-primary center-content"
          size="64"
        >
          {{ icon }}
        </v-icon>
      </div>

      <v-card-actions
        class="project-card__actions"
        :class="{'project-card__actions--dark': dark}">
        <div
          class="text-h5 ellipsis"
          data-test="project-name"
        >{{ title }}</div>

        <v-spacer />

        <v-menu
          v-if="hasActions"
          min-width="228"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="menu-button"
              icon
            >
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>

          <v-list
            style="min-width: 228px;"
          >
            <v-list-item
              title=""
              :disabled="$store.getters.hasOpenTransactions"
              @click="$emit('destroy')"
              prepend-icon="mdi-delete"
            >
              <v-list-item-title>Remove</v-list-item-title>

            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
    </v-card>
  </a>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    dark: {
      type: Boolean,
      default: false
    },
    hasActions: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="css" scoped>

.project-anchor {
  display: block;
}

.project-card {
  height: var(--project-card-height);
}

.project-card__content {
  height: calc(100% - var(--project-card-actions-height));
}

.project-card__actions {
  height: var(--project-card-actions-height);
  background-color: var(--primary-color-lighten1);
  width: 100%;
  padding: 12px 4px 12px 18px;
}

.project-card__actions--dark {
  background-color: var(--primary-color);
  color: white;
}

</style>
