import FileSaverModule from "file-saver";
let FileSaver;

if (window.Cypress) {
  FileSaver = window.$browser.FileSaver;
} else {
  FileSaver = FileSaverModule;
}

export default FileSaver;
